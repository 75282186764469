import Editor from "@monaco-editor/react";

export default function monacoWidgetJson(props) {
    const handleEditorChange = (value, event) => {
        props.onChange(value)
    }

    return (
        <Editor
            height={props.uiSchema.height ? props.uiSchema.height : "20vh"}
            defaultLanguage="json"
            defaultValue={props.value}
            onChange={handleEditorChange}
            theme="vs-dark"
            options={
                {
                    tabSize: 2
                }
            }
        />
    )
};