import React from 'react';
import ClientProvider from '../ClientProvider';

export const stati = [
    {
        label: "true",
        valueHTML: "true",
        value: true,
        icon: "file-earmark-check-fill"
    },
    {
        label: "false",
        valueHTML: "false",
        value: false,
        icon: "file-earmark-excel-fill"
    },
    {
        label: "null",
        valueHTML: "null",
        value: null,
        icon: "file-earmark-fill"
    }
]

export function StatiRadio({ changeHandler, value, id, namePrefix }) {
    return (
        <span className="statiRadioGroup">
            {stati.map(x => (
                <input
                    key={id + "_" + x["valueHTML"]}
                    type="radio"
                    name={(namePrefix ? namePrefix + "_" : "") + id}
                    value={x["valueHTML"]}
                    onChange={changeHandler}
                    title={x["label"]}
                    checked={value === x["value"]}
                />
            ))}
        </span>
    )
}

export function StatiRadioSingle(props) {
    return (
        <StatiRadioMultiple
            tableData={[props.row]}
            id={"id_" + props.row["#"]}
            namePrefix={props.namePrefix}
        />
    )
}

export function StatiRadioMultiple({ tableData, id, namePrefix }) {
    const wsProvider = React.useContext(ClientProvider);
    const urlsStatus = wsProvider.CommandsTree.getSingleSetting("urlsStatus", [])
    const urlsStatusFiltered = urlsStatus.filter(urlsStatusObj1 => tableData.find(row => row["url"] === urlsStatusObj1["url"])) //Do not use values, that are not in tableData [could be subset]

    const getValue = () => {
        const count = urlsStatusFiltered.length
        var result = undefined
        stati.forEach(statusObj => {
            if (urlsStatusFiltered.filter(x => x["status"] === statusObj["value"]).length === count) {
                result = statusObj["value"]
                return false
            }
        })
        return result
    }

    const changeHandler = (e) => {
        const valueNew = stati.find(x => x["valueHTML"] === e.target.value)["value"]
        const urlsStatusNew = [...urlsStatus]
        tableData.forEach(row => {
            const indexOfObject = urlsStatusNew.findIndex(x => x["url"] === row["url"]);
            if (indexOfObject !== -1) {
                urlsStatusNew.splice(indexOfObject, 1);
            }
            const urlsStatusObjNew = {
                "url": row["url"],
                "status": valueNew
            }
            urlsStatusNew.push(urlsStatusObjNew)
        })
        wsProvider.CommandsTree.setSingleSetting("urlsStatus", urlsStatusNew)
    }

    return (
        <StatiRadio
            changeHandler={changeHandler}
            value={getValue()}
            id={id}
            namePrefix={namePrefix}
        />
    )
}