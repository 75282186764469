import React, { useState } from 'react'
import ClientProvider from '../ClientProvider';

import ButtonSaveClient from './ButtonSaveClient'
import ButtonRestartServer from './ButtonRestartServer'
import ButtonRunCrawler from './ButtonRunCrawler'
import ButtonRunCrawlerParts from './ButtonRunCrawlerParts'
import ButtonDeleteCache from './ButtonDeleteCache'
import ButtonOpenWebsite from './ButtonOpenWebsite'

export default function ButtonsContainer(props) {
    const wsProvider = React.useContext(ClientProvider);

    const [connectedStatus, setConnectedStatus] = useState(true)
    wsProvider.setConnectedStatus = setConnectedStatus
    wsProvider.connectedStatus = connectedStatus

    return (
        <>
            {wsProvider.commandsTreeReady() &&
                <>
                    <ButtonRunCrawler />
                    <ButtonRunCrawlerParts />
                </>
            }
            <ButtonSaveClient />
            <ButtonDeleteCache />
            <ButtonRestartServer />
            <ButtonOpenWebsite />
        </>
    )
}