import React, { useState, useRef, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';
import Form from "@rjsf/core";
import { ItemsTableDefault, ItemsTableOther } from '../../Various/ItemsTable/ItemsTable'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactJson from 'react-json-view'
import envs from '../../../envs'
import ProjectPathSelectorWidget from "../../Various/ProjectPathSelectorWidget";


export default function WorkspaceBatchJobs() {
  const wsProvider = React.useContext(ClientProvider);
  const [batchJobsOverview, setBatchJobsOverview] = useState({})
  const [batchJobDetails, setBatchJobDetails] = useState(null)
  const [sortColumns, setSortColumns] = useState([])
  const [sortColumns2, setSortColumns2] = useState([])
  const [sortColumns3, setSortColumns3] = useState([])
  const [batchJobTabs, setBatchJobTabs] = wsProvider.useStateVarGlobal("batchJobTabs", 0)
  const [filterFormData, setFilterFormData] = wsProvider.useStateVarGlobal("filterFormDataBatch", {
    batchJobName: 'CrawlerRunParallel'
  })

  var [filterColumnsItems, setFilterColumnsItems] = useState({})
  var [filterColumnsCommands, setFilterColumnsCommands] = useState({})

  let onlyLocal = []
  if (envs.host === "localhost") {
    onlyLocal.push("DownloadClientOutputFromAws")
  }



  const [schemaDefinition, setSchemaDefinition] = useState(
    {
      schema: {
        type: 'object',
        properties: {
          batchJobName: {
            enum: [],
            title: 'batchJobName',
            type: 'string'
          },
          batchJobId: {
            "title": "batchJobId",
            "type": "string",
            "enum": []
          },
          filterStatus: {
            "title": "filterStatus",
            "type": "string",
            "enum": ["SUBMITTED", "RUNNING", "SUCCEEDED", "FAILED"]
          }
        },
        required: ["batchJobName"]
      },
      uiSchema: {
        'ui:order': [
          'batchJobName',
          'batchJobId',
          'filterStatus'
        ]
      }
    })

  useEffect(
      () => {
        wsProvider.ws.waitForSocketAndSend(
            {
              action: "list_batch_jobs",
              options: {  }
            },
            (response) => {
              let batchJobs = (envs.host === "localhost") ? response["data"] : response["data"].filter(job => !onlyLocal.includes(job))

              setSchemaDefinition(
                  {
                    schema: {
                      type: 'object',
                      properties: {
                        batchJobName: {
                          enum:  batchJobs.map(x => x["module_name"]),
                          enumNames: batchJobs.map(x => x["display_name"]),
                          title: 'batchJobName',
                          type: 'string'
                        },
                        batchJobId: {
                          "title": "batchJobId",
                          "type": "string",
                          "enum": []
                        },
                        filterStatus: {
                          "title": "filterStatus",
                          "type": "string",
                          "enum": ["SUBMITTED", "RUNNING", "SUCCEEDED", "FAILED"]
                        }
                      },
                      required: ["batchJobName"]
                    },
                    uiSchema: {
                      'ui:order': [
                        'batchJobName',
                        'batchJobId',
                        'filterStatus'
                      ]
                    }
                  }
              )
            }
        )
      }, []
  )

  useEffect(() => {
    loadBatchJobsOverview()
  }, []);

  useEffect(() => {
    if (Object.keys(batchJobsOverview).length > 0) {
      const schemaNew = {
        ...schemaDefinition,
        schema: {
          ...schemaDefinition.schema,
          properties: {
            ...schemaDefinition.schema.properties,
            /*
            batchJobName: {
              ...schemaDefinition.schema.properties.batchJobName,
              enum: Object.keys(batchJobsOverview["batchJobs"])
            },
            */
            batchJobId: {
              ...schemaDefinition.schema.properties.batchJobId,
              enum: (filterFormData.batchJobName && batchJobsOverview["batchJobs"][filterFormData.batchJobName]) ? batchJobsOverview["batchJobs"][filterFormData.batchJobName].map(x => x["batchJobId"]) : [],
              enumNames: (filterFormData.batchJobName && batchJobsOverview["batchJobs"][filterFormData.batchJobName]) ? batchJobsOverview["batchJobs"][filterFormData.batchJobName].map(x => { let date = new Date(); date.setTime(x["batchJobId"]); return date.toLocaleString() }) : [],
            }
          }
        }
      }
      setSchemaDefinition(schemaNew)
    }
  }, [batchJobsOverview, filterFormData.batchJobName]);

  useEffect(() => {
    loadBatchJobDetails()
  }, [filterFormData.batchJobId])

  useEffect(() => {
    // Register Callback
    // Return is the cleanup function
    return wsProvider.registerBatchJobUpdateHandler("workspaceBatchJobs", handleBatchJobUpdate)
  }, [filterFormData.batchJobId, filterFormData.batchJobName])

  const refreshBatchJobsOverview = () => {
    loadBatchJobsOverview()
  }

  const handleBatchJobUpdate = (data) => {
    // console.log("handleBatchJobUpdate")
    // console.log(data)
    //Check if this job is opened:
    if (data["batchJobId"] === filterFormData.batchJobId && data["batchJobName"] === filterFormData.batchJobName) {

      if (data["level"] === "batchJobItemCommand") {
        setBatchJobDetails(x => {
          if (!x || !x.commands)
            return x
          const batchJobDetailsNew = {
            ...x,
            time: data.time,
            commands: [
              ...x.commands
            ]
          }
          const index = batchJobDetailsNew.commands.findIndex(x => (x.path === data.stats.path))
          batchJobDetailsNew.commands[index] = data.stats
          return batchJobDetailsNew
        })
      } else if (data["level"] === "batchJobItem") {
        setBatchJobDetails(x => {
          const batchJobDetailsNew = {
            ...x,
            time: data.time,
          }

          if (x && "items" in x) {
            batchJobDetailsNew.items = [...x.items]
          } else {
            batchJobDetailsNew.items = []
          }

          const index = batchJobDetailsNew.items.findIndex(x => (x.path === data.stats.path))
          batchJobDetailsNew.items[index] = data.stats
          return batchJobDetailsNew
        })
      } else if (data["level"] === "batchJob") {
        setBatchJobDetails(x => {
          if (data.stats['progress_succeeded']) {
            data.stats['progress_succeeded'] = data.stats['progress_succeeded'] * 100 + '%'
          }
          if (data.stats['progress_failed']) {
            data.stats['progress_failed'] = data.stats['progress_failed'] * 100 + '%'
          }
          const batchJobDetailsNew = {
            ...x,
            time: data.time,
            job: data.stats
          }
          return batchJobDetailsNew
        })
      }
    }
  }

  const loadBatchJobsOverview = (extraCallback) => {
    wsProvider.ws.waitForSocketAndSend({
      action: "getBatchJobsOverview",
      options: {}
    }, (response) => {
      console.log("overview response", response)
      setBatchJobsOverview(response)
      if (extraCallback) {
        extraCallback()
      }
    })
  }

  const loadBatchJobDetails = () => {
    if (!filterFormData.batchJobId)
      return

    wsProvider.ws.waitForSocketAndSend({
      action: "getBatchJobDetails",
      options: {
        batchJobId: filterFormData.batchJobId,
        batchJobName: filterFormData.batchJobName
      }
    }, (response) => {
      setBatchJobDetails(response)
    })
  }

  const deleteJob = () => {
    wsProvider.ws.waitForSocketAndSend({
      action: "deleteBatchJob",
      options: {
        batchJobId: filterFormData.batchJobId,
        batchJobName: filterFormData.batchJobName
      }
    }, (response) => {
      refreshBatchJobsOverview()
      setFilterFormData(filterFormData => {
        const filterFormDataNew = {
          ...filterFormData
        }
        delete filterFormDataNew.batchJobId
        return filterFormDataNew
      })
    })
  }

  const deleteJobAll = () => {
    wsProvider.ws.waitForSocketAndSend({
      action: "deleteAllBatchJob",
      options: {
        batchJobName: filterFormData.batchJobName
      }
    }, (response) => {
      refreshBatchJobsOverview()
      setFilterFormData({
        batchJobName: filterFormData.batchJobName
      })
    })
  }

  const filterChangeHandler = (data) => {
    setFilterFormData(data.formData)
  }

  const addFieldForceStopBtn = (data) => {
    if (data === undefined) {
      return data
    }
    return [
      ...data.map(x => {
        if (x) {
          x["forceStopBtn"] = ["RUNNING", "SUBMITTED"].includes(x["status"]) ? "STOP" : ""
        }
        return x
      })
    ]
  }

  const addFieldReattemptBtn = (data) => {
    if (data === undefined) {
      return data
    }
    return [
      ...data.map(x => {
        if (x) {
          x["reattemptBtn"] = "REATTEMPT" // ["SUCCEEDED", "FAILED"].includes(x["status"]) ? "REATTEMPT" : ""
        }
        return x
      })
    ]
  }

  const clickHandler_batchJobOverview = (item, column, index, itemOrig) => {
    if (column === "forceStopBtn" && item["forceStopBtn"] === "STOP") {
      wsProvider.ws.waitForSocketAndSend({
        action: "stopBatchJob",
        options: {
          batchJobId: filterFormData.batchJobId,
          batchJobName: filterFormData.batchJobName
        }
      }, (response) => {
        // alert("stopBatchJob: " + filterFormData.batchJobId)
      })
    } else {
      wsProvider.toLog(item[column], true)
    }
  }

  const clickHandler_batchJobOverviewItem = (item, column, index, itemOrig) => {
    if (column === "forceStopBtn" && item["forceStopBtn"] === "STOP") {
      wsProvider.ws.waitForSocketAndSend({
        action: "stopBatchJobItem",
        options: {
          batchJobId: filterFormData.batchJobId,
          batchJobName: filterFormData.batchJobName,
          itemIndex: item["path"]
        }
      }, (response) => {
        //  alert("stopBatchJobItem: " + filterFormData.batchJobId + "/" + item["path"])
      })
    } else if (column === "reattemptBtn") {
      wsProvider.ws.waitForSocketAndSend({
        action: "manualReattemptBatchJobItem",
        options: {
          batchJobId: filterFormData.batchJobId,
          batchJobName: filterFormData.batchJobName,
          itemIndex: item["path"]
        }
      }, (response) => {
        //  alert("stopBatchJobItem: " + filterFormData.batchJobId + "/" + item["path"])
      })

    } else {
      wsProvider.toLog(item[column], true)
    }
  }
  const clickHandler_batchJobOverviewItemCommand = (item, column, index, itemOrig) => {
    wsProvider.toLog(item[column], true)
  }

  const renderBatchJobHeader = () => {
    if (batchJobDetails && filterFormData.batchJobId && "items" in batchJobDetails)
      return (
        <ItemsTableDefault
          tableData={addFieldForceStopBtn([batchJobDetails["job"]])}
          sortColumns={sortColumns2}
          setSortColumns={setSortColumns2}
          time={batchJobDetails["time"]}
          extraClass="batchJobOverview"
          key={filterFormData.batchJobId}
          clickHandler={clickHandler_batchJobOverview}
        />
      )
    return null
  }

  const filterByStatus = (data) => {
    if (filterFormData.filterStatus)
      return data.filter(x => x["status"] === filterFormData.filterStatus)
    return data
  }

  var tableData_batchJobOverviewItem = []
  var tableData_batchJobOverviewItemCommand = []
  if (batchJobDetails && filterFormData.batchJobId) {
    let items = batchJobDetails["items"]
    items = addFieldForceStopBtn(items)
    items = addFieldReattemptBtn(items)
    tableData_batchJobOverviewItem = filterByStatus(items)
    tableData_batchJobOverviewItemCommand = filterByStatus(batchJobDetails.commands)
  }

  return (
    <div className="row">
      <div className="col-12 workspaceTop">
        <div className='flex_header'>
          <div className='header'>
            <div className='row'>
              <div className='col-6'>
                <Form
                  className="Form_BatchJobOverview"
                  schema={schemaDefinition["schema"]}
                  uiSchema={schemaDefinition["uiSchema"]}
                  formData={filterFormData}
                  onChange={filterChangeHandler}
                />
              </div>
              <div className='col-6'>
                <button
                  onClick={refreshBatchJobsOverview}
                  className="btn btn-primary btn-sm refreshClients"
                >
                  <i className="bi bi-arrow-clockwise"> Overview</i>
                </button>
                <button
                  onClick={loadBatchJobDetails}
                  className="btn btn-primary btn-sm refreshClients"
                >
                  <i className="bi bi-arrow-clockwise"> Job</i>
                </button>
                {filterFormData.batchJobId &&
                  <button
                    onClick={deleteJob}
                    className="btn btn-primary btn-sm refreshClients"
                  >
                    <i className="bi bi-trash"> Delete</i>
                  </button>
                }
                {filterFormData.batchJobName &&
                  <button
                    onClick={deleteJobAll}
                    className="btn btn-primary btn-sm refreshClients"
                  >
                    <i className="bi bi-trash"> Delete All</i>
                  </button>
                }
              </div>
            </div>
          </div>
          <div className='content'>
            {filterFormData.batchJobName &&
              <Tabs
                selectedIndex={batchJobTabs}
                onSelect={setBatchJobTabs}
                className={"react-tabs height100 flex_header batchJobTabs"}
              >
                <TabList className="react-tabs__tab-list header">
                  <Tab>Create Batch-Job</Tab>
                  {batchJobDetails && filterFormData.batchJobId && tableData_batchJobOverviewItem && tableData_batchJobOverviewItemCommand &&
                    <>
                      <Tab>Items ({tableData_batchJobOverviewItem.length})</Tab>
                      <Tab>Commands ({tableData_batchJobOverviewItemCommand.length})</Tab>
                      <Tab>Definition</Tab>

                    </>
                  }
                </TabList>
                <TabPanel className="content">
                  <CreateBatchJob
                    batchJobName={filterFormData.batchJobName}
                    setFilterFormData={setFilterFormData}
                    key={filterFormData.batchJobName + "_" + filterFormData.batchJobId}
                    loadBatchJobsOverview={loadBatchJobsOverview}
                    setBatchJobTabs={setBatchJobTabs}
                    batchJobDetails={batchJobDetails}
                  />
                </TabPanel>
                {batchJobDetails && filterFormData.batchJobId &&
                  <>
                    <TabPanel className="content">
                      <div className='flex_header'>
                        <div className='header'>
                          {renderBatchJobHeader()}
                        </div>
                        <div className='content'>
                          {(batchJobDetails && filterFormData.batchJobId && "items" in batchJobDetails) &&
                            <>
                              <ItemsTableDefault
                                tableData={tableData_batchJobOverviewItem}
                                sortColumns={sortColumns}
                                setSortColumns={setSortColumns}
                                time={batchJobDetails["time"]}
                                key={filterFormData.batchJobId}
                                extraClass="batchJobOverviewItem"
                                clickHandler={clickHandler_batchJobOverviewItem}
                                filterColumns={filterColumnsItems}
                                setFilterColumns={setFilterColumnsItems}
                              />
                            </>
                          }
                        </div>
                      </div>

                    </TabPanel>
                    <TabPanel className="content">
                      <div className='flex_header'>
                        <div className='header batchJobHeader'>
                          {renderBatchJobHeader()}
                        </div>
                        <div className='content'>
                          {(batchJobDetails && filterFormData.batchJobId && "commands" in batchJobDetails) &&
                            <>
                              <ItemsTableDefault
                                tableData={tableData_batchJobOverviewItemCommand}
                                sortColumns={sortColumns3}
                                setSortColumns={setSortColumns3}
                                time={batchJobDetails["time"]}
                                key={filterFormData.batchJobId}
                                extraClass="batchJobOverviewItemCommand"
                                clickHandler={clickHandler_batchJobOverviewItemCommand}
                                filterColumns={filterColumnsCommands}
                                setFilterColumns={setFilterColumnsCommands}
                              />
                            </>
                          }
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel className="content">
                      <BatchJobDefinitionPanel
                        key={filterFormData.batchJobId}
                        batchJobDefinition={batchJobDetails.batchJobDefinition}
                      />
                    </TabPanel>
                  </>
                }
              </Tabs>
            }
          </div>
        </div>
      </div>
    </div >
  )
}

function CreateBatchJob({ batchJobName, setFilterFormData, loadBatchJobsOverview, setBatchJobTabs, batchJobDetails }) {
  const wsProvider = React.useContext(ClientProvider);
  const [schemaDefinition, setSchemaDefinition] = useState(null)
  const [formData, setFormData] = useState((batchJobDetails && batchJobDetails.batchJobDefinition) ? batchJobDetails.batchJobDefinition.batchJobOptions : {})

  const changeFormDataHandler = (data) => {
    setFormData(data.formData)
  }

  const widgets = {
    projectpathselector: ProjectPathSelectorWidget,
  };

  const batchJobInitHandler = (batchJobOptions) => {

    console.log(batchJobOptions)

    wsProvider.ws.waitForSocketAndSend({
      action: "batchJobInit",
      options: {
        batchJobName,
        "batchJobOptions": formData
      }
    }, (response) => {
      loadBatchJobsOverview(() => {
        setFilterFormData(filterFormData => ({
          ...filterFormData,
          batchJobId: response.batchJobId
        }))
        setBatchJobTabs(1)  // was 1
      })
    })
  }

  useEffect(() => {
    wsProvider.ws.waitForSocketAndSend({
      action: "getBatchJobSchemaDefinition",
      options: { batchJobName }
    }, (response) => {
      setSchemaDefinition(response)
    })
  }, []);


  if (schemaDefinition) {
    return (
      <Form
        className="Form_BatchJobConfig"
        schema={schemaDefinition["schema"]}
        uiSchema={schemaDefinition["uiSchema"]}
        formData={formData}
        onChange={changeFormDataHandler}
        onSubmit={batchJobInitHandler}
        omitExtraData={true}
        widgets={widgets}
      >
        <button type="submit" className='btn btn-info'>RUN</button>
      </Form>
    )
  }

  return null
}

function BatchJobDefinitionPanel({ batchJobDefinition }) {
  return (
    <ReactJson
      src={batchJobDefinition}
      theme="monokai"
      indentWidth={2}
      displayDataTypes={false}
      key={batchJobDefinition.batchJobId}
      collapsed={2}
    />
  )
}