import React, { useState, useRef, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';
import Form from "@rjsf/core";
import { ItemsTableDefault, ItemsTableOther } from '../../Various/ItemsTable/ItemsTable'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactJson from 'react-json-view'
import { projectPaths } from '../../Workspace/Client/WorkspaceClientSettings';



export default function WorkspaceStatisticsDiff() {
    const wsProvider = React.useContext(ClientProvider);

    const [data, setData] = useState(false)
    const [sortColumns, setSortColumns] = useState([])
    const [filterColumns, setFilterColumns] = wsProvider.useStateVar("crawler", "filterColumnsStatisticsDiff", {})

    const [schemaDefinition, setSchemaDefinition] = useState({
        schema: {
            type: 'object',
            properties: {
                projectPath: {
                    type: 'string',
                    enum: projectPaths,
                    default: 'live'
                },
                flag: {
                    type: 'string',
                    default: 'latest'
                }
            },
            required: ["projectPath", "flag"]
        },
        uiSchema: {
            'ui:order': [
                'projectPath',
                'flag'
            ]
        }
    })

    const [filterFormData, setFilterFormData] = wsProvider.useStateVarGlobal("filterFormDataStatiticsDiff", {
        projectPath: "live",
        flag: "latest"
    })

    const loadData = () => {
        if (!filterFormData.projectPath || !filterFormData.flag)
            return

        wsProvider.ws.waitForSocketAndSend({
            action: "getStatiticsDiff",
            options: filterFormData
        }, (response) => {
            setData(response)
        })
    }

    const filterChangeHandler = (data) => {
        setFilterFormData(data.formData)
        loadData()
    }

    return (
        <div className="row">
            <div className="col-12 workspaceTop">
                <div className='flex_header'>
                    <div className='header'>
                        <div className='row'>
                            <div className='col-6'>
                                <Form
                                    className="FormStatisticDiffHeader"
                                    schema={schemaDefinition["schema"]}
                                    uiSchema={schemaDefinition["uiSchema"]}
                                    formData={filterFormData}
                                    onSubmit={filterChangeHandler}
                                >
                                    <button className="btn btn-info">Load</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        {data && filterFormData.projectPath && filterFormData.flag &&
                            <ItemsTableDefault
                                tableData={data}
                                sortColumns={sortColumns}
                                setSortColumns={setSortColumns}
                                key={filterFormData.projectPath + "-" + filterFormData.flag}
                                filterColumns={filterColumns}
                                setFilterColumns={setFilterColumns}
                            />
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}