import { ItemsTableDefault } from '../../../../Various/ItemsTable/ItemsTable'
import ClientProvider from '../../../../Various/ClientProvider';
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tree from 'rc-tree';
import FilterOutputPanel from './FilterOutputPanel'
import TreeSelectBreadcrumb from './TreeSelectBreadcrumb'
import TreeSelectLinkTag from './TreeSelectLinkTag'
import TreeSelectUrlSplit from './TreeSelectUrlSplit'
import TreeSelectUrlSource from './TreeSelectUrlSource'
import { stati } from '../../../../Various/ItemsTable/StatiRadio'
import Select from 'react-select';

export default function FilterTypeIsCourse({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const wsProvider = React.useContext(ClientProvider);
    const [sortColumns, setSortColumns] = wsProvider.useStateVar("command", "sortColumnsInput", [])
    const [filterColumns, setFilterColumns] = wsProvider.useStateVar("command", "filterColumnsFlat", {})

    const [filterIsCourseTabs, setFilterIsCourseTabs] = wsProvider.useStateVar("command", "filterIsCourseTabs", 0)
    const [filterIsCourseShowStatus, setFilterIsCourseShowStatus] = wsProvider.useStateVar("command", "filterIsCourseShowStatus", "all")

    const tableData = execAllArray.map(x => x["in"])
    const urlsStatus = wsProvider.CommandsTree.getSingleSetting("urlsStatus", [])

    var tableDataFiltered = tableData

    if (filterIsCourseShowStatus !== "all") {
        const urlsStatusFiltered = urlsStatus.filter(statusObj => statusObj["status"] === filterIsCourseShowStatus)
        tableDataFiltered = tableData.filter(row => urlsStatusFiltered.find(statusObj => statusObj["url"] === row["url"]) || filterIsCourseShowStatus === null && !urlsStatus.find(statusObj => statusObj["url"] === row["url"]))
    }


    return (
        <Tabs
            selectedIndex={filterIsCourseTabs}
            onSelect={setFilterIsCourseTabs}
        >
            <TabList>
                <Tab>Flat</Tab>
                <Tab>Breadcrumbs</Tab>
                <Tab>Url-Source</Tab>
                <Tab>Url-Split</Tab>
                {/*<Tab>Url-linkTag</Tab>*/}
                <Tab>Output</Tab>
                <ShowStatusDropdown status={filterIsCourseShowStatus} setStatus={setFilterIsCourseShowStatus} />
            </TabList>
            <TabPanel>
                <div className="row">
                    <div className="col-12">
                        <ItemsTableDefault
                            tableData={tableDataFiltered}
                            sortColumns={sortColumns}
                            setSortColumns={setSortColumns}
                            mergeCustomFields={true}
                            urlsStatus={urlsStatus}
                            useRowKeyGetter={(x) => x["#"]}
                            hideColumns={["#source", "url_linkTag", "url_linkTagLowest", "url_source", "url_redirected", "body", "breadcrumbs", "subtitles", "fields", "fields_details", "#exec", "#"]}
                            filterColumns={filterColumns}
                            setFilterColumns={setFilterColumns}
                        />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <TreeSelectBreadcrumb
                    execAllArray={execAllArray}
                    tableData={tableDataFiltered}
                />
            </TabPanel>
            <TabPanel>
                <TreeSelectUrlSource
                    execAllArray={execAllArray}
                    tableData={tableDataFiltered}
                />
            </TabPanel>
            <TabPanel>
                <TreeSelectUrlSplit
                    execAllArray={execAllArray}
                    tableData={tableDataFiltered}
                />
            </TabPanel>
            {/*
            <TabPanel>
                <TreeSelectLinkTag
                    execAllArray={execAllArray}
                    tableData={tableDataFiltered}    
                    />
            </TabPanel>
            */}
            <TabPanel>
                <FilterOutputPanel
                    execAll={execAll}
                    execAllArray={execAllArray}
                    showAll={showAll}
                    execSelected={execSelected}
                    commandKey={commandKey}
                />
            </TabPanel>
        </Tabs>
    )
}

function ShowStatusDropdown({ status, setStatus }) {

    const onChange = (result) => setStatus(result["value"])

    const useStati = [
        {
            label: "all",
            valueHTML: "all",
            value: "all",
            icon: "file-earmark-fill"
        },
        ...stati
    ]

    const options = useStati.map(x => ({ "value": x["value"], "label": x["label"] }))

    return (
        <Select
            className="reactSelect filterStatus float-end"
            value={{ value: status, label: useStati.find(x => x["value"] === status)["label"] }}
            onChange={onChange}
            options={options}
        />
    )

}