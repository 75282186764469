//import {useState} from "react";
import { ItemsTableCommand } from '../../../Various/ItemsTable/ItemsTable'
import ClientProvider from '../../../Various/ClientProvider';
import React from 'react';

export default function Pipe({ execAll, execAllArray, showAll, execSelected, commandKey, CommandsTree, groupByField }) {
    const wsProvider = React.useContext(ClientProvider);

    let settings = CommandsTree.getAttrSettings()

    settings["inputFields"] = settings["inputFields"] ? settings["inputFields"] : []
    settings["outputFields"] = settings["outputFields"] ? settings["outputFields"] : []

    const getItemSingleCombined = (execSelected) => {
        var results = []
        var result = {}

        result["#exec"] = execSelected.toString()   //Needs to be string for sorting reasons
        settings["inputFields"].forEach(field => {
            result[field + " (in)"] = execAll[execSelected]["in"][field]
        });

        if (execAll[execSelected]["out"].length === 0) {
            settings["outputFields"].forEach(field => {
                result[field + " (out)"] = "-----"
            });
            results.push(result)
        } else {
            execAll[execSelected]["out"].forEach(execItemOut => {
                var result2 = { ...result }
                settings["outputFields"].forEach(field => {
                    result2[field + " (out)"] = execItemOut[field]
                });
                results.push(result2)
            })
        }

        return results
    }

    const getItemsAllCombined = () => {
        var results = []

        for (let i = 0; i < execAllArray.length; i++) {
            getItemSingleCombined(execAllArray[i]["#exec"]).forEach(item => results.push(item))
        }
        return results
    }

    const functionToUseCombined = showAll !== true ? getItemSingleCombined : getItemsAllCombined

    const dataCombined = functionToUseCombined(execSelected)
    const [sortColumns, setSortColumns] = wsProvider.useStateVar("command", "sortColumns", [])

    if (execAllArray.length > 0 && (execAll[execSelected] || showAll === true))
        return (
            <div className="row">
                <div className="col-12">
                    <ItemsTableCommand
                        tableData={dataCombined}
                        mergeCustomFields={false}
                        showInfo={true}
                        sortColumns={sortColumns}
                        setSortColumns={setSortColumns}
                        showAll={showAll}
                        execSelected={execSelected}
                        groupByField={groupByField}
                    />
                </div>
            </div>
        )
    return null

}