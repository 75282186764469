import React, {useState} from 'react';

export const ToggleWrapper = (props) => {
    const [show, setShow] = useState(false);
    
    const toggleShow = () => {
        setShow(show1 => !show1)
    }
    return (
        <div>
            <a onClick={toggleShow}>{show ? "-" : "+"} {props.title}</a>
            { show &&
                <>
                    {props.children}
                </>
            }
        </div>
    )
}
