import React, { useState, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';
import { ItemsTableDefault } from '../../Various/ItemsTable/ItemsTable';
import Form from '@rjsf/core';
import TopicGroupMultiSelect from './TopicGroupMultiSelect';

const headerSchema = {
    schema: {
        "type": "object",
        "properties": {
            "load_all_clients": {
                "type": "boolean",
                "default": false
            },
        }
    },
    uiSchema: {}
}

export default function WorkspaceTopicsToDo() {
    const wsProvider = React.useContext(ClientProvider);

    // HEADER
    const [headerData, setHeaderData] = useState({
        "load_all_clients": false
    })

    const headerDataUpdate = ({ formData }) => {
        setHeaderData(formData)
    }

    useEffect(() => {
        loadData()
    }, [headerData.load_all_clients])

    // TABLES
    const [sortColumns, setSortColumns] = useState([])
    const [filterColumns, setFilterColumns] = useState({})
    const [sortColumns_details, setSortColumns_details] = useState([])

    // TODO-DATA
    const [toDo, setToDo] = useState([])
    const [toDoTable, setToDoTable] = useState([])

    const loadData = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "get_file_Topics_MISSING_toDo",
            options: {
                load_all_clients: headerData.load_all_clients,
                project_id: wsProvider.selectedProjectId,
                client: wsProvider.clientSelected,
            }
        }, (response) => {
            setToDo(response.data)
        })
    }

    // MATCHING
    const [matching, setMatching] = useState()

    useEffect(() => {
        wsProvider.ws.waitForSocketAndSend({
            action: "get_TopicSystem_ToDo_Matching",
            options: {}
        }, (response) => {
            if (response) {
                setMatching(response)
            }
        })
    }, [])

    // AUTOSAVE
    useEffect(() => {
        if (matching === undefined) {
            return
        }
        wsProvider.ws.waitForSocketAndSend({
            action: "set_TopicSystem_ToDo_Matching",
            options: {
                data: matching
            }
        }, (response) => { })
    }, [matching])

    useEffect(() => {
        if (matching === undefined || toDo.length === 0) {
            setToDoTable(toDo)
            return
        }
        setToDoTable(toDo.map(item => ({
            ...item,
            topic_groups_set: matching ? matching[item.topic]?.join("|") : "",
            topic_groups_set_count: (matching && matching[item.topic]) ? matching[item.topic].length : ""
        })))
    }, [matching, toDo])

    // UI-STATE & ACTIONS
    const [itemActive, setItemActive] = useState(false)

    const clickHandler = (item, column, index, itemOrig, columnIndex) => {
        setItemActive(itemOrig)
    }

    const clickHandler_details = (item, column, index, itemOrig, columnIndex) => {
        if (column === "url_value") {
            window.open(itemOrig.url_value.replace(/\\/gi, "/"), '_blank');
        }
    }

    // RUN
    const [waitingForBatchJobsDone, setWaitingForBatchJobsDone] = useState([])

    useEffect(() => {
        // Register Callback
        // Return is the cleanup function
        return wsProvider.registerBatchJobUpdateHandler("TopcisComboRefresh", handleBatchJobUpdate)
    }, [JSON.stringify(waitingForBatchJobsDone)])

    const handleBatchJobUpdate = (data) => {
        if (!waitingForBatchJobsDone.includes(data["batchJobId"]))
            return

        if (data["level"] === "batchJob" && data.stats.status === "SUCCEEDED") {
            loadData()
        }
    }

    const run_merge_topics_from_ui = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "topic_system_merge_topics_from_ui",
            options: {}
        }, (response) => {
            let projectPathSelector
            if (headerData.load_all_clients) {
                projectPathSelector = {
                    "project": wsProvider.selectedProjectId,
                    "selectionType": "all_by_project_path",
                    "selectionValue": "live",
                    "maxClients": 0
                }
            } else {
                projectPathSelector = {
                    "project": wsProvider.selectedProjectId,
                    "selectionType": "single",
                    "selectionValue": wsProvider.clientSelected,
                    "maxClients": 0
                }
            }
            wsProvider.ws.waitForSocketAndSend({
                action: "batchJobInit",
                options: {
                    "batchJobName": "edufind.ClientsRunEdufind",
                    "batchJobOptions": {
                        "engine": "awslambda",
                        "projectPathSelector": projectPathSelector,
                        "runTopicGroupsAddCommandSet": true,
                        "topicGroupsAdd_stats": true,
                    }
                }
            }, (data) => {
                setWaitingForBatchJobsDone(x => [...x, data["batchJobId"]])
            })
        })

    }


    return (<>
        <div className="row">
            <div className="col-12 workspaceTop ">
                <div className='flex_header' style={{ height: "calc(100% - 30px)" }}>
                    <div className='header'>
                        <div className='row' style={{ marginBottom: "10px" }}>
                            <div className='col-6'>
                                <Form
                                    className="FormStatisticDiffHeader"
                                    schema={headerSchema["schema"]}
                                    uiSchema={headerSchema["uiSchema"]}
                                    formData={headerData}
                                    onChange={headerDataUpdate}
                                >
                                    <button className="btn btn-info" style={{ "display": "none" }}>Load</button>
                                </Form>
                            </div>
                            <div className='col-6'>
                                <div style={{ "float": "right", "marginRight": "3px" }}>
                                    <button
                                        onClick={run_merge_topics_from_ui}
                                        className="btn btn-primary btn-sm"
                                        title='run_merge_topics_from_ui and runTopicGroupsAddCommandSet'
                                    >
                                        <i className="bi bi-play-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='row height100 '>
                            <div className='col-4'>
                                <ItemsTableDefault
                                    tableData={toDoTable}
                                    sortColumns={sortColumns}
                                    setSortColumns={setSortColumns}
                                    filterColumns={filterColumns}
                                    setFilterColumns={setFilterColumns}
                                    hideColumns={["count"]}
                                    clickHandler={clickHandler}
                                    noMemo={true}
                                />
                            </div>
                            <div className='col-8'>
                                {itemActive &&
                                    <>
                                        <div style={{ display: "flex", marginBottom: "10px", justifyContent: "space-between" }}>
                                            <button
                                                onClick={() => {
                                                    const indexNow = toDoTable.findIndex(x => x.topic === itemActive.topic)
                                                    setItemActive(toDoTable[indexNow - 1])
                                                }}
                                                className="btn btn-primary btn-sm"
                                                style={{
                                                    width: "200px",
                                                }}
                                                disabled={toDoTable.findIndex(x => x.topic === itemActive.topic) === 0}
                                            >
                                                BACK
                                            </button>
                                            <button
                                                onClick={() => {
                                                    const indexNow = toDoTable.findIndex(x => x.topic === itemActive.topic)
                                                    setItemActive(toDoTable[indexNow + 1])
                                                }}
                                                className="btn btn-primary btn-sm"
                                                style={{
                                                    width: "200px",
                                                }}
                                                disabled={toDoTable.findIndex(x => x.topic === itemActive.topic) + 1 >= toDoTable.length}
                                            >
                                                NEXT
                                            </button>
                                        </div>
                                        <h3>{itemActive.topic}</h3>
                                        <div style={{ display: "flex", columnGap: "10px" }}>
                                            <div style={{ flex: "1" }}>

                                                <TopicGroupMultiSelect
                                                    values={matching && matching[itemActive.topic] ? matching[itemActive.topic] : []}
                                                    setValues={(values) => setMatching(x => ({ ...x, [itemActive.topic]: values }))}
                                                    key={itemActive.topic}

                                                />
                                            </div>
                                            <button
                                                onClick={() => {
                                                    setMatching(x => ({ ...x, [itemActive.topic]: ["99###999###9999"] }))
                                                }}
                                                className="btn btn-primary btn-sm"
                                            >
                                                STOPWORD
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setMatching(x => {
                                                        const result = { ...x }
                                                        delete result[itemActive.topic]
                                                        return result
                                                    })
                                                }}
                                                className="btn btn-primary btn-sm"
                                            >
                                                RESET
                                            </button>
                                        </div>
                                        <h6>{itemActive.items.length} Items</h6>
                                        <div className='flex_header' style={{ height: (((itemActive.items.length + 2) * 25) + 2) + "px" }}>
                                            <ItemsTableDefault
                                                key={JSON.stringify(itemActive.items)}
                                                tableData={itemActive.items}
                                                sortColumns={sortColumns_details}
                                                setSortColumns={setSortColumns_details}
                                                clickHandler={clickHandler_details}

                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}