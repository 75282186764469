/* eslint-disable no-console, react/no-access-state-in-setstate */
import React from 'react';
import Tree from 'rc-tree';
import ClientProvider from '../../Various/ClientProvider';
import CompWrapper from '../CompWrapper'
import { procConfigCommands } from './procConfigCommands'
import Form from "@rjsf/core";

export default class ProcConfigTree extends React.Component {
  static contextType = ClientProvider

  constructor(props, context) {
    super(props, context)
    this.state = {
      selected: [undefined],
      expanded: null,

    };
    /*context.setCommandsTree(this)*/
  }

  getSelected = () => this.state.selected
  setSelected = (value) => this.setState({ selected: value })

  getExpanded = () => this.state.expanded
  setExpanded = (value) => this.setState({ expanded: value })

  //getSelected = () => this.context.getStateVar("command", "procConfigSelected", [undefined])
  //setSelected = (value) => this.context.setStateVar("command", "procConfigSelected", value)

  //getExpanded = () => this.context.getStateVar("command", "procConfigExpanded", null)
  //setExpanded = (value) => this.context.setStateVar("command", "procConfigExpanded", value)

  getLevel = (key) => key === undefined | key === null ? undefined : key.toString().split("_").length - 1
  getLevelTop = (key) => key.toString().split("_")[0]

  getLevelFirstSelected = () => this.getLevelTop(this.getSelected()[0])
  getLevelSelected = () => this.getLevel(this.getSelected()[0])
  getPositionSelected = () => this.getSelected()[0].toString().split("_")[this.getLevelSelected()]
  getLevelSecondSelected = () => this.getSelected()[0].toString().split("_")[1]

  onSelect = (selectedKeys, e) => {
    this.setSelected(selectedKeys)
    if (this.getLevel(selectedKeys[0]) >= 2) {
      //Exec Selected
      this.context.setStateVar("crawler", "execSelected", e.node["title"])
      this.context.setStateVar("crawler", "sourceSelected", e.node["#source"])
      this.context.setStateVar("crawler", "textProcessorTabs", 0)

    }
  };

  allowDrop = ({ dragNode, dropNode, dropPosition }) => {

    let dragLevel = this.getLevel(dragNode["key"])
    let dropLevel = this.getLevel(dropNode["key"])
    let dragLevelTop = this.getLevelTop(dragNode["key"])
    let dropLevelTop = this.getLevelTop(dropNode["key"])

    switch (dragLevel) {
      case 0:
        if (dropPosition === 1) {
          //Same Level
          if (dragLevel === dropLevel)
            return true
        } else {
          //Level Deeper under parent
          //if(dragLevel===dropLevel+1)
          //  return true
        }
        return false
      case 1:
        if (dragLevelTop === dropLevelTop)
          if (dropPosition === 1) {
            //Same Level
            if (dragLevel === dropLevel)
              return true
          } else {
            //Level Deeper under parent
            //if(dragLevel===dropLevel+1)
            //  return true
          }
        return false
      case 2:
        //Exec Selected
        return false
    }
  }

  onDrop = info => {
    const dragNode = info.dragNode
    const dropNode = info.node
    const dropPosition = info.dropPosition
    const dropLevelTop = this.getLevelTop(dropNode["key"])
    let dragPosition


    let dragLevel = this.getLevel(dragNode["key"])
    let procConfig = [...this.context.CommandsTree.getSingleSetting("procConfig")]

    switch (dragLevel) {
      case 0:
        //Group Selected
        dragPosition = dragNode["key"]
        array_move(procConfig, dragPosition, dropPosition - (dropPosition > dragPosition ? 1 : 0))
        this.context.CommandsTree.setSingleSetting("procConfig", procConfig)
        break
      case 1:
        dragPosition = dragNode["key"].toString().split("_")[1]
        procConfig[dropLevelTop]["values"] = [...procConfig[dropLevelTop]["values"]]
        array_move(procConfig[dropLevelTop]["values"], dragPosition, dropPosition - (dropPosition > dragPosition ? 1 : 0))
        this.context.CommandsTree.setSingleSetting("procConfig", procConfig)
        break
    }
  };

  onExpand = expandedKeys => {
    this.setExpanded(expandedKeys)
  };

  onDelete = () => {

    let procConfig = [...this.context.CommandsTree.getSingleSetting("procConfig")]
    const deleteLevelTop = this.getLevelFirstSelected()
    let positionSelected = this.getPositionSelected()
    switch (this.getLevelSelected()) {
      case 0:
        //Select Last Element when deleted last

        if ((parseInt(positionSelected) + 1) === procConfig.length)
          if (procConfig.length - 2 >= 0)
            this.setSelected([procConfig.length - 2])

          else
            this.setSelected([undefined])

        procConfig.splice(positionSelected, 1);

        this.context.CommandsTree.setSingleSetting("procConfig", procConfig)

        //Group Selected
        break
      case 1:
        procConfig[deleteLevelTop]["values"] = [...procConfig[deleteLevelTop]["values"]]

        //Unset selected if you delete the last one... [otherwise bug with form]
        if ((parseInt(positionSelected) + 1) === procConfig[deleteLevelTop]["values"].length)
          this.setSelected([undefined])

        procConfig[deleteLevelTop]["values"].splice(positionSelected, 1);

        this.context.CommandsTree.setSingleSetting("procConfig", procConfig)
        //Value Selected
        break
      case 2:
        //Exec Selected
        break
    }

  }

  onAdd = () => {
    let procConfig = [...this.context.CommandsTree.getSingleSetting("procConfig")]
    switch (this.getLevelSelected()) {
      case undefined:
        procConfig.push({ command: "SplitRegex", values: [] })
        this.context.CommandsTree.setSingleSetting("procConfig", procConfig)

        this.setSelected([(procConfig.length - 1).toString()])

        break
      case 0:
      case 1:
        const levelTopSelected = this.getLevelFirstSelected()
        procConfig[levelTopSelected]["values"] = [
          ...procConfig[levelTopSelected]["values"],
          {}
        ]
        this.context.CommandsTree.setSingleSetting("procConfig", procConfig)

        this.setSelected([(levelTopSelected).toString() + "_" + (procConfig[levelTopSelected]["values"].length - 1).toString()])

        break
      case 2:
        break
    }
  }

  getTreeData = (procConfig) => {
    const procConfigStats = this.context.CommandsTree.getProcConfigStats()

    let result = []
    procConfig.forEach((commandGroup, commandI) => {
      result.push({
        title: commandGroup["command"] + " (" + commandGroup["values"].length + ")",
        command: commandGroup["command"],
        disabled1: commandGroup["disabled1"],
        key: commandI,
        className: commandGroup["disabled1"] === true ? "disabled1" : null,
        children: commandGroup["values"].map((x, i) => {
          if (procConfigStats !== null && procConfigStats[commandI] && procConfigStats[commandI][i]) {
            var children = Object.keys(procConfigStats[commandI][i]).filter(key => procConfigStats[commandI][i][key]["result"]).map((execI) => {
              return {
                title: execI,
                key: commandI + "_" + i + "_" + execI,
                "#source": procConfigStats[commandI][i][execI]["#source"]
              }
            })
          } else {
            var children = undefined
          }
          return {
            title: (x ? x["valRegex"] : "SOMETHING MISSING 1") + " (" + (children !== undefined ? children.length : "?") + ")",
            key: commandI + "_" + i,
            className: x ? (x["disabled1"] === true ? "disabled1" : null) : "SOMETHING MISSING 2",
            children: children ? children : []
          }
        })
      })
    })
    return result
  }
  render() {
    if (this.context.CommandsTree && this.context.CommandsTree.getAttr("className") == "textProcessor") {

      var useProcConfig = this.context.CommandsTree.getSingleSetting("procConfig")
      if (!useProcConfig) {
        useProcConfig = []
        this.context.CommandsTree.setSingleSetting("procConfig", useProcConfig)
      }
      let treeData = this.getTreeData(this.context.CommandsTree.getSingleSetting("procConfig"))
      return (
        <>
          <CompWrapper
            className="ProcConfigTree"
            title="ProcConfig"
            buttonBar={(
              <>
                <button
                  onClick={this.onAdd}
                  className="btn btn-primary btn-sm"
                  disabled={this.context.getCrawlerSelected() && (this.getLevelSelected() === 0 || this.getLevelSelected() === 1 || this.getLevelSelected() === undefined) ? "" : "disabled"}
                >
                  <i className="bi bi-plus"></i>
                </button>
                <button
                  onClick={this.onDelete}
                  className="btn btn-primary btn-sm"
                  disabled={(this.getLevelSelected() === 0 || this.getLevelSelected() === 1) ? "" : "disabled"}
                >
                  <i className="bi bi-dash"></i>
                </button>
              </>
            )}
          >
            <Tree
              treeData={treeData}

              allowDrop={this.allowDrop}
              draggable
              onDrop={this.onDrop}

              onExpand={this.onExpand}
              expandedKeys={this.getExpanded()}
              autoExpandParent={false}
              defaultExpandAll={true}

              onSelect={this.onSelect}
              selectedKeys={this.getSelected()}
            />
            {this.getLevelSelected() === 0 &&
              (
                <ProcConfigCommandGroup
                  treeData={treeData}
                  levelTopSelected={this.getLevelFirstSelected()}
                />
              )
            }
            {(this.getLevelSelected() === 1 || this.getLevelSelected() === 2) &&
              (
                <ProcConfigValue
                  treeData={treeData}
                  levelTopSelected={this.getLevelFirstSelected()}
                  positionSelected={this.getLevelSecondSelected()}
                />
              )
            }
          </CompWrapper>
        </>
      );
    }
    return null
  }
}

function ProcConfigCommandGroup({ treeData, levelTopSelected }) {

  const wsProvider = React.useContext(ClientProvider);

  if (treeData[levelTopSelected] === undefined)
    return null


  const changeHandler = (a) => {
    let procConfig = [...wsProvider.CommandsTree.getSingleSetting("procConfig")]
    procConfig[levelTopSelected]["command"] = a["formData"]["command"]
    procConfig[levelTopSelected]["disabled1"] = a["formData"]["disabled1"]
    wsProvider.CommandsTree.setSingleSetting("procConfig", procConfig)
  }

  return (
    <Form
      schema={{
        type: 'object',
        properties: {
          disabled1: {
            "title": 'Deaktivieren',
            "type": 'boolean'
          },
          command: {
            enum: Object.keys(procConfigCommands),
            title: "Command-Group",
            type: 'string'
          }
        },
        dependencies: {},
        required: []
      }}
      uiSchema={{
        'ui:order': [
          'command',
          'disabled1'
        ]
      }}
      formData={{
        command: treeData[levelTopSelected]["command"],
        disabled1: treeData[levelTopSelected]["disabled1"]
      }}
      onChange={changeHandler}
    />
  )
}

function ProcConfigValue({ treeData, levelTopSelected, positionSelected }) {
  const wsProvider = React.useContext(ClientProvider);
  let procConfig = [...wsProvider.CommandsTree.getSingleSetting("procConfig")]

  const changeHandler = (a) => {

    //procConfig[levelTopSelected]["values"] = [...procConfig[levelTopSelected]["values"]]
    procConfig[levelTopSelected]["values"][positionSelected] = a["formData"]
    wsProvider.CommandsTree.setSingleSetting("procConfig", procConfig)
  }

  return (
    <CompWrapper
      className="ProcConfigTreeValue"
      title="ProcConfig: Edit"
    >
      <Form
        schema={procConfigCommands[treeData[levelTopSelected]["command"]]["schema"]}
        uiSchema={procConfigCommands[treeData[levelTopSelected]["command"]]["uischema"]}
        formData={procConfig[levelTopSelected]["values"][positionSelected]}
        onChange={changeHandler}
      />
    </CompWrapper>
  )
}

function array_move(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}