import React, { useState, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';

import Form from "@rjsf/core";
import MonacoWidgetPy from '../../Various/rjsf/monacoWidgetPy';
import MonacoWidgetXpath from '../../Various/rjsf/monacoWidgetXpath';
import MonacoWidgetJs from '../../Various/rjsf/monacoWidgetJs';
import MonacoWidgetJson from '../../Various/rjsf/monacoWidgetJson';
import EcedFullSelectWidget from '../../SideBarRight/WordsLib/ecedFullSelectWidget';
import JsonViewWidget from '../../Various/rjsf/jsonViewWidget';

//import { getDefaultCompilerOptions } from 'typescript';

export default function WorkspaceCommandBottom() {
    const wsProvider = React.useContext(ClientProvider);
    const [commandConfig, setCommandConfig] = useState(null)
    // const [firstChange, setFirstChange] = useState(false)

    const handleKeyDown = () => {
        console.log("here")
    }

    const widgets = {
        monacojs: MonacoWidgetJs,
        monacojson: MonacoWidgetJson,
        monacopy: MonacoWidgetPy,
        monacoXpath: MonacoWidgetXpath,
        ecedFullSelectWidget: EcedFullSelectWidget

    };

    const command = wsProvider.CommandsTree.getAttr("className")

    const getSchema = () => {

        const field = wsProvider.CommandsTree.getAttr("title")

        let schema = {
            ...commandConfig["schema"],
            "properties": {
                ...commandConfig["schema"]["properties"]
            }
        }

        //add dynamic fields
        if (command == "pipe") {
            var fields = wsProvider.CrawlerTree.getFields(false)
            schema["properties"]["inputFields"]["items"]["enum"] = fields
            schema["properties"]["outputFields"]["items"]["enum"] = fields
        }
        else if (command == "fromCrawlers") {
            var fromCrawlersEnum = []
            var fromCrawlersEnumNames = []

            wsProvider.clientConfig[0]["children"].every(element => {
                if (element["key"] === wsProvider.getCrawlerSelected())
                    return false
                fromCrawlersEnum.push(element.key)
                fromCrawlersEnumNames.push(element.title)
                return true
            });

            schema["properties"]["keys"]["items"]["enum"] = fromCrawlersEnum
            schema["properties"]["keys"]["items"]["enumNames"] = fromCrawlersEnumNames
        } else if (command == "textProcessor") {
            // useField: add dynamically
            var fields = wsProvider.CrawlerTree.getFields(false)
            schema["properties"]["useField"]["enum"] = [""].concat(fields)

        }

        return schema
    }

    const getUiSchema = () => {
        const field = wsProvider.CommandsTree.getAttr("title")
        let uischema = commandConfig["uischema"]

        /*
        //add fieldSpecExtra
        if ("fieldSpecExtra" in commandConfig) {
            var fieldSpecExtra = commandConfig["fieldSpecExtra"]
            if (field in fieldSpecExtra) {
                const { ["ui:order"]: uischemaAdd, ...uischemaProps } = uischema;
                const { ["ui:order"]: extraUischemaAdd, ...extraUischemaProps } = fieldSpecExtra[field]["uischema"];

                uischema = {
                    "ui:order": [
                        ...uischemaAdd,
                        ...extraUischemaAdd
                    ],
                    ...uischemaProps,
                    ...extraUischemaProps
                }
            }
        }
        */

        return uischema

    }

    useEffect(() => {
        wsProvider.ws.waitForSocketAndSend({
            action: "getCommandSchema",
            options: {
                client: wsProvider.clientSelected,
                crawlerKey: wsProvider.getCrawlerSelected(),
                commandClassName: wsProvider.CommandsTree.getAttr("className"),
                field: wsProvider.CommandsTree.getAttr("title")
            }
        }, (response) => {
            setCommandConfig(response)
        }
        )
    }, []);

    const changeHandler = (e, a) => {
        /*
        //Buggy!
        if (firstChange === false) {
            setFirstChange(true)
            console.log("First Change")
            return
        } 
        console.log("Second Change")
        */
        wsProvider.CommandsTree.changeAttrSettings(e.formData)
    }
    const formData = wsProvider.CommandsTree.getAttrSettings()

    if (wsProvider.CommandsTree.getAttr("className") === undefined)
        return false

    if (!commandConfig)
        return null

    return (
        <Form

            key={wsProvider.CommandsTree.getAttr("key") + wsProvider.CommandsTree.getAttr("className") + wsProvider.CommandsTree.getAttr("title")}
            schema={getSchema()}
            uiSchema={getUiSchema()}
            formData={formData}
            onChange={changeHandler}
            className={wsProvider.CommandsTree.getAttr("className")}
            widgets={widgets}
        />
    )
}