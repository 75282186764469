const region_table = {
    "ZH": "zurich",
    "BE": "mittelland",
    "FR": "mittelland",
    "SO": "mittelland",
    "NE": "mittelland",
    "JU": "mittelland",
    "BS": "nordwest",
    "BL": "nordwest",
    "AG": "nordwest",
    "GL": "ost",
    "SH": "ost",
    "AR": "ost",
    "AI": "ost",
    "SG": "ost",
    "GR": "ost",
    "TG": "ost",
    "LU": "zentral",
    "UR": "zentral",
    "SZ": "zentral",
    "OW": "zentral",
    "NW": "zentral",
    "ZG": "zentral",
    "VD": "leman",
    "VS": "leman",
    "GE": "leman",
    "TI": "tessin",
    "CH": "schweiz",    //buggy from chatgpt
}

export const propertiesSource = {
    "type": "array",
    "title": "Source",
    "items": {
        "type": "string",
        "enum": [
            "id",
            "avatar",
            "name",
            "position",
            "timestamp",
            "current_company",
            "url",
            "education",
            "plz",
            "city",
            "province",
            "experience"
        ]
    },
    "uniqueItems": true
}

export const propertiesSize = {
    "type": "integer",
    "title": "Size"
}

export const propertiesProvince = {
    "type": "array",
    "title": "Province",
    "items": {
        "type": "string",
        "enum": Object.keys(region_table),
        "enumNames": Object.keys(region_table).map(x => x + " (" + region_table[x] + ")")
    },
    "uniqueItems": true
}