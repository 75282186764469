import Iframe from './load/IFrame/Iframe'

export default function Load({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const renderSingle = (execItem, index) => (
        < >
            {execItem["out"].slice(0, execItem["out"].length < 20 ? execItem["out"].length : 20).map((x) => {
                return (
                    <Iframe
                        content={x}
                        resultHeader={"Input: " + execItem["in"]}
                        title={commandKey + "_" + index}
                    />
                )
            })}
        </ >
    )
    if (showAll !== true) {
        return (
            <div className="row">
                <div className="col-12">
                    {renderSingle(execAll[execSelected], execSelected)}
                </div>
            </div>
        )
    } else {
        return (
            <div className="row">
                <div className="col-12">
                    {execAllArray.map((execItem, index) =>
                        renderSingle(execItem, index)
                    )}
                </div>
            </div>
        )
    }
}