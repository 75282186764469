import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import React, { useState, useEffect } from 'react';
import Tree from 'rc-tree'
import ClientProvider from './ClientProvider';
import ClientSelectSimple from './Select/ClientSelectSimple'
import ProjectPathSelect from './Select/ProjectPathSelect'

export default function CrawlerPicker() {
  const wsProvider = React.useContext(ClientProvider);

  const [show, setShow] = useState(false);
  const [suffix, setSuffix] = useState("");
  const [crawlerKeysSelected, setCrawlerKeysSelected] = useState([]);

  const [client, setClient] = useState("_temp2");
  const [crawlers, setCrawlers] = useState([]);
  const [projectPath, setprojectPath] = useState("templates");

  useEffect(() => {
    // Register Callback
    // Return is the cleanup function
    changeClientHandler(client, "all")
  }, [])

  const handleShow = () => setShow(true)
  const handleHide = () => setShow(false)

  const handleSave = () => {
    // setShow(false);

    wsProvider.ws.waitForSocketAndSend({
      action: "getCrawlersToImport",
      options: {
        clientFrom: client,
        clientTo: wsProvider.clientSelected,
        crawlerKeys: crawlerKeysSelected,
        suffix: suffix
      }
    }, (response) => {
      response.forEach(crawlerConfig => {
        wsProvider.CrawlerTree.addCrawlerFromImport(crawlerConfig, false)
        setShow(false);
      })
    })
  }

  const changeClientHandler = (value, selectCrawlers) => {
    setClient(value)
    wsProvider.ws.waitForSocketAndSend({
      action: "getCrawlersFromClient",
      options: {
        client: value
      }
    }, (response) => {
      setCrawlers(response)
      if (selectCrawlers === "all") {
        setCrawlerKeysSelected(response.map(x => x["key"]))
      }
    })
  }

  const onChangeSuffix = (e) => {
    setSuffix(e.target.value)
  }

  return (
    <>
      <button
        onClick={handleShow}
        className="btn btn-primary btn-sm"
        disabled={(wsProvider.clientConfig.length > 0) ? "" : "disabled"}
        title='Insert Crawler from Template'
      >
        <i className="bi bi-aspect-ratio"></i>
      </button>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Insert Crawlers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectPathSelect projectPath={projectPath} setprojectPath={setprojectPath} />
          <ClientSelectSimple client={client} setClient={changeClientHandler} projectPath={projectPath} />
          <input
            value={suffix}
            placeholder="suffix..."
            onChange={onChangeSuffix}
            className="form-control form-control-sm suffix"
          />
          <Tree
            treeData={crawlers}
            autoExpandParent={true}
            checkable
            checkedKeys={crawlerKeysSelected}
            onCheck={setCrawlerKeysSelected}
            selectable={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}