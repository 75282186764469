import { components } from "react-select";
import AsyncSelect from 'react-select/async';
import ClientProvider from "../../Various/ClientProvider";
import React from "react";


const TopicGroupMultiSelect_Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export default function TopicGroupMultiSelect({ values, setValues }) {
    const wsProvider = React.useContext(ClientProvider);
    const topic_to_topic_group_str_mapping = wsProvider.getFileFromBackend("TopicSystem_TopicToTopicGroupMapping", ["input"])
    const topic_group_labels = wsProvider.getFileFromBackend("TopicSystem_input_topic_group_labels", [])

    const handleChange = (values) => {
        setValues(values.map(x => x.value))
    };

    const getOptionFromTopicGroupStr = (topic_group_str) => {
        return {
            value: topic_group_str,
            label: topic_group_labels.topic_groups[topic_group_str],
        }
    }

    function loadOptions(
        inputValue,
        callback
    ) {
        const inputValueLower = inputValue.toLowerCase()
        const topic_group_str_list = Object.keys(topic_to_topic_group_str_mapping).filter(x => x.startsWith(inputValueLower)).map(topic => topic_to_topic_group_str_mapping[topic]).flat()

        callback(removeDuplicates(topic_group_str_list).map(topic_group_str => getOptionFromTopicGroupStr(topic_group_str)))
    };

    return <AsyncSelect
        isMulti
        closeMenuOnSelect={true}
        hideSelectedOptions={true}
        components={{
            TopicGroupMultiSelect_Option
        }}
        onChange={handleChange}
        allowSelectAll={true}
        value={values.map(value => getOptionFromTopicGroupStr(value))}
        loadOptions={loadOptions}
    />
}

function removeDuplicates(arr) {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
} 