import FilterOutputPanel from './FilterOutputPanel'
import ClientProvider from '../../../../Various/ClientProvider';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tree from 'rc-tree';
import generateTreeData from './_generateTreeDataFunctions'
import { getFlat } from './TreeSelect'
import { onlyUnique } from '../../../../Various/TreeFilter/helpers'

export default function FilterTypePickValues({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const wsProvider = React.useContext(ClientProvider);

    const field = wsProvider.CommandsTree.getSingleSetting("field", "title")

    if (!field) {
        return "Please choose a field"
    }

    const uniqueValues = Object.keys(execAll).map(x => execAll[x].in[field]).filter(onlyUnique)

    const data = uniqueValues.map(x => {
        return {
            key: x,
            title: x,
            isLeaf: true
        }
    })

    const checkedNodes = wsProvider.CommandsTree.getSingleSetting("checkedNodes", [])

    const setCheckedNodes = (value) => {
        //Filter Nodes that are not possible
        const result = value
        wsProvider.CommandsTree.setSingleSetting("checkedNodes", result)
    }

    return (
        <Tabs>
            <TabList>
                <Tab>Workspace</Tab>
                <Tab>Output</Tab>
            </TabList>
            <TabPanel>
                <Tree
                    treeData={data}
                    selectable={false}
                    checkable={true}
                    checkedKeys={{ checked: checkedNodes }}
                    checkStrictly={true}
                    onCheck={(value) => {
                        setCheckedNodes(value["checked"])
                    }}
                />
            </TabPanel>
            <TabPanel>
                <FilterOutputPanel
                    execAll={execAll}
                    execAllArray={execAllArray}
                    showAll={showAll}
                    execSelected={execSelected}
                    commandKey={commandKey}
                />
            </TabPanel>
        </Tabs>
    )
}