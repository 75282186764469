import { useContext } from 'react';
import QueryContext from '../Context';
import ClientProvider from '../../../../Various/ClientProvider';


export default function RunButtonQuery({ }) {
    const wsProvider = useContext(ClientProvider);
    const { setResponse, setResponseTimestamp, query, index } = useContext(QueryContext)

    const run_query = () => {

        wsProvider.ws.waitForSocketAndSend({
            action: "runESQuery",
            options: {
                query,
                index
            }
        }, (response) => {
            setResponseTimestamp(Date.now())
            setResponse(response)
        })
    }

    return <button
        onClick={run_query}
        className="btn btn-primary btn-md"
        style={{ "width": "100%", marginTop: "10px" }}
        type="button"
    >
        Run
    </button>
}