import React from 'react'
import ClientProvider from '../ClientProvider';

export default function ButtonOpenWebsite() {
  const wsProvider = React.useContext(ClientProvider);

  return (
    <div className="col-1">
      <div className="d-grid gap-2">
        {wsProvider.clientSelected && wsProvider.clientConfig.length &&
          <a
            className="btn btn-primary btn-sm"
            href={wsProvider.clientConfig[0]["settings"]["url"]}
            role="button"
            target="_blank"
          >
            <i className="bi bi-box-arrow-up-right"></i>
          </a>
        }
      </div>
    </div>
  )
}