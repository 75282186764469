import React from 'react';
import ClientProvider from '../../Various/ClientProvider';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Form from "@rjsf/core";
import envs from '../../../envs'
import ExportButton from "../../Various/ExportButton";
import ProjectPathProvider from "../../Various/ProjectPathProvider";

export default function WorkspaceCrawlerSettings() {
  const schema = {
    type: 'object',
    properties: {
      save_xlsx: {
        type: 'boolean'
      },
      crawl_max_items: {
        type: 'number'
      },
      wordsLib: {
        type: 'string'
      },
      custom_fields: {
        items: {
          type: 'object',
          title: 'Field',
          properties: {
            field_name: {
              type: 'string'
            }
          },
          dependencies: {
            field_name: {
              properties: {
                field_validation: {
                  enum: [
                    'float',
                    'int',
                    'custom'
                  ],
                  type: 'string',
                  enumNames: [
                    'float',
                    'int',
                    'custom'
                  ]
                }
              },
              required: []
            },
            field_validation: {
              oneOf: [
                {
                  properties: {
                    field_validation: {
                      enum: [
                        'custom'
                      ]
                    },
                    field_validation_custom: {
                      type: 'string'
                    }
                  }
                },
                {
                  properties: {
                    field_validation: {
                      enum: [
                        'int'
                      ]
                    }
                  }
                },
                {
                  properties: {
                    field_validation: {
                      enum: [
                        'float'
                      ]
                    }
                  }
                }
              ]
            }
          },
          required: []
        },
        title: 'Output',
        type: 'array'
      }
    },
    dependencies: {},
    required: []
  }

  const uischema = {
    'ui:order': [
      'save_xlsx',
      'crawl_max_items',
      'wordsLib',
      'custom_fields'
    ],
    wordsLib: {
      'ui:widget': 'textarea'
    },
    custom_fields: {
      items: {
        'ui:order': [
          'field_name',
          'field_validation',
          'field_validation_custom'
        ],
        field_validation_custom: {
          'ui:widget': 'textarea'
        }
      }
    }
  }

  const wsProvider = React.useContext(ClientProvider);
  const projectPathProvider = React.useContext(ProjectPathProvider);

  const changeHandler = (e) => wsProvider.CrawlerTree.changeAttr("settings", e.formData)
  const formData = wsProvider.CrawlerTree.getAttr("settings")
  const [workspaceCrawlerTabs, setWorkspaceCrawlerTabs] = wsProvider.useStateVarGlobal("workspaceCrawlerTabs", 0)
  const crawlerKey = wsProvider.getCrawlerSelected()

  return (
    <Tabs
      selectedIndex={workspaceCrawlerTabs}
      onSelect={setWorkspaceCrawlerTabs}
    >
      <TabList>
        <Tab>Settings</Tab>
        <Tab>Admin</Tab>
      </TabList>
      <TabPanel className="">
        <Form
          schema={schema}
          uiSchema={uischema}
          formData={formData}
          onChange={changeHandler}
          className={"crawlerSettings"}
        //widgets={widgets}
        />
      </TabPanel>
      <TabPanel className="">
        <div className='row'>
          <div className='col-1'>
            <div className="d-grid gap-2">
              <ExportButton type={"crawler_items"} label={"Export Items"} parameters={{"project": projectPathProvider.selectedProjectId, "client": wsProvider.clientSelected, "crawler": crawlerKey}} />
              <ExportButton type={"crawler_stats"} label={"Export Stats"} parameters={{"project": projectPathProvider.selectedProjectId,  "client": wsProvider.clientSelected, "crawler": crawlerKey}} />
            </div>
          </div>
        </div>
      </TabPanel>
    </Tabs>
  )
}