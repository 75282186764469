import React from 'react'
import ClientProvider from '../ClientProvider';

export function restartServer(wsProvider, callback = () => { }) {

  console.log("Going to Restart Server")
  wsProvider.setJobs({})

  wsProvider.ws.waitForSocketAndSend({
    action: "restartServer",
    options: {}
  }, () => {

  })
}

export default function ButtonRestartServer() {
  const wsProvider = React.useContext(ClientProvider);

  const actionHandler = () => {
    restartServer(wsProvider)
  }

  return (
    <div className="col-1">
      <div className="d-grid gap-2">
        <button
          onClick={actionHandler}
          className="btn btn-primary btn-sm"
          type="button"
          disabled={wsProvider.connectedStatus ? '' : 'disabled'}
        >
          Restart Server
        </button>
      </div>
    </div>
  )
}