import * as PropTypes from "prop-types";
import {useState} from "react";
import {ItemsTableDefault} from "../../../../Various/ItemsTable/ItemsTable";

export default function DishDetails({ data }) {
    const [sortColumns, setSortColumns] = useState([])
    if (!data) {
        return null
    }

    console.log(data[0])

    const tableDataPrepared = Object.getOwnPropertyNames(data[0]).map(x => {
       if (Array.isArray(data[0][x])) {
            data[0][x] = data[0][x].join(", ")
        }
        return { "name": x, "value": data[0][x] }
    })

    return <><h5>Dish</h5><ItemsTableDefault
        tableData={tableDataPrepared}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        noMemo={true}
        columnsSettings={{
            "name": {
                minColumnWidth: 200,
                minWidth: 200,
                width: 200,
            },
            "value": {
                minColumnWidth: 1000,
                minWidth: 1000,
                width: 1000,
            },

        }}
    /></>
}