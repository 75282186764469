//import {useState} from "react";
import ItemsTable, { ItemsTableOther } from '../../../Various/ItemsTable/ItemsTable'
import React, { useState } from 'react'
import ClientProvider from '../../../Various/ClientProvider';


export default function List({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const wsProvider = React.useContext(ClientProvider);

    const [sortColumnsIn, setSortColumnsIn] = useState([])
    const [sortColumnsOut, setSortColumnsOut] = useState([])

    const getItemSingleIn = () => [(execAll[execSelected]["in"])]

    const getItemsAllIn = () => execAllArray.map(x => x["in"])

    const getItemSingleOut = () => execAll[execSelected]["out"]
    const getItemsAllOut = () => execAllArray.map(x => x["out"]).flat(1)

    const functionToUseIn = showAll == false ? getItemSingleIn : getItemsAllIn
    const functionToUseOut = showAll == false ? getItemSingleOut : getItemsAllOut

    const dataIn = functionToUseIn()
    const dataOut = functionToUseOut()

    if (execAllArray.length > 0 && (execAll[execSelected] || showAll == true))
        return (
            <div className="row">
                <div className="col-6">
                    {Object.keys(dataIn[0]).length > 0 &&   //With no keys in item there will ne a bug in ItemsTable
                        <ItemsTableOther
                            tableData={dataIn}
                            sortColumns={sortColumnsIn}
                            setSortColumns={setSortColumnsIn}
                            mergeCustomFields={true}
                            time={wsProvider.CommandsTree.getStatsTime()}
                        />
                    }
                </div>
                <div className="col-6">
                    <ItemsTableOther
                        tableData={dataOut}
                        sortColumns={sortColumnsOut}
                        setSortColumns={setSortColumnsOut}
                        mergeCustomFields={true}
                        time={wsProvider.CommandsTree.getStatsTime()}

                    />
                </div>
            </div>
        )
    return null

}