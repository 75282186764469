import React, { useEffect, useRef, useState } from 'react'
import ClientProvider from '../../Various/ClientProvider';
import StdOutLogElement from './StdOutLogElement'
import CompWrapper from '../CompWrapper'

export default function StdOutLog({ stdOutLog }) {
    const wsProvider = React.useContext(ClientProvider);

    const messagesEndRef = useRef(null)

    /*
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    useEffect(() => {
        scrollToBottom()
      }, [wsProvider.stdOutLog]);
    */

    const listItems = stdOutLog.map((element) =>
        <StdOutLogElement output={element["output"]} key={element["key"]} />
    );



    return (
        <CompWrapper
            className="stdOutLog"
            title={"Log (" + listItems.length + ")"}
            buttonBar={(
                <>
                    {listItems.length > 0 &&
                        <button
                            className="btn btn-primary btn-sm"
                            type="button"
                            onClick={wsProvider.clearLog}
                        >X</button>
                    }
                </>
            )}
        >
            {listItems}
            <div ref={messagesEndRef} />
        </CompWrapper>
    );
} 