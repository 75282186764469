import React, { useState } from "react"
import ClientProvider from '../Various/ClientProvider';


export default function CompWrapper(props) {
    const wsProvider = React.useContext(ClientProvider);
    const [show, setShow] = wsProvider.useStateVar("crawler", "CompWrapper_Open_" + props["className"], true);


    const toggleShow = () => {
        setShow(!show)
    }
    return (
        <div className="CompWrapper">
            <h6><a onClick={toggleShow}>{show ? "-" : "+"} {props.title}</a></h6>
            {show &&
                <>
                    <div className="ButtonBar">
                        {props.buttonBar}
                    </div>
                    <div className={props.className}>
                        {props.children}
                    </div>
                </>
            }
        </div>
    )
}