import React, { useState, useContext } from 'react';

import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

export const DialogContext = React.createContext(null)

export default function DialogYesNo({ children }) {

    const [dialogSettings, setDialogSettings] = React.useState({ open: false });

    const handleToClose = () => {
        setDialogSettings({ ...dialogSettings, "open": false })
    };

    const openDialog = (input_dialogSettings) => {
        setDialogSettings({ ...input_dialogSettings, "open": true })
    }

    return (
        <DialogContext.Provider value={{ openDialog }}>
            <Dialog open={dialogSettings.open} onClose={handleToClose}>
                <DialogTitle>{dialogSettings.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogSettings.prompt}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleToClose(); dialogSettings.handleYes() }}
                        color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button onClick={() => { handleToClose(); dialogSettings.handleNo() }}
                        color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {children}
        </DialogContext.Provider>
    );
}