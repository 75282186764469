import React, { useEffect } from 'react';
import QueryContext from '../Context';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactJson from 'react-json-view';


export default function ConfigWrapper({ children, defaultIndexName,defaultProject,defaultProjectPath,  hasQuery }) {
    const { query, setIndexByIndexName, formData } = React.useContext(QueryContext)

    useEffect(() => {
        if (defaultIndexName) {
            setIndexByIndexName(defaultProject, defaultProjectPath, defaultIndexName)
        }
    }, [])

    return <>
        <Tabs>
            <TabList>
                <Tab>Edit</Tab>
                {hasQuery &&
                    <Tab>Query (JSON)</Tab>
                }
                <Tab>Form-Data (JSON)</Tab>
            </TabList>
            <TabPanel>
                <div style={{ height: "calc(100vh - 320px)" }}>
                    {children}
                </div>
            </TabPanel>
            {hasQuery &&

                <TabPanel>
                    <div style={{ maxHeight: "calc(100vh - 320px)", overflowY: "auto" }}        >
                        <ReactJson
                            src={query}
                            theme="monokai"
                            indentWidth={1}
                            enableClipboard={true}
                            displayDataTypes={false}
                        />
                    </div>
                </TabPanel>
            }
            <TabPanel>
                <div style={{ maxHeight: "calc(100vh - 320px)", overflowY: "auto" }}        >
                    <ReactJson
                        src={formData}
                        theme="monokai"
                        indentWidth={1}
                        enableClipboard={true}
                        displayDataTypes={false}
                    />
                </div>
            </TabPanel>
        </Tabs>
    </>

}