import React, { useEffect, useRef } from 'react'
import ClientProvider from '../../Various/ClientProvider';
import CompWrapper from '../CompWrapper'
import { ItemsTableDefault } from '../../Various/ItemsTable/ItemsTable'
import { useState, useMemo } from 'react';
import { CommandsData } from '../../Workspace/Command/CommandExec/_commands.js';

const columnsSettings = {
    "title": {
        minColumnWidth: 70,
        minWidth: 70,
        // width: 70,        
    },
    "C": {
        minColumnWidth: 45,
        minWidth: 45,
        // width: 45,        
    },
    "er": {
        minColumnWidth: 45,
        minWidth: 45,
        // width: 45,        
    },
    "erC": {
        minColumnWidth: 45,
        minWidth: 45,
        width: 45,
    },
    "in": {
        minColumnWidth: 45,
        minWidth: 45,
        // width: 45,        
    },
    "inC": {
        minColumnWidth: 45,
        minWidth: 45,
        width: 45,
    },
    "fl": {
        minColumnWidth: 45,
        minWidth: 45,
        width: 45,
    },
    "flC": {
        minColumnWidth: 45,
        minWidth: 45,
        width: 45,
    },
    "noExt": {
        minColumnWidth: 60,
        minWidth: 60,
        // width: 60,        
    }
}

const getSummerys = (wsProvider) => {
    var summerys = {}
    var total = {
        "title": "total",
        "command": "total"
    }

    if (!wsProvider.CommandsTree)
        return null

    const commandsStats = wsProvider.CommandsTree.getCommandsStats()
    for (var commandKey in commandsStats) {
        const commandStats = commandsStats[commandKey]
        Object.keys(commandStats["execs"]).forEach(itemId => {
            const execItem = commandStats["execs"][itemId]
            if ("summery" in execItem) {
                if (!(commandKey in summerys)) {
                    summerys[commandKey] = {}
                }
                Object.keys(execItem["summery"]).forEach(summeryCat => {
                    const summeryCatValue = execItem["summery"][summeryCat]
                    if (summeryCat == "title") {
                        if (!("title" in summerys[commandKey])) {
                            summerys[commandKey]["title"] = (summerys[commandKey]["command"] ? summerys[commandKey]["command"] + ": " : "") + summeryCatValue
                        }
                    } else if (summeryCat == "command") {
                        summerys[commandKey][summeryCat] = summeryCatValue
                    } else {
                        summerys[commandKey][summeryCat] = (summerys[commandKey][summeryCat] !== undefined ? summerys[commandKey][summeryCat] : 0) + summeryCatValue
                        total[summeryCat] = (total[summeryCat] !== undefined ? total[summeryCat] : 0) + summeryCatValue
                    }
                })
            }
        })
    }
    summerys["total"] = total

    return summerys
}

const getSummerysPrepared = (wsProvider) => {
    const summerys = getSummerys(wsProvider)

    if (!summerys)
        return null

    var results = []

    const mark_row = {
        "textProcessor": (row) => {

            var res = ""

            if (row.er > 0) {
                return "rowRed"
            }

            if (row.in > 0) {
                switch (row.title) {
                    /* Ignore Info */
                    case "url":
                    case "body":
                    case "title":
                    case "duration":
                    case "targetaudience":
                    case "desc":
                    case "eventtype":
                    case "lessons":
                    case "location":
                    case "studymode":
                    case "timetable":
                        break
                    default:
                        return "rowOrange"
                }
            }

            if (row.noExt > 0) {
                switch (row.title) {
                    case "cluster":
                        return "rowRed"
                    case "eventtype":
                    case "degree": //--> Manual Check Needed
                        break
                    default:
                        return "rowGray"
                }
            }

            if (row.fl === 0) {
                switch (row.title) {
                    /* Freiwillig */
                    case "date2":
                    case "duration":
                    case "targetaudience":
                    case "desc":
                    case "ects":
                    case "lessons":
                    case "timetable":

                    case "cat0": //--> Manual Check Needed
                    case "eventtype": //--> Manual Check Needed
                    case "studymode": //--> Manual Check Needed
                    case "location":
                        return "rowDarkGray"

                    case "cluster": //--> Manual Check Needed
                        return "rowOrange"

                    default:
                        return "rowRed"
                }
            }

            if (row.fl !== row.C) {
                switch (row.title) {
                    case "body":
                    case "title":
                        return "rowRed"
                }
            }


            return "rowGreen"

        },
        "extractTable": (row) => {
            var res = ""
            if (row.er > 0) {
                res = "rowOrange"
            } else {
                res = "rowGreen"
            }
            return res
        },
        "filter": (row) => {
            var res = ""
            if (row.er > 0) {
                res = "rowRed"
            } else {
                res = "rowGreen"
            }
            return res
        },
        "valueToField": (row) => {
            var res = ""
            if (row.er > 0) {
                res = "rowOrange"
            } else {
                res = "rowGreen"
            }
            return res
        },
        "total": (row) => {
            var res = ""
            return res
        }
    }

    const hide_row = (row) => {
        switch (row.command) {
            case "textProcessor":
                return ["lessons", "timetable", "url"].includes(row["title"]) ? true : false
            default:
                return false
        }
    }

    for (var [key, row] of Object.entries(summerys)) {
        if (!hide_row(row)) {        /* HIDE TOTAL */
            results.push({
                ...row,
                key: key,
                addClass: "command" in row ? mark_row[row.command](row) : ""
            })
        }
    }

    return results
}



export default function Summery() {
    const wsProvider = React.useContext(ClientProvider);

    const [sortColumns, setSortColumns] = useState([
        {
            "columnKey": "title",
            "direction": "ASC"
        }
    ])
    const [workspaceTabs, setWorkspaceTabs] = wsProvider.useStateVarGlobal("workspaceTabs")

    const statsTime = wsProvider.CommandsTree ? wsProvider.CommandsTree.getStatsTime() : 0
    const commandsStats = wsProvider.CommandsTree.getCommandsStats()

    const summerysPrepared = useMemo(() => getSummerysPrepared(wsProvider), [statsTime, commandsStats ? Object.keys(commandsStats).length : 0]);

    if (!wsProvider.commandsTreeReady())
        return null

    if (!summerysPrepared)
        return null

    const clickHandler = (item, columnKey, index) => {
        const title = item["title"]

        if (title == "total")
            return

        const matchingCommand = wsProvider.CommandsTree.findCommandRec(x => (x["key"] === item["key"]))
        const commmandName = matchingCommand["className"]
        wsProvider.CommandsTree.goToCommand(matchingCommand["key"])

        var sort1 = undefined
        var textProcessorTabs = undefined
        var total = undefined
        var filterErrorInfoNoExt = undefined



        if (commmandName === "extractTable") {

            switch (columnKey) {
                case "C":
                case "er":
                    setWorkspaceTabs(1)
                    sort1 = [{ "columnKey": "errors", "direction": "DESC" }]
                    break
                default:
                    setWorkspaceTabs(0)

            }
            wsProvider.setStateVar("command", "showAll", true)
        } else if (commmandName === "valueToField") {
            switch (columnKey) {
                case "C":
                    setWorkspaceTabs(0)
                    break
                case "er":
                case "erC":
                    sort1 = [{ "columnKey": title + "errors", "direction": "DESC" }]
                    textProcessorTabs = 1
                    break
            }
        } else if (commmandName === "filter" && wsProvider.CommandsTree.getSingleSetting("type") === "isCourse") {
            switch (columnKey) {
                /*case "C":
                    setWorkspaceTabs(0)
                    break*/
                case "er":
                case "erC":
                    setWorkspaceTabs(1)
                    wsProvider.setStateVar("crawler", "activeColumn", title)
                    const [filterIsCourseTabs, setFilterIsCourseTabs] = wsProvider.useStateVar("command", "filterIsCourseTabs", 0)
                    const [filterIsCourseShowStatus, setFilterIsCourseShowStatus] = wsProvider.useStateVar("command", "filterIsCourseShowStatus", "all")

                    setFilterIsCourseTabs(0)
                    setFilterIsCourseShowStatus(null)
                    break
            }
        } else if (commmandName === "textProcessor") {
            switch (columnKey) {
                case "C":
                    filterErrorInfoNoExt = null
                    setWorkspaceTabs(1)
                    sort1 = [{ "columnKey": "title", "direction": "ASC" }]
                    break
                case "er":
                case "erC":
                    filterErrorInfoNoExt = "errors"
                    sort1 = [{ "columnKey": "s_errors", "direction": "DESC" }]
                    textProcessorTabs = 1
                    break
                case "in":
                case "inC":
                    filterErrorInfoNoExt = "infos"
                    switch (title) {
                        case "title":
                        case "body":
                            sort1 = [{ "columnKey": title, "direction": "ASC" }]
                            break
                        default:
                            sort1 = [{ "columnKey": "s_infos", "direction": "DESC" }]
                    }
                    textProcessorTabs = 2
                    break
                case "fl":
                case "flC":
                    filterErrorInfoNoExt = null
                    textProcessorTabs = 4
                    sort1 = [{ "columnKey": "s_flat", "direction": "DESC" }]
                    total = false
                    break
                case "noExt":
                    filterErrorInfoNoExt = "noExt"

                    textProcessorTabs = 0
                    total = false
                    sort1 = [
                        {
                            "columnKey": "s_flat",
                            "direction": "ASC"
                        },
                        {
                            "columnKey": title,
                            "direction": "DESC"
                        }
                    ]
                    break
            }
        }
        if (filterErrorInfoNoExt !== undefined) {
            wsProvider.setStateVar("command", "textProcessorFilterErrorInfoNoExt", filterErrorInfoNoExt)
        }
        if (sort1 !== undefined) {
            sort1.push({ "columnKey": "title", "direction": "ASC" })
            wsProvider.setLoadFirstTableItem(true)
            wsProvider.setStateVar("crawler", "activeColumn", title)
            setWorkspaceTabs(1)
            wsProvider.setStateVar("crawler", "sortColumns", sort1)
            wsProvider.setStateVar("crawler", "showAll", true)

            wsProvider.setStateVar("crawler", "sourceSelected", {})
            wsProvider.setStateVar("crawler", "execSelected", null)

        }
        if (textProcessorTabs !== undefined) {
            wsProvider.setStateVar("crawler", "textProcessorTabs", textProcessorTabs)
        }
        if (total !== undefined)
            wsProvider.setStateVar("crawler", "totalActive", total)
    }

    const loadAllStats = () => {
        wsProvider.CommandsTree.loadStatsAll()
    }
    return (
        <CompWrapper
            className="summery"
            title="Summery"
            buttonBar={(
                <>
                    <button
                        onClick={loadAllStats}
                        className="btn btn-primary btn-sm"
                    >
                        <i className="bi bi-arrow-repeat"></i>
                    </button>
                </>
            )}
        >
            <div className="row mb-1">
                <ItemsTableDefault
                    tableData={summerysPrepared}
                    mergeCustomFields={false}
                    clickHandler={clickHandler}
                    hideColumns={["key", "erC", "inC", "flC", "command"]}
                    columnsSettings={columnsSettings}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    time={wsProvider.CommandsTree.getStatsTime()}
                />
            </div>
        </CompWrapper>
    );
} 