import { ErrorDetails } from './../ExtractTable'

export default function MultiUnderstand({ execSelected, execAll, execAllArray, showAll, outputFields }) {
    var errors = []
    //All errors in one list


    const getItemSingleCombined = (execSelected) => {
        var results = []
        var result = {}
        result["#exec"] = execSelected.toString()   //Needs to be string for sorting reasons

        if (execAll[execSelected]["out"].length === 0) {
            outputFields.forEach(field1 => {
                result[field1] = "-----"
            });
            results.push(result)
        } else {
            execAll[execSelected]["out"].forEach(execItemOut => {
                var result2 = { ...result }
                outputFields.forEach(field1 => {
                    result2[field1] = execItemOut[field1]
                });
                results.push(result2)
            })
        }

        return results
    }

    const getItemsAllCombined = () => {
        var results = []
        for (let i = 0; i < execAllArray.length; i++) {
            getItemSingleCombined(execAllArray[i]["#exec"]).forEach(item => results.push(item))
        }
        return results
    }

    const functionToUseCombined = showAll === false ? getItemSingleCombined : getItemsAllCombined

    var dataCombined = functionToUseCombined(execSelected)

    for (const row of dataCombined) {
        for (const error of row["s_errors"]) {
            error["count"] = 0
            if (row["url"] !== undefined)
                error["url"] = row["url"]
            errors.push(error)
        }
    }
    //remove duplicates
    var errorsFiltered = errors.filter((value, index, self) =>
        index === self.findIndex((t) => {
            return t["commands"]["AddUnderstand"]["errorhash"] === value["commands"]["AddUnderstand"]["errorhash"]
        })
    )

    //Add count
    for (const error of errors) {
        const errorFiltered = errorsFiltered.find(t => t["commands"]["AddUnderstand"]["errorhash"] === error["commands"]["AddUnderstand"]["errorhash"])
        errorFiltered["count"] = errorFiltered["count"] + 1
    }

    errorsFiltered = errorsFiltered.filter(x => (x.name === "NothingExtracted"))

    //Sort by count
    errorsFiltered.sort(function (a, b) {
        return b["count"] - a["count"]
    });

    errors = errorsFiltered
    if (errors.length === 0)
        return null
    return (
        <ErrorDetails
            data={errors}
            errorName={"AddUnderstand"}
            resultFunc={(formData => {
                return formData.valueMatch
            })}
            checkValueExistsFunc={formData => formData.valueMatch}
            wordsLibType={"understand"}
        />
    )
}