import QueryContext from "./Context"
import ClientProvider from '../../../Various/ClientProvider';
import React, { useState } from "react";
export default function BaseQuery({ config, result }) {
    const wsProvider = React.useContext(ClientProvider);

    const [query, setQuery] = useState()
    const [formData, setFormData] = useState({})
    const [index, setIndex] = useState()
    const [response, setResponse] = useState()
    const [responseTimestamp, setResponseTimestamp] = useState()

    const setIndexByIndexName = (project, project_path, index_name) => {
        wsProvider.ws.waitForSocketAndSend({
            action: "getIndexName",
            options: {
                "project_id": project,
                "project_path": project_path,
                "index_name": index_name
            }
        }, (response) => {
            setIndex(response)
        })
    }

    return <>
        <QueryContext.Provider value={{
            query,
            setQuery,
            formData,
            setFormData,
            index,
            setIndex,
            response,
            setResponse,
            responseTimestamp,
            setResponseTimestamp,
            setIndexByIndexName
        }}>
            <div className='col-2'>
                {config}
            </div>
            <div className='col-10'>
                {result}
            </div>
        </QueryContext.Provider>
    </>
}