import { Processor, ErrorWorkspaceNoNavi, JSONTabTabPanel, ErrorCommandDetail_addWordsLib } from "./TextProcessor"
import React, { useState } from 'react';
import ReactJson from 'react-json-view'
import ClientProvider from '../../../Various/ClientProvider';
import { ToggleWrapper } from '../../../SideBarRight/ToggleWrapper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import getWordsLibConfig from '../../../SideBarRight/WordsLib/getWordsLibConfig'

export default function ExtractTable(props) {
    const wsProvider = React.useContext(ClientProvider);
    const CommandsTree = props["CommandsTree"]

    const field = "thead"


    const outputFields = [
        "title",
        "url",
        field,
        "errors",

    ]

    const clickHandler = (item, column, index) => {
    }

    props = {
        ...props,
        field: field,
        outputFields: outputFields,
        ProcessorDetails: ValueToFieldDetails,
        clickHandler: clickHandler,
        command: "extractTable"
    }

    return Processor(props)
}

const ValueToFieldDetails = ({ row, activeItem, field, dataCombined, execSelected, showAll }) => {
    var errors = []
    //All errors in one list
    for (const row of dataCombined) {
        for (const error of row["errors"]) {
            error["count"] = 0
            errors.push(error)
        }
    }
    //remove duplicates
    const errorsFiltered = errors.filter((value, index, self) =>
        index === self.findIndex((t) => {
            return t["commands"]["AddValueToField"]["errorhash"] === value["commands"]["AddValueToField"]["errorhash"]
        })
    )

    //Add count
    for (const error of errors) {
        const errorFiltered = errorsFiltered.find(t => t["commands"]["AddValueToField"]["errorhash"] === error["commands"]["AddValueToField"]["errorhash"])
        errorFiltered["count"] = errorFiltered["count"] + 1
    }

    //Sort by count
    errorsFiltered.sort(function (a, b) {
        return b["count"] - a["count"]
    });

    errors = errorsFiltered

    if (row)
        return (
            <div className="extractTableDetails">
                <div className="elem1-0">
                    <h4>{row["title"]}</h4>
                    <p>
                        <a href={row["url"]} target="_blank">
                            {row["url"]}
                        </a>
                    </p>
                </div>
                <div className="elem2-0">
                    <Tabs>
                        <TabList>
                            {errors.length > 0 &&
                                <Tab>Error ({errors.length})</Tab>
                            }
                            <Tab>Raw</Tab>
                        </TabList>
                        {errors.length > 0 &&
                            <TabPanel>
                                {Array.isArray(errors) &&
                                    (
                                        <>
                                            <ErrorWorkspaceNoNavi
                                                key={execSelected + showAll ? "true" : "false"}
                                                data={errors}
                                                UseErrorDetailsComp={ErrorDetails}
                                                execSelected={execSelected}
                                                showAll={showAll}
                                                errorName={"AddValueToField"}
                                                wordsLibType={"valueToField"}
                                                resultFunc={(formData) => (formData["fields"] ? formData["fields"].join("\n") : "")}
                                                checkValueExistsFunc={(formData) => (formData["fields"].length > 0)}
                                            />
                                        </>
                                    )
                                }
                            </TabPanel>
                        }
                        <TabPanel>
                            <JSONTabTabPanel data={row[field]} />
                        </TabPanel>
                    </Tabs>
                </div>

            </div>
        )
}

export const ErrorDetails = ({ data, errorName, wordsLibType, resultFunc, checkValueExistsFunc }) => {
    const wsProvider = React.useContext(ClientProvider);
    const wordsLibConfig = getWordsLibConfig({
        baseFields: wsProvider.baseFields,
        type: wordsLibType
    })

    const getFormDatas = () => {
        var result = {}
        for (let line of data) {
            result[line["commands"][errorName]["errorhash"]] = line["commands"][errorName]["formData"]
        }
        //console.log(result)
        return result
    }
    const [formDatas, setFormDatas] = useState(getFormDatas())

    const [errorActive, setErrorActive] = useState(data[0]["commands"][errorName]["errorhash"])
    const errorObjActive = data.find(t => t["commands"][errorName]["errorhash"] === errorActive)
    if (!errorObjActive) {
        setErrorActive(data[0]["commands"][errorName]["errorhash"])
        return null
    }

    const commandActive = errorName
    const commandActiveData = errorObjActive["commands"][errorName]



    const updateData = (key1, data1) => {
        setFormDatas(all => {
            let allNew = {
                ...all
            }
            allNew[key1] = data1
            return allNew
        })
    }

    const submitAll = () => {
        var formData
        var wordsLibScope
        var resultsByScope = {}
        for (let errorhash of Object.keys(formDatas)) {
            formData = formDatas[errorhash]
            wordsLibScope = formData["scope"]
            delete formData["scope"]

            if (!(wordsLibScope in resultsByScope)) {
                resultsByScope[wordsLibScope] = []
            }
            if (checkValueExistsFunc(formData)) {
                resultsByScope[wordsLibScope].push(formData)
            }
        }
        for (let scope of Object.keys(resultsByScope)) {
            wsProvider.addWordsLib(resultsByScope[scope], scope)
        }

        //SET FILTERS TO THIS VALUE
        wsProvider.setStateVar("command", "wordsLibType", wordsLibType);
        wsProvider.setStateVar("command", "wordsLibScope", wordsLibScope);

        //Set workspace
        const [sidebarTabs1, setSidebarTabs1] = wsProvider.useStateVarGlobal("sidebarTabs1")
        const [sidebarTabs2, setSidebarTabs2] = wsProvider.useStateVarGlobal("sidebarTabs2")
        setSidebarTabs1(1)
        setSidebarTabs2(0)

        Object.keys(wordsLibConfig["filterSchema"]).forEach(identifier => {
            console.log("command", "wordsLib_" + wordsLibType + "_" + identifier, formData[identifier])
            wsProvider.setStateVar("command", "wordsLib_" + wordsLibType + "_" + identifier, formData[identifier])
        })

    }

    return (
        <div className="container wordsLibAddMultiple">
            <div className="row">
                <div className="col-7">
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={submitAll}
                    >
                        Submit All
                    </button>
                    {data.map(x => {
                        const errorHashI = x["commands"][errorName]["errorhash"]
                        return (
                            <>
                                <Navi
                                    errorhash={errorHashI}
                                    key={errorHashI}
                                    count={x["count"]}
                                    setErrorActive={setErrorActive}
                                    formData={formDatas[errorHashI]}
                                    errorDict={x}
                                    resultFunc={resultFunc}
                                />
                            </>
                        )
                    })}
                </div>
                <div className="col-5">
                    <ErrorCommandDetail_addWordsLib
                        formData={formDatas[errorActive]}
                        onChangeHandler={(data1) => updateData(errorActive, data1["formData"])}
                        command={commandActive}
                        commandData={commandActiveData}
                    />
                </div>
            </div>
        </div>
    )
}

const Navi = ({ errorhash, count, setErrorActive, formData, errorDict, resultFunc }) => {
    if (formData === undefined) {
        return null
    }
    return (
        <div className="row">
            <a className="col-7 d-inline-block align-top" onClick={() => setErrorActive(errorhash)} style={{ cursor: "pointer" }}>
                {formData["value"]} ({count})
            </a>
            <span className="col-2 d-inline-block align-top">
                {resultFunc(formData)}
            </span>
            <span className="col-1 d-inline-block align-top">
                {formData["scope"]}
            </span>
            <span className="col-1 d-inline-block align-top">
                {formData["lang"]}
            </span>
            {errorDict["url"] &&
                <span className="col-1 d-inline-block align-top">
                    <a href={errorDict["url"]} target="_blank">
                        Link
                    </a>
                </span>
            }
        </div>
    )
}