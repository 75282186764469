import Tree from 'rc-tree';
import React, { useState } from 'react';
import { StatiRadioSingle, StatiRadioMultiple } from '../../../../Various/ItemsTable/StatiRadio'

export default function TreeSelect({ treeData, expandedNodes, setExpandedNodes, checkedLeafs, setCheckedLeafs, statusValues, stati }) {

    function RenderItem({ inData }) {
        if (inData["isLeaf"] === true) {
            return (
                <RenderLeaf inData={inData} />
            )
        } else {
            return (
                <RenderNode inData={inData} />
            )
        }
    }

    function RenderLeaf({ inData }) {
        const statusObj = statusValues.find(x => x["url"] === inData["row"]["url"])
        const status = statusObj ? statusObj["status"] : null
        const statusHTML = stati.find(x => x["value"] === status)["valueHTML"]
        const statusIcon = stati.find(x => x["value"] === status)["icon"]
        let checked = inData["key"] in checkedLeafs && checkedLeafs[inData["key"]] === true ? true : false

        const checkHandler = (newChecked) => {
            const newCheckedLeafs = { ...checkedLeafs }
            newCheckedLeafs[inData["key"]] = newChecked
            setCheckedLeafs(newCheckedLeafs)
        }

        /*
                            <StatiRadioSingle
                        row={inData["row"]}
                        namePrefix="treeSelect"
                    />
        */
        return (
            <div className={'row leaf status_' + statusHTML}>
                <div className='col-9 colMenu' style={{ paddingLeft: inData["level"] * 20 + "px" }}>
                    <i className={"bi bi-" + statusIcon}></i>

                    {inData["row"]["title"]}<br />
                    <a href={inData["row"]["url"]} target="_blank">{inData["row"]["url"]}</a>
                </div>
                <div className='col-1' style={{ position: "relative" }}>
                    <input
                        type="checkbox"
                        id="scales"
                        name="scales"
                        checked={checked}
                        style={{ position: "absolute", left: inData["level"] * 20 + "px" }}
                        onChange={() => checkHandler(!checked)}
                    />
                </div>
            </div>
        )

    }

    function RenderNode({ inData }) {
        const open = (inData["nodeKey"] in expandedNodes && expandedNodes[inData["nodeKey"]] === true) ? true : false

        const handleOpenToggle = (value, maxLevels) => {

            const results = getFlat(inData, maxLevels)
            const newExpandedNodes = { ...expandedNodes }
            results.forEach(x => {
                newExpandedNodes[x["nodeKey"]] = value
            })
            setExpandedNodes(newExpandedNodes)
        }

        const deepestLevel = getDeepestLevel(inData)

        const stateCheckboxes = []
        for (let i = inData["level"]; i <= deepestLevel; i++) {
            stateCheckboxes.push(i)
        }

        return (
            <>
                <div className='row node'>
                    <div className='col-9 colMenu' style={{ paddingLeft: inData["level"] * 20 + "px" }} onClick={() => handleOpenToggle(!open, 0)}>
                        <div className="item">
                            <i className={"bi bi-folder-" + (!open ? "plus" : "minus")}></i>
                            {inData["title"]}
                        </div>
                    </div>
                    <div className='col-1' style={{ position: "relative" }}>
                        {stateCheckboxes.map(level => {
                            return (
                                <ThreeStateCheckbox
                                    inData={inData}
                                    key={"level_" + level}
                                    level={level}
                                    checkedLeafs={checkedLeafs}
                                    setCheckedLeafs={setCheckedLeafs}
                                />
                            )
                        })
                        }
                    </div>
                    <div className='col-2 openDeep'>
                        <a onClick={() => handleOpenToggle(true, 1)}>+2</a>
                        <a onClick={() => handleOpenToggle(false, 1)}>-2</a>
                        <a onClick={() => handleOpenToggle(true, 2)}>+3</a>
                        <a onClick={() => handleOpenToggle(false, 2)}>-3</a>
                    </div>
                </div>
                {open === true &&
                    <div className={"childrenContainer " + (open ? "open" : "close")}>
                        {"children" in inData &&
                            inData["children"].sort(compareItems).map(item => (
                                <RenderItem
                                    inData={item}
                                    key={"key" in item ? item["key"] : item["nodeKey"]}
                                />
                            ))
                        }
                    </div>
                }
            </>
        )
    }

    return (
        <div className="treeSelect">
            {treeData.sort(compareItems).map(item => (
                <RenderItem
                    inData={item}
                    key={"key" in item ? item["key"] : item["nodeKey"]}
                />
            ))}
        </div>
    )
}
function compareItems(a, b) {
    if (a["isLeaf"] === b["isLeaf"]) {
        return 0
    }
    return a["isLeaf"] === true ? 1 : -1
}

export function getFlat(inData, maxLevels, results) {
    if (results === undefined) {
        results = []
    }
    results.push(inData)
    if (maxLevels > 0 && "children" in inData) {
        inData["children"].forEach(x => getFlat(x, maxLevels - 1, results))
    }
    return results
}

function getDeepestLevel(inData) {
    return getFlat(inData, 1000).map(x => x["level"]).reduce(function (a, b) {
        return Math.max(a, b);
    }, -Infinity);

}



const ThreeStateCheckbox = ({ inData, level, checkedLeafs, setCheckedLeafs }) => {

    const getLeafsBelonging = () => {
        return getFlat(inData, level - inData["level"] + 1).filter(x => x["level"] === level && x["isLeaf"] === true)
    }

    const leafsBelonging = getLeafsBelonging()

    const getValue = () => {

        const checkedPos = leafsBelonging.filter(x => x["key"] in checkedLeafs && checkedLeafs[x["key"]] === true).length
        const checkedNeg = leafsBelonging.filter(x => x["key"] in checkedLeafs && checkedLeafs[x["key"]] === false).length

        if (checkedPos === leafsBelonging.length && leafsBelonging.length > 0)
            return true

        if (checkedNeg === leafsBelonging.length || checkedPos === 0)
            return false

        return null
    }

    const checked = getValue()

    const updateInput = (ref, checked) => {
        const input = ref.current
        if (input) {
            input.checked = checked
            input.indeterminate = checked == null
        }
    }

    const inputRef = React.useRef(null)
    const checkedRef = React.useRef(checked)

    React.useEffect(() => {
        checkedRef.current = checked
        updateInput(inputRef, checked)
    }, [checked])

    const handleClick = () => {

        const newCheckedLeafs = { ...checkedLeafs }
        const newChecked = checkedRef.current === true ? false : true

        leafsBelonging.forEach(x => {
            newCheckedLeafs[x["key"]] = newChecked
        })

        setCheckedLeafs(newCheckedLeafs)
    }
    //if(leafsBelonging.length!==0)
    return (
        <input
            disabled={leafsBelonging.length === 0}
            ref={inputRef}
            type="checkbox"
            onClick={handleClick}
            className={"level_" + level}
            style={{ position: "absolute", left: level * 20 + "px" }}
        />
    )
    return null
}