import TreeSelectStatus from './TreeSelectStatus'
import generateTreeData from './_generateTreeDataFunctions'

export default function TreeSelectBreadcrumb({tableData}) {

    let data = generateTreeData("breadcrumbs", tableData)

    return (
        <TreeSelectStatus
            data={data}
            expandedVar="breadcrumbNodesExpanded"
            checkedVar="breadcrumbNodesChecked"
            tableData={tableData}
        />
    )
}