import TreeSelectStatus from './TreeSelectStatus'
import generateTreeData from './_generateTreeDataFunctions'

export default function TreeSelectUrlSplit({tableData}) {

    let data = generateTreeData("url_split", tableData)


    return (
        <TreeSelectStatus
            data={data}
            expandedVar="urlsplitNodesExpanded"
            checkedVar="urlsplitNodesChecked"
            tableData={tableData}
        />
    )
}