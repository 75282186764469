import { getFlat } from '../../Workspace/Command/CommandExec/filter/TreeSelect'

export const getInputTreeFlat = (props) => {

    const inputTreeFlat = getFlat(props.inputTree, 4).filter(x => "key" in x && props.inputKeyAllow(x.key))
    var res = {}
    inputTreeFlat.forEach(x => {
        res[x["key"]] = x
    })
    return res
}

export const getLev2FromTreeById = (node, id) => {
    const lev0 = node["children"].find(item => item["id"] === id.substring(0, 2))
    if (lev0 === undefined) {
        return getLev2FromTreeById(node, "9998")
    }

    let lev1 = lev0["children"].find(item => item["id"] === id.substring(0, 3))

    if (lev1 === undefined) {
        return getLev2FromTreeById(node, "9998")
    }

    return lev1["children"].find(item => item["id"] === id.substring(0, 4))
}

export const treeFilterValue = (node, terms, level, forceKeepKeys) => {
    forceKeepKeys = forceKeepKeys === undefined ? [] : forceKeepKeys

    //Prepare Terms
    if (typeof terms === 'string' || terms instanceof String) {
        terms = terms.toLowerCase().split(" ").filter(x => x.length > 2)
        if (terms.length === 0)
            return node
    }

    var keep = false
    var containsAllTerms = true

    //Check Terms
    terms.forEach(term => {
        if (!node.title.toLowerCase().includes(term))
            containsAllTerms = false
    })

    if (containsAllTerms) {
        keep = true
    } else if (forceKeepKeys.includes(node.key)) {
        keep = true
    }

    const newChildren = []

    if ("children" in node) {
        node.children.forEach(childNode => {
            const newChildNode = treeFilterValue(childNode, terms, level + 1, forceKeepKeys)
            if (newChildNode) {
                newChildren.push(newChildNode)
            }
        });
    }

    if (newChildren.length > 0 || keep === true || level === 0)
        return {
            ...node,
            "children": newChildren
        }
}

export const sortTreeItemsLev2 = (node) => {
    node["children"].forEach(lev0 => {
        lev0["children"].forEach(lev1 => {
            lev1["children"].forEach(lev2 => {
                if ("children" in lev2) {
                    lev2["children"].sort(((a, b) => a["title"].localeCompare(b["title"])))
                }
            })
        })
    })
    return node
}

export const sortTreeItemsLev3 = (node) => {
    node["children"].forEach(lev0 => {
        lev0["children"].forEach(lev1 => {
            lev1["children"].forEach(lev2 => {
                lev2["children"].forEach(lev3 => {
                    if ("children" in lev3) {
                        lev3["children"].sort(((a, b) => a["title"].localeCompare(b["title"])))
                    }
                })
            })
        })
    })
    return node
}

export const treeFilterNoChilds = (node, maxLevel, level) => {
    const newChildren = []

    if ("children" in node) {
        node.children.forEach(childNode => {
            const newChildNode = treeFilterNoChilds(childNode, maxLevel, level + 1)
            if (newChildNode) {
                newChildren.push(newChildNode)
            }
        });
    }


    if (level === 0 || level > maxLevel || newChildren.length > 0)
        return {
            ...node,
            "children": newChildren
        }
}

export const combiIdToArray = (id) => id.split("combi_")[1].split("__")
export const checkCombiId = id => id.includes("combi_")

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
