import Editor from "@monaco-editor/react";

export default function monacoWidgetXpath(props) {
    const handleEditorChange = (value, event) => {
        props.onChange(value)
    }

    return (
        <div className="monacoXpath">
            <Editor
                height="70px"
                defaultLanguage="python"
                defaultValue={props.value}
                onChange={handleEditorChange}
                theme="vs-dark"
                options={
                    {
                        tabSize: 2
                    }
                }
            />
        </div>
    )
  };