//import {useState} from "react";
//import ItemsTable from '../../../ItemsTable/ItemsTable'

export default function GetLang({ execAll, execAllArray, showAll, execSelected, commandKey, CommandsTree }) {
    if (execAllArray.length > 0 && (execAll[execSelected] || showAll === true))
        return (
            <div className="row">
                <div className="col-12">
                    Not yet implemented
                </div>
            </div>
        )
    return null
}