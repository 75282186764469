import { useState } from "react"
import { ItemsTableDefault } from "../../../../Various/ItemsTable/ItemsTable"

export default function ProfileDetails({ education_data }) {
    const [sortColumns, setSortColumns] = useState([])
    if (!education_data) {
        return null
    }


    const tableDataPrepared = [...education_data]

    tableDataPrepared.forEach(x => {
        if (Array.isArray(x.topic_group_set)) {
            x.topic_group_set = x.topic_group_set.join(" | ")
        }
        if (Array.isArray(x.degrees)) {
            x.degrees = x.degrees.join(" | ")
        }
        if ((x.url)) {
            x.school_id_2 = x.url.split("/")[4]
        }
    })

    return <ItemsTableDefault
        tableData={education_data}
        sortColumns={sortColumns}
        setSortColumns={setSortColumns}
        // hideColumns={["topics_used", "title", "degree"]} // , "field"
        noMemo={true}
        columnsSettings={{
            "degrees": {
                minColumnWidth: 100,
                minWidth: 100,
                width: 100,
            },
            "start_year": {
                minColumnWidth: 80,
                minWidth: 80,
                width: 80,
            },
            "end_year": {
                minColumnWidth: 80,
                minWidth: 80,
                width: 80,
            },
            "school_id": {
                minColumnWidth: 100,
                minWidth: 100,
                width: 100,
            },
            "school_id_2": {
                minColumnWidth: 100,
                minWidth: 100,
                width: 100,
            },
            "title_value_de_CH": {
            },
            "topic_group_set": {
            },
            "url": {
                minColumnWidth: 100,
                minWidth: 100,
                width: 100,
            }
        }}
    />
}