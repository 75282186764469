

export default function FullQualifiedClientId(project, projectPath, clientId) {

    const toString = () => {
        return project + "/" + projectPath + "/" + clientId
    }

    return {
        toString: () => toString(),
    }
}
