import SelectCommandType, { CreatableSingle } from './SelectCommandType'
import { CommandsData } from '../../Workspace/Command/CommandExec/_commands.js';
import ClientProvider from '../../Various/ClientProvider';
import React from 'react';

export default function CommandSettings() {
    const wsProvider = React.useContext(ClientProvider);

    if (wsProvider.CommandsTree)
        return (
            <div className="row">
                <SelectCommandType
                    changeHandler={wsProvider.CommandsTree.changeAttrType}
                    value={wsProvider.CommandsTree.getAttr("className")}
                    options={Object.keys(CommandsData).map((x) => { return { name: x, label: x } })}
                    disabled={(wsProvider.CommandsTree.getAttr("key")) ? "" : "disabled"}
                />
                {wsProvider.CommandsTree.getAttr("className") && CommandsData[wsProvider.CommandsTree.getAttr("className")]["hasItemField"] &&
                    <CreatableSingle
                        changeHandler={wsProvider.CommandsTree.changeAttrTitle}
                        value={wsProvider.CommandsTree.getAttr("title") ? wsProvider.CommandsTree.getAttr("title") : "url"}
                        options={wsProvider.CommandsTree.context.CrawlerTree.getFields(false).map(x => { return { name: x, label: x } })}
                        disabled={(wsProvider.CommandsTree.getAttr("key")) ? "" : "disabled"}
                        creatable={true}
                    />
                }
                <SelectCommandType
                    changeHandler={wsProvider.CommandsTree.changeAttrActive}
                    value={wsProvider.CommandsTree.getAttr("active") === false ? "false" : "true"}
                    options={[{ name: "true", label: "True" }, { name: "false", label: "False" }]}
                    disabled={(wsProvider.CommandsTree.getAttr("key")) ? "" : "disabled"}
                />
            </div>
        )
    return null
}