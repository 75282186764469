import { useContext, useState } from "react"
import QueryContext from "../../Base/Context"
import ResultTabPanelItemsTable from "../../Base/Result/TabPanels/ItemsTable"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ResultTabPanelJson from "../../Base/Result/TabPanels/Json"
import RestaurantDetails from "./RestaurantDetails"
import DishDetails from "./DishDetails";

export default function QueryRInderDishesSelectorResult() {
    const { response } = useContext(QueryContext)
    const [dishActive, setDishActive] = useState()
    const [restaurantActive, setRestaurantActive] = useState()

    const clickHandler = (item, column, index, itemOrig) => {


        if (column === "restaurant_name") {
            setRestaurantActive(itemOrig.restaurant)
            setDishActive(null)
            return
        } else {
            setRestaurantActive(null)
            setDishActive(itemOrig)
        }
    }
    const transform = (response) => {
        return response.hits.hits.map(hit => {
            if (Array.isArray(hit._source.food_types)) {
                hit._source.food_types = hit._source.food_types.join(", ")
            }
            if (Array.isArray(hit._source.cuisines)) {
                hit._source.cuisines = hit._source.cuisines.join(", ")
            }
            if (Array.isArray(hit._source.allergens)) {
                hit._source.allergens = hit._source.allergens.join(", ")
            }
            if (Array.isArray(hit._source.dietary_preferences)) {
                hit._source.dietary_preferences = hit._source.dietary_preferences.join(", ")
            }
            hit._source.restaurant_name = hit._source.restaurant.name
            return hit._source
        })
    }

    return <Tabs>
        <TabList>
            {response &&
                <Tab>Hits ({response.hits.total.value})</Tab>
            }
            <Tab>JSON</Tab>
        </TabList>
        {response &&
            <TabPanel>
                <ResultTabPanelItemsTable
                    fn_dataTransform={transform}
                    height={"calc(100vh - 570px)"}
                    clickHandler={clickHandler}
                    hideColumns={["source", "hash", "restaurant", "restaurant_hash", "course_key", "index_hash"]}
                    columnsSettings={{
                        "name": {
                            minColumnWidth: 150,
                            minWidth: 150,
                            width: 150,
                        },
                        "restaurant_name": {
                            minColumnWidth: 150,
                            minWidth: 150,
                            width: 150,
                        },
                        "section": {
                            minColumnWidth: 80,
                            minWidth: 80,
                            width: 80,
                        },
                        "course": {
                            minColumnWidth: 80,
                            minWidth: 80,
                            width: 80,
                        },

                        // "education": {
                        //     minColumnWidth: 100,
                        //     minWidth: 100,
                        //     width: 100,
                        // },
                        // "position": {},
                        // "url": {
                        //     minColumnWidth: 100,
                        //     minWidth: 100,
                        //     width: 100,
                        // }
                    }}
                />
                <div style={{ height: "280px", marginTop: "20px" }}>
                    {restaurantActive &&
                        <RestaurantDetails data={[restaurantActive]} />
                    }
                    {dishActive &&
                        <DishDetails data={[dishActive]} />
                    }
                </div>
            </TabPanel>
        }
        <TabPanel>
            <ResultTabPanelJson />
        </TabPanel>
    </Tabs>
}