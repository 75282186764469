const campaignSchema = {
    type: "object",
    description: "Campaign",
    title: "Campaign",
    properties: {
        client_id: {
            type: "string",
            title: "Client ID"
        },
        campaign_name: {
            type: "string",
            title: "Campaign Name"
        },
        campaign_start_date: {
            type: "string",
            title: "Start Date",
            description: "Date when the campaign starts.",
        },
        campaign_end_date: {
            type: "string",
            title: "End Date",
            description: "Date when the campaign ends."
        },
        campaign_budget: {
            type: "object",
            title: "Budget",
            description: "Budget definition for the campaign.",
            properties: {
                amount: {
                    type: "integer",
                    title: "Amount",
                    description: "Maximum amount of money to spend on the campaign in the specified period."
                },
                periodicity: {
                    type: "integer",
                    title: "Periodicity",
                    description: "Periodicity of the budget."
                },
                periodicity_unit: {
                    type: "string",
                    title: "Periodicity Unit",
                    description: "Periodicity unit of the budget.",
                    enum: [ "days", "months"]
                },
                allocation_strategy: {
                    type: "string",
                    title: "Budget Allocation Strategy",
                    description: "How to spread the budget over the specified period.",
                    enum: [ "full_blast", "pro_rated"],
                    default: "pro_rated"
                }
            },
            required: [
                "amount",
            ]
        },
        campaign_display_groups: {
            type: "array",
            title: "Display Groups",
            description: "Display groups for the campaign.",
            items: {
                type: "object",
                title: "Display Group",
                description: "Display group for the campaign.",
                properties: {
                    display_group_name: {
                        type: "string",
                        title: "Name",
                        description: "Name of the display group."
                    },
                    price: {
                        type: "object",
                        title: "Price",
                        oneOf: [
                            {
                                type: "object",
                                title: "Fixed CPC",
                                description: "Fixed cpc for all items.",
                                properties: {
                                    cpc_amount: {
                                        type: "integer",
                                        title: "CPC Amount",
                                    }
                                },
                                required: [
                                    "cpc_amount"
                                ]
                            },
                            {
                                type: "object",
                                title: "Percentage CPC",
                                description: "Calculates the cpc as a percentage of the item price.",
                                properties: {
                                    cpc_percentage: {
                                        description: "multiplication factor from 0 to 1",
                                        type: "number",
                                        title: "CPC Percentage",
                                    },
                                    cpc_percentage_base_field: {
                                        type: "string",
                                        title: "Field",
                                        description: "Field to use for the price calculation.",
                                        enum: ['price_from', 'price_to']
                                    },
                                    fallback_cpc_amount: {
                                        type: "integer",
                                        title: "Fallback Fixed CPC Amount",
                                        description: "fallback fixed cpc for all items.",
                                    }
                                },
                                required: [
                                    "cpc_percentage",
                                    "cpc_percentage_base_field",
                                    "fallback_cpc_amount"
                                ]
                            }
                        ]
                    },
                    filter: {
                        type: "string",
                        title: "Filter",
                        description: "ElasticSearch filter query to match items.",
                        default: '{\n    "query": {\n        "match_all": {}\n    }\n}'
                    },
                },
                required: [
                    "display_group_name",
                    "price",
                ]
            }
        }
    },
    required: [
        "campaign_name",
        "client_id",
        "campaign_start_date",
        "campaign_budget",
        "campaign_display_groups"
    ],
    dependencies: {}
}

export default campaignSchema;