import Iframe from './load/IFrame/Iframe'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default function Loop({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const renderSingle = (execItem, index) => (
        <Tabs className="LoopCommand">
            <TabList>
                <Tab>Output</Tab>
                <Tab>Input</Tab>
            </TabList>
            <TabPanel>
                {execItem["out"].slice(0, execItem["out"].length < 20 ? execItem["out"].length : 20).map((x, index2) => {
                    return (
                        <Iframe
                            content={x}
                            title={commandKey + "_out_" + index + "_" + index2}
                        />
                    )
                })}
            </TabPanel>
            <TabPanel>
                <Iframe
                    content={execItem["in"]}
                    title={commandKey + "_in_" + index}
                />
            </TabPanel>
        </Tabs>
    )
    if (showAll !== true) {
        return renderSingle(execAll[execSelected], execSelected)
    } else {
        return execAllArray.map((execItem, index) =>
            renderSingle(execItem, index)
        );
    }
}