import { useEffect, useState } from 'react'

export default function MouseOverInfo ({getIterator}) {
    const [nodeOver, setNodeOver] = useState(null);

    //Mouseover-Effect to display info
    useEffect(() => {
        var iterator = getIterator()
        if(iterator) {
          var thisHeading = iterator.iterateNext()
          while (thisHeading) {
            thisHeading.addEventListener("mouseover", handleMouseOver)
            thisHeading = iterator.iterateNext()
          }
          
          return () => {
            var iterator = getIterator()
            if(iterator) {
              var thisHeading = iterator.iterateNext()
              while (thisHeading) {
                thisHeading.removeEventListener("mouseover", handleMouseOver)
                thisHeading = iterator.iterateNext()
              }
            }
          }
        }
      });

    const handleMouseOver = function (event) {
        setNodeOver(this)
        event.preventDefault();
        event.stopPropagation();
    }
    
    if(nodeOver)
        return (
            <>
                {nodeOver.tagName} (@{nodeOver.getAttribute("class")})<br/>
            </>
        )
    else
        return null
}


