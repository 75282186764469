import React, { useState, useRef, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';
import Form from "@rjsf/core";
import { ItemsTableDefault, ItemsTableOther } from '../../Various/ItemsTable/ItemsTable'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactJson from 'react-json-view'


export default function WorkspacePerformance() {
  const wsProvider = React.useContext(ClientProvider);
  const [performJobsOverview, setPerformJobsOverview] = useState({ "performJobs": [] })
  const [performJobDetails, setPerformJobDetails] = useState([])
  const [sortColumns, setSortColumns] = useState([])
  const [filterColumns, setFilterColumns] = useState({})

  const [schemaDefinition, setSchemaDefinition] = useState(
    {
      schema: {
        type: 'object',
        properties: {
          performJobId: {
            enum: [],
            title: 'performJobId',
            type: 'string'
          }
        },
        required: ["performJobId"]
      },
      uiSchema: {
        'ui:order': [
          'performJobId'
        ]
      }
    })

  const [filterFormData, setFilterFormData] = wsProvider.useStateVarGlobal("filterFormDataPerform", {
    performJobId: ''
  })

  useEffect(() => {
    loadPerformJobsOverview()
  }, []);

  useEffect(() => {
    loadPerformJobDetails()
  }, [filterFormData.performJobId])

  useEffect(() => {
    if (Object.keys(performJobsOverview).length > 0) {
      const schemaNew = {
        ...schemaDefinition,
        schema: {
          ...schemaDefinition.schema,
          properties: {
            ...schemaDefinition.schema.properties,
            performJobId: {
              ...schemaDefinition.schema.properties.performJobId,
              enum: performJobsOverview.performJobs.map(x => x["performJobId"]),
              //enumNames: (filterFormData.performJobName && performJobsOverview["performJobs"][filterFormData.performJobName]) ? performJobsOverview["performJobs"][filterFormData.performJobName].map(x => { let date = new Date(); date.setTime(x["performJobId"]); return date.toLocaleString() }) : [],
            }
          }
        }
      }
      setSchemaDefinition(schemaNew)
    }
  }, [performJobsOverview]);


  const refreshPerformJobsOverview = () => {
    loadPerformJobsOverview()
  }

  const loadPerformJobsOverview = () => {
    wsProvider.ws.waitForSocketAndSend({
      action: "getPerformJobsOverview",
      options: {}
    }, (response) => {
      // console.log(response)
      setPerformJobsOverview(response)
    })
  }

  const loadPerformJobDetails = () => {
    if (!filterFormData.performJobId)
      return

    wsProvider.ws.waitForSocketAndSend({
      action: "getPerformJobDetails",
      options: {
        performJobId: filterFormData.performJobId,
      }
    }, (response) => {
      console.log(response)
      setPerformJobDetails(response)
    })
  }

  const deleteJob = () => {
    wsProvider.ws.waitForSocketAndSend({
      action: "deletePerformJob",
      options: {
        performJobId: filterFormData.performJobId,
      }
    }, (response) => {
      refreshPerformJobsOverview()
      setFilterFormData(filterFormData => {
        const filterFormDataNew = {
          ...filterFormData
        }
        delete filterFormDataNew.performJobId
        return filterFormDataNew
      })
    })
  }

  const deleteJobAll = () => {
    wsProvider.ws.waitForSocketAndSend({
      action: "deleteAllPerformJob",
      options: {
      }
    }, (response) => {
      refreshPerformJobsOverview()
      setFilterFormData({
        "performJobId": ""
      })
    })
  }

  const filterChangeHandler = (data) => {
    setFilterFormData(data.formData)
  }

  return (
    <div className="row">
      <div className="col-12 workspaceTop">
        <div className='flex_header'>
          <div className='header'>
            <div className='row'>
              <div className='col-6'>
                <Form
                  className="Form_PerformJobOverview"
                  schema={schemaDefinition["schema"]}
                  uiSchema={schemaDefinition["uiSchema"]}
                  formData={filterFormData}
                  onChange={filterChangeHandler}
                />
              </div>
              <div className='col-6'>
                <button
                  onClick={refreshPerformJobsOverview}
                  className="btn btn-primary btn-sm refreshClients"
                >
                  <i className="bi bi-arrow-clockwise"> Overview</i>
                </button>
                {filterFormData.performJobId &&
                  <button
                    onClick={deleteJob}
                    className="btn btn-primary btn-sm refreshClients"
                  >
                    <i className="bi bi-trash"> Delete</i>
                  </button>
                }
                <button
                  onClick={deleteJobAll}
                  className="btn btn-primary btn-sm refreshClients"
                >
                  <i className="bi bi-trash"> Delete All</i>
                </button>
              </div>
            </div>
          </div>
          <div className='content'>
            {filterFormData.performJobId &&
              <ItemsTableDefault
                tableData={performJobDetails}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                key={filterFormData.performJobId}
                extraClass="performJobOverviewItem"
                filterColumns={filterColumns}
                setFilterColumns={setFilterColumns}
              />}
          </div>
        </div>
      </div>
    </div >
  )
}