import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import React, { useState } from 'react';
import Tree from 'rc-tree'
import ClientProvider from './ClientProvider';
import ClientSelectSimple from './Select/ClientSelectSimple'
import ProjectPathSelect from './Select/ProjectPathSelect'

export default function CommandPicker() {
  const wsProvider = React.useContext(ClientProvider);

  const [show, setShow] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedKeysCommands, setSelectedKeysCommands] = useState([]);

  const [client, setClient] = useState()
  const [projectPath, setprojectPath] = useState("test")
  const [crawler, setCrawler] = useState()

  const [clientConfig, setClientConfig] = useState([]);

  const handleShow = () => setShow(true)
  const handleHide = () => setShow(false)

  const handleSave = () => {

    setShow(false);

    let insertKeys = [...selectedKeysCommands]
    if (insertKeys.length === 0)
      return false

    let crawlerConfig = { ...clientConfig[0]["children"].find(element => element.key === selectedKeys[0]) }
    let commandConfigFound = wsProvider.CommandsTree.findCommandRecursiveByKey(crawlerConfig["commands"], insertKeys[0])
    let commandConfigNewKeys = wsProvider.CommandsTree.replaceKeysRecursiv({ ...commandConfigFound })

    wsProvider.CommandsTree.addCommands(commandConfigNewKeys, true)

  }

  const changeClientHandler = (value) => {
    setClient(value)
    wsProvider.ws.waitForSocketAndSend({
      action: "getClientConfigToImport",
      options: { client: value }
    }, (response) => setClientConfig(response)
    )
  }

  return (
    <>
      <button
        onClick={handleShow}
        className="btn btn-primary btn-sm"
        disabled={wsProvider.getCrawlerSelected() ? "" : "disabled"}

      >
        <i className="bi bi-aspect-ratio"></i>
      </button>
      <Modal show={show} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Insert Commands</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectPathSelect projectPath={projectPath} setprojectPath={setprojectPath} />
          <ClientSelectSimple client={client} setClient={changeClientHandler} projectPath={projectPath} />
          {clientConfig.length > 0 &&
            <Tree
              treeData={clientConfig[0]["children"]}
              selectedKeys={selectedKeys}
              onSelect={setSelectedKeys}
            />
          }
          {selectedKeys.length > 0 &&
            <>
              <hr />
              <Tree
                treeData={clientConfig[0]["children"].find(element => element.key === selectedKeys[0])["commands"]}
                selectedKeys={selectedKeysCommands}
                onSelect={setSelectedKeysCommands}
              />
            </>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
