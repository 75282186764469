import { useContext } from "react"
import QueryContext from "../../Base/Context"
import ResultTabPanelItemsTable from "../../Base/Result/TabPanels/ItemsTable"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ResultTabPanelJson from "../../Base/Result/TabPanels/Json";

export default function QueryRawResult() {
    const { response } = useContext(QueryContext)

    return <Tabs>
        <TabList>
            {response &&
                <Tab>Hits ({response.hits.total.value})</Tab>
            }
            <Tab>JSON</Tab>
        </TabList>
        {response &&
            <TabPanel>
                <ResultTabPanelItemsTable fn_dataTransform={(response) => response.hits.hits.map(hit => hit._source)} />
            </TabPanel>
        }
        <TabPanel>
            <ResultTabPanelJson />
        </TabPanel>
    </Tabs>
}