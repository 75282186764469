import { useContext } from "react";
import ConfigWrapper from "../../Base/Config/ConfigWrapper";
import ConfigForm from "../../Base/Config/ConfigForm";
import IndexSelector from "../../Base/Config/IndexSelector";
import { propertiesProvince, propertiesSize, propertiesSource } from "../../Base/Config/formDataConstants";
import RunButtonQuery from "../../Base/Config/RunButtonQuery";
import QueryContext from "../../Base/Context";

export default function QueryLinkedinProfilesSelectorConfig() {
    const { formData } = useContext(QueryContext)

    const generate_query = () => {
        const query = {
            "query": {
                "bool": {
                    "must": []
                }
            },
            "source": formData.source,
            "size": formData.size
        }

        if (formData.nested_query) {
            query.query.bool.must.push({
                "nested": {
                    "path": "education",
                    "query": JSON.parse(formData.nested_query)
                }
            })
        }

        if (formData.province?.length) {
            query.query.bool.must.push({
                "terms": {
                    "province": formData.province
                }
            })
        }

        return query
    }

    return <ConfigWrapper
        defaultIndexName="linkedin_profiles"
        hasQuery={true}
    >
        <IndexSelector isDisabled={true} />
        <ConfigForm
            schema={{
                "type": "object",
                "properties": {
                    "province": propertiesProvince,
                    "source": propertiesSource,
                    "nested_query": {
                        "type": "string",
                    },
                    "size": propertiesSize,
                }
            }}
            uiSchema={{
                "source": {
                    "ui:widget": "checkboxes"
                },
                "nested_query": {
                    "ui:widget": "monacojson",
                    "height": "500px"
                },
                "province": {
                    "ui:widget": "checkboxes"
                }
            }}
            defaultFormData={() => ({
                province: [],
                size: 100,
                source: [
                    "name",
                    "position",
                    "url",
                    "education",
                    "province"
                ],
                nested_query: JSON.stringify({
                    "bool": {
                        "must": [
                            {
                                "bool": {
                                    "should": [

                                        {
                                            "prefix": {
                                                "education.url.keyword": {
                                                    "value": "https://ch.linkedin.com/school/coachingzentrum-olten"
                                                }
                                            }
                                        },
                                        {
                                            "prefix": {
                                                "education.school_id": {
                                                    "value": "CHE-czo"
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }, null, 2)
            })}
            className="rjsf_QueryLinkedinProfilesSelectorConfig"
            generate_query={generate_query}
        />
        <RunButtonQuery />
    </ConfigWrapper>
}

/*
ZHAW

{
    "bool": {
        "should": [
            {
                "prefix": {
                    "education.url.keyword": {
                        "value": "https://ch.linkedin.com/school/zhaw"
                    }
                }
            },
            {
                "prefix": {
                    "education.school_id": {
                        "value": "CHE-zhaw"
                    }
                }
            }
        ]
    }
},

/*  
{
    "term": {
        "education.degrees": "BA"
    }
},

{
"range": {
    "education.end_year": {
        "gte": "2024"
    }
}
}

{
"prefix": {
    "education.topic_group_set": {
        "value": "07###072###0722###Systementwicklung"
    }
}
},

*/