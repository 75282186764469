import React, { useEffect, useRef } from 'react'
import ClientProvider from '../../Various/ClientProvider';
import CompWrapper from '../CompWrapper'

export default function Jobs({ jobs }) {
    const wsProvider = React.useContext(ClientProvider);

    const killJob = (jobId) => {
        wsProvider.ws.waitForSocketAndSend({
            signal: "killJob",
            options: {
                killJobId: jobId
            }
        }, (response) => {
            console.log("Sent kill signal: " + jobId)
        })
    }
    const removeJob = (jobId) => {
        wsProvider.setJobs((jobs) => {
            const jobsNew = { ...jobs }
            delete jobsNew[jobId]
            return jobsNew
        })
    }


    const refreshJobs = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "getJobs",
            options: {}
        }, (response) => {
            wsProvider.setJobs(response)
        })
    }

    return (
        <CompWrapper
            className="jobs"
            title="Jobs"
            buttonBar={(
                <button
                    onClick={refreshJobs}
                    className="btn btn-primary btn-sm"
                >
                    <i className="bi bi-arrow-repeat"></i>
                </button>
            )}
        >
            <div className="row mb-1">
                {Object.keys(jobs).map(jobId => {
                    return (
                        <div key={jobId}>
                            <span onClick={() => { removeJob(jobId) }} style={{ "cursor": "pointer" }}>
                                [X]&nbsp;
                            </span>
                            <span className="col-4 d-inline-block align-top">
                                {jobs[jobId]["action"]}
                            </span>
                            <span className="col-4 d-inline-block align-top">
                                {jobs[jobId]["jobId"].substring(0, 8)}...
                            </span>
                            {jobs[jobId]["action"] !== "killJob" &&
                                <span className="col-1 d-inline-block align-top" onClick={() => { killJob(jobId) }} style={{ "cursor": "pointer" }}>
                                    STOP
                                </span>
                            }
                        </div>
                    )
                })}
            </div>
        </CompWrapper>
    );
} 