import { makeid } from '../components/SideBarRight/CommandsTree/CommandsTree'
import envs from '../envs'

export default class WS extends WebSocket {
  onerror = (event) => {
    //  console.log(event)
    console.log('Websocket ERROR Send')
  };

  onopen = () => {
    console.log('Opened Connection Send!')
    this.reconnectCounter = 0
    if (this.wsProvider && this.wsProvider.setConnectedStatus) {
      this.wsProvider.setJobs([])
      this.wsProvider.setConnectedStatus(true)
    }
  };

  waitForSocketAndSend(content, callback) {
    const jobId = makeid()
    //console.log("waitForSocketAndSend: " + content["action"])

    if ("action" in content) {
      content["jobId"] = jobId

      this.wsProvider.setJobs(jobs => {
        let res = {
          ...jobs,
        }
        res[jobId] = {
          jobId: jobId,
          action: content["action"]
        }
        return res
      })
    }

    if (content["action"]) {
      console.log("START JOB: " + content["action"] + " " + jobId)
    } else {
      console.log("START NON-ACTION: " + Object.keys(content).join(","))
    }

    //console.log(content)
    var self = this
    content["websocketClientId"] = this.wsProvider.websocketClientId
    //console.log(content)
    var this2 = this

    waitForSocketConnection(this, function () {
      if (callback) {
        this2.wsProvider.setCallbacks(x => {
          const newCallbacks = { ...x }
          newCallbacks[jobId] = callback
          return newCallbacks
        })
      }
      self.send(JSON.stringify(content));
    })
  }

  reconnectCounter = 0

  reconnectIn = () => {
    if (this.reconnectCounter < 10) {
      return 500
    }

    if (this.reconnectCounter > 100) {
      return 100000000
    }
    return 5000
  }

  onclose = (event) => {
    console.log('Closed Connection Send!')
    var wsProvider = this.wsProvider
    if (wsProvider) {
      if (wsProvider.setConnectedStatus) {
        wsProvider.setConnectedStatus(false)
      }
      wsProvider.toLog("Closed Connection Send", true)
      var reconnectCounter = this.reconnectCounter
      setTimeout(function () {
        console.log('Trying to reconnect (' + envs.send_port + ')...', reconnectCounter)
        var newWS = new WS(envs.websocket_protocol + "://" + envs.host + ":" + envs.send_port + "/?websocketClientId=" + wsProvider.websocketClientId + '&authToken=' + wsProvider.authToken)
        newWS.wsProvider = wsProvider
        newWS.reconnectCounter = reconnectCounter + 1
        wsProvider.setWs(newWS);
      }, this.reconnectIn());
    }
  };
}

// Make the function wait until the connection is made...
function waitForSocketConnection(socket, callback) {
  setTimeout(
    function () {
      if (socket.readyState === 1) {
        if (callback != null) {
          callback();
        }
      } else if (socket.readyState === 0) {
        console.log("wait for connection...")
        waitForSocketConnection(socket, callback);
      } else if (socket.readyState === 3) {
        console.log("connection already closed...")
      }

    }, 5); // wait 5 milisecond for the connection...
}