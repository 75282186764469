import Tree from 'rc-tree';
import React from 'react';
import { sortPointDelimited } from '../../Various/ItemsTable/ItemsTable_core'

function ResultsTree(props) {
    const setExecSelected = props.setExecSelected

    const onSelect = (selectedKeys, e) => {
        setExecSelected(e.node.key)
    };

    const getTreeData = (maxAmount, execAll, execAllArray) => {
        if (execAll === false)
            return []

        var childs = []
        var i = 0

        execAllArray.slice(0, maxAmount).forEach(x => {
            childs.push({
                title: x["#exec"],
                key: x["#exec"]
            })
            i++
        })
        childs.sort((a, b) => sortPointDelimited(a["key"], b["key"]))
        return childs
    }

    let treeData = getTreeData(props.showHideTree ? 100000 : 10, props.execAll, props.execAllArray)


    return (
        <Tree
            treeData={treeData}
            onSelect={onSelect}
            showHideTree={props.showHideTree}
        />
    )
}
function unequalTwoValues(val1, val2) {
    return JSON.stringify(val1) !== JSON.stringify(val2)
}
function areEqual(prevProps, nextProps) {

    if (unequalTwoValues(prevProps["time"], nextProps["time"])) {
        //console.log("time")
        return false
    }
    if (unequalTwoValues(prevProps["showHideTree"], nextProps["showHideTree"])) {
        //console.log("showHideTree")
        return false
    }
    if (unequalTwoValues(prevProps["execSelected"], nextProps["execSelected"])) {
        //console.log("execSelected")
        return false
    }
    if (unequalTwoValues(prevProps["execAll"].length, nextProps["execAll"].length)) {
        //console.log("execAll")
        return false
    }
    //console.log("equal")
    return true
}

//JUST FOR TESTING
//export default ResultsTree
export default React.memo(ResultsTree, areEqual);