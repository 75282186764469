import React, { useState } from 'react';
import ClientProvider from '../../Various/ClientProvider';
//import ContextTreeNode from 'rc-tree/lib/TreeNode';
import { CommandsData } from './CommandExec/_commands.js';
import WorkspaceCommandBottom from './WorkspaceCommandBottom'
import ResultsTree from './ResultsTree';
import { BaseDropdown } from './CommandExec/textProcessor/BaseComponents'
export default function WorkspaceCommand() {
    const getExecs = () => {
        const commandStats = wsProvider.CommandsTree.getCommandStats(true)
        if (commandStats === null)
            return null
        if (["execs"] in commandStats) {
            return commandStats["execs"]
        }
        return null
    }

    const wsProvider = React.useContext(ClientProvider);
    const [showHideTree, setShowHideTree] = wsProvider.useStateVar("crawler", "showHideTree", false)

    const [showAll, setShowAll] = wsProvider.useStateVar(wsProvider.CommandsTree.getShowAllScope(), "showAll", wsProvider.CommandsTree.getShowAllDefault())
    const [groupByField, setGroupByField] = wsProvider.useStateVar("command", "groupByField", true)

    const execAll = getExecs()
    if (execAll === null) {
        console.log("NO EXECS (YET?)")
        return null
    }
    const execAllArray = Object.values(execAll)
    const defaultExecSelected = execAllArray.length ? execAllArray[0]["#exec"] : null
    const [execSelected, setExecSelected] = wsProvider.useStateVar("crawler", "execSelected", defaultExecSelected);
    var useExecSelected = execSelected

    if (execAll[execSelected] === undefined) {
        //Go and find the right execSelected if nothings there [anymore probably because of a change]
        var [sourceSelected, setSourceSelected] = wsProvider.useStateVar("crawler", "sourceSelected", {})
        const foundExecId = Object.keys(execAll).find(execId => execId.includes(sourceSelected["#"]))
        if (foundExecId) {
            useExecSelected = foundExecId
        }
    }
    if ((useExecSelected === undefined || execAll[useExecSelected] === undefined) && execAllArray.length) {
        useExecSelected = execAllArray[0]["#exec"]
    }

    const command = wsProvider.CommandsTree.getAttr("className")
    const CommandExecComponent = CommandsData[command]["comp"];

    return (
        <>
            <div className="col-1 workspaceLeft">
                {command === "textProcessor" &&
                    <TextProcessorFilterErrorInfoNoExt />
                }
                <ShowHide
                    value={groupByField}
                    setValue={setGroupByField}
                    label={"Group Results"}
                    key_id={"showhide_groupResults"}
                />
                <ShowHide
                    value={showAll}
                    setValue={setShowAll}
                    label={"Show all Results"}
                    key_id={"showhide_allResults"}

                />
                <ShowHide
                    value={showHideTree}
                    setValue={setShowHideTree}
                    label={"Show all Navi"}
                    key_id={"showhide_tree"}

                />
                {
                    <ResultsTree
                        showHideTree={showHideTree}
                        time={wsProvider.CommandsTree.getStatsTime()}
                        execSelected={useExecSelected}
                        setExecSelected={setExecSelected}
                        execAll={execAll}
                        groupByField={groupByField}
                        execAllArray={execAllArray}
                        key={wsProvider.CommandsTree.getAttr("key")}
                    />
                }
            </div>
            <div className="col-11 workspaceRight">
                {(execAllArray.length > 0 && (showAll === true || execAll[useExecSelected])) &&
                    (
                        <CommandExecComponent
                            execAll={execAll}
                            execAllArray={execAllArray}
                            showAll={showAll}
                            groupByField={groupByField}
                            execSelected={useExecSelected}
                            commandKey={wsProvider.CommandsTree.getAttr("key")}
                            CommandsTree={wsProvider.CommandsTree}

                        />
                    )
                }
            </div>
        </>
    )
}

function ShowHide({ value, setValue, label, key_id }) {
    const changeHandler = (e) => {
        setValue(!value)
    }
    const id = "flexCheckDefault" + key_id
    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                id={id}
                onChange={changeHandler}
                checked={value}
            />&nbsp;
            <label className="form-check-label" htmlFor={id}>
                {label}
            </label>
        </div>
    )
}


function TextProcessorFilterErrorInfoNoExt() {
    const wsProvider = React.useContext(ClientProvider);
    const [status, setStatus] = wsProvider.useStateVar("command", "textProcessorFilterErrorInfoNoExt", null)

    const stati = [
        {
            label: "All",
            value: null
        },
        {
            label: "NoExt",
            value: "noExt"
        },
        {
            label: "Errors",
            value: "errors"
        },
        {
            label: "Infos",
            value: "infos"
        }
    ]

    return <BaseDropdown
        stati={stati}
        status={status}
        setStatus={setStatus}
        className={"filterErrorInfoNoExt"}
    />

}