import React from 'react';
import TreeSelect from './TreeSelect'
import ClientProvider from '../../../../Various/ClientProvider';
import { stati } from '../../../../Various/ItemsTable/StatiRadio'
import { ItemsTableDefault } from '../../../../Various/ItemsTable/ItemsTable'

export default function TreeSelectStatus({ data, expandedVar, checkedVar, tableData }) {
    const wsProvider = React.useContext(ClientProvider);

    const [expanded, setExpanded] = wsProvider.useStateVar("command", expandedVar, {})
    const [checkedLeafs, setCheckedLeafs] = wsProvider.useStateVar("command", checkedVar, {})

    const urlsStatus = wsProvider.CommandsTree.getSingleSetting("urlsStatus", [])
    const [sortColumns, setSortColumns] = wsProvider.useStateVar("command", "sortColumns" + expandedVar, [])
    const [filterColumns, setFilterColumns] = wsProvider.useStateVar("command", "filterColumns" + expandedVar, {})

    console.log(checkedLeafs)

    return (
        <div className="row">
            <div className="col-6">
                <TreeSelect
                    treeData={data}
                    expandedNodes={expanded}
                    setExpandedNodes={setExpanded}
                    checkedLeafs={checkedLeafs}
                    setCheckedLeafs={setCheckedLeafs}
                    statusValues={urlsStatus}
                    stati={stati}
                />
            </div>
            <div className="col-6">
                <ItemsTableDefault
                    tableData={tableData.filter(x => x["#"] in checkedLeafs && checkedLeafs[x["#"]] === true)}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    mergeCustomFields={true}
                    useRowKeyGetter={(x) => x["#"]}
                    urlsStatus={urlsStatus}
                    hideColumns={["#source", "url_linkTag", "url_linkTagLowest", "url_source", "url_redirected", "body", "breadcrumbs", "subtitles", "fields", "fields_details", "#exec", "#"]}
                    filterColumns={filterColumns}
                    setFilterColumns={setFilterColumns}
                />
            </div>
        </div>
    )
}