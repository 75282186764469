import { SelectCellFormatter, useRowSelection } from 'react-data-grid';
import {StatiRadioSingle, StatiRadioMultiple} from './StatiRadio'
 
/*
function SelectGroupFormatter(props) {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <SelectCellFormatter
      aria-label="Select Group"
      isCellSelected={props.isCellSelected}
      value={isRowSelected}
      onChange={(checked) => {
        onRowSelectionChange({ row: props.row, checked, isShiftClick: false });
      }}
    />
  );
}
*/

export function getSelectColumn(tableData) {
    return {
        key: "select-row",
        name: '',
        width: 70,
        minWidth: 70,
        maxWidth: 70,
        resizable: false,
        sortable: false,
        frozen: true,
        headerRenderer: (props) => <StatiRadioMultiple tableData={tableData} id="header" namePrefix="dataGrid"/>,
        formatter: (props)=><StatiRadioSingle namePrefix="dataGrid" row={props.row} />,
        /*
        groupFormatter: SelectGroupFormatter
        */
      }
}