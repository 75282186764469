
import { useState } from 'react';
import XpathSelectAttr from './XpathSelectAttr'

export default function XpathStatus ({xpath, iframeRef, isFullPage, insertCommand, xpathAttr, setXpathAttr}) {

    const getXpathIterator = () => {
        try {
            var obj = (iframeRef.current.childNodes[0].contentDocument)
            
            let useXpath = xpath.startsWith(".") ? (isFullPage ? "//body": "//body/*") + xpath.substring(1): xpath

            var xpathIterator = document.evaluate(
                useXpath
                , obj, null, XPathResult.ANY_TYPE, null
            );
            return xpathIterator
        } catch (e) {
            console.error('Provided XPath expression is not valid', e)
            return false
        }
    }
    if(!iframeRef.current)
        return (
            <>
            Iframe not ready!
            </>
        )
    let iterator = getXpathIterator()
    if(!iterator) {
        return (
            <>
            Xpath not valid!
            </>
        )
    }
    let results = []
    var currentElement = iterator && iterator.iterateNext()
    while (currentElement ) {
        results.push(currentElement)
        currentElement = iterator && iterator.iterateNext()
    }
    
    return (
        <>
        Results ({results.length}):
        <XpathSelectAttr xpathAttr={xpathAttr} setXpathAttr={setXpathAttr} insertCommand={insertCommand}/>
        <ul>
            {results.map((element) =>
                <li>
                    {element[xpathAttr !== "default" ? xpathAttr : "innerText"]}
                </li>
            )}
        </ul>
        </>
    );
    
   return ''
}