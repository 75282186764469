import { useContext } from "react"
import QueryContext from "../../Base/Context"
import ResultTabPanelItemsTable from "../../Base/Result/TabPanels/ItemsTable"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ResultTabPanelJson from "../../Base/Result/TabPanels/Json";

export default function QueryCoursesByTopicGroupResult() {
    const { response } = useContext(QueryContext)

    const fn_dataTransform = (response) => {
        console.log(response)
        const prepared = (response.aggregations.agg_filter.topicDegreeCombs.buckets.map(x => ({
            topic_degree_comb: x.key_as_string,
            hits: x.hits_agg.hits.hits.map(y => ({
                title: y._source.title_value_de_CH,
                topic_degree_comb: x.key_as_string
            }))
        })))

        const results = []
        prepared.forEach(x => x.hits.forEach(y => results.push(y)))
        return results
    }

    return <Tabs>
        <TabList>
            {response &&
                <Tab>Hits ({response.hits.total.value})</Tab>
            }
            <Tab>JSON</Tab>
        </TabList>
        {response &&
            <TabPanel>
                <ResultTabPanelItemsTable fn_dataTransform={fn_dataTransform} />
            </TabPanel>
        }
        <TabPanel>
            <ResultTabPanelJson />
        </TabPanel>
    </Tabs>
}