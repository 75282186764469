import Select from 'react-select';
import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';

export default function SelectCommandType ({changeHandler, value, options, disabled}) {
    return (
        <Select
            className="selectCommandType reactSelect col-6"
            value={{name: value, label: value}}
            onChange={changeHandler}
            options={options}
            isDisabled={disabled}
        />
    )

}


export class CreatableSingle extends Component {
  state = {
    customOptions: []
  };

  handleChange = (
    newValue,
    actionMeta
  ) => {
    if(newValue && newValue["__isNew__"] === true) {
        this.setState(
            prevState => ({ customOptions: [
                ...prevState.customOptions,
                {name: newValue["label"], label: newValue["label"]}
            ] })
            , () => {
                this.props.changeHandler({name: newValue["value"]})
            })
    } else {
        this.props.changeHandler({name: newValue["name"]})
    }
  };

  getOptions = () => {
    return [
        {name: '-', label: '---'},
        ...this.props.options,
        ...this.state.customOptions
    ]
  }
  render() {
    return (
      <CreatableSelect
        className="selectCommandType reactSelect col-6"
        value={{name: this.props.value, label: this.props.value}}
        options={this.getOptions()}
        isDisabled={this.props.disabled}
        isClearable
        onChange={this.handleChange}
      />
    );
  }
}