import TreeSelectStatus from './TreeSelectStatus'
import generateTreeData from './_generateTreeDataFunctions'

export default function TreeSelectUrlSource({tableData}) {

    let data = generateTreeData("url_source", tableData)

    return (
        <TreeSelectStatus
            data={data}
            expandedVar="urlSourceNodesExpanded"
            checkedVar="urlSourceNodesChecked"
            tableData={tableData}
        />
    )
}