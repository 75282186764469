// iframe.js

import React, { useRef, useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Nodes from './Nodes'
import MouseOverInfo from './MouseOverInfo'
import XpathStatus from './XpathStatus'
import XpathConfigurator from './XpathConfigurator'
import ClientProvider from '../../../../../Various/ClientProvider';
import { makeid } from '../../../../../SideBarRight/CommandsTree/CommandsTree'
export default function Iframe(props) {
  const wsProvider = React.useContext(ClientProvider);

  const [nodesSelected, setNodesSelected] = useState([]);
  const [xpath, setXpath] = useState();
  const [items, setItems] = useState([
    { value: "." },
  ]);
  const [nodeSuggest, setNodeSuggest] = useState();
  const [xpathAttr, setXpathAttr] = useState("innerText")


  const isFullPage = props.content.startsWith("<body") ? true : false

  const getBase = () => {
    if (props.base)
      return "<base href=\"" + props.base + "\"/>"
    return ''
  }
  const getHead = () => {
    return `
      <head>
        ${getBase()}
        <style>
          * {
            margin:0!important;
          }
          head, style, head style, img, svg {
            display:none!important;
          }
          body {
            font-size:1rem;
          }

          *:not(body):not(html):not(head) {
            border-left:1px solid red!important;
            border-top:1px solid red!important;
            border-right:0!important;
            padding-left:8px;
            display:block;
          }
          *:not(body):not(html):not(head):first-child {
            border-top:0!important;
          }
          * > *:last-child {
            border-bottom:0!important;
          }
          *:not(body):not(html):hover {
            background-color:rgba(0,0,0,0.05);
          }
        </style>
      </head>
  `;
  }

  const wrapHTML = (content) => {
    if (!isFullPage)
      return `
      <!DOCTYPE html>
      <html>
        ${getHead()}
        <body style="margin:0">
        ${content.replace("<a", "<a target=\"_blank\"")}
        </body>
      </html>
    `;
    return `
      <!DOCTYPE html>
      <html>
        ${getHead()}
        ${content}
      </html>
  `;
  }

  const getIterator = () => {
    if (iframeRef.current) {
      var obj = (iframeRef.current.childNodes[0].contentDocument)
      return document.evaluate(
        '//*[not(name()="html" or name()="head" or name()="meta" or name()="link" or name()="script" or name()="style" or name()="body" or name()="noscript" or name()="title")]'
        , obj, null, XPathResult.ANY_TYPE, null
      );
    }
  }


  useEffect(() => { //Click an a JS-Element
    var iterator = getIterator()
    var thisHeading = iterator && iterator.iterateNext()
    while (thisHeading) {
      thisHeading.addEventListener("click", handleClick)
      thisHeading = iterator && iterator.iterateNext()
    }

    return () => {
      var iterator = getIterator()
      var thisHeading = iterator && iterator.iterateNext()
      while (thisHeading) {
        thisHeading.removeEventListener("click", handleClick)
        thisHeading = iterator && iterator.iterateNext()
      }
    }
  });

  const handleClick = function (event) {
    if (nodesSelected.length == 0)
      setNodesSelected(nodesSelected => [...nodesSelected, this])
    else
      setNodesSelected(nodesSelected => [nodesSelected[nodesSelected.length - 1], this])
    setNodeSuggest(this)
    event.preventDefault();
    event.stopPropagation();
  }

  const xpathChangeHandler = (value) => {
    setXpath(value.target.value)
  }

  const insertCommand = (command, field, xpath_settings_name) => {
    console.log("Insert", command, field, xpathAttr, xpath)
    if (xpath_settings_name === undefined) {
      xpath_settings_name = "xpath"
    }
    let newCommand = {
      key: makeid(),
      title: field ? field : '',
      className: command,
      settings: {
        attribute: xpathAttr
      },
    }
    newCommand["settings"][xpath_settings_name] = xpath
    wsProvider.CommandsTree.addCommands(newCommand, false, true)
  }


  const iframeRef = useRef(null); // ref => { current: null }

  const writeHTML = (frame) => {
    if (!frame) {
      return;
    }
    let doc = frame.contentDocument;

    doc.open();
    doc.write(wrapHTML(props.content, isFullPage));
    doc.close();
    frame.style.width = '100%';
    if (!isFullPage)
      frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`
  };

  var pretty = require('pretty');

  return (
    <Tabs>
      <TabList>
        <div className="row">
          <div className="col-12">
            <Tab>Browser</Tab>
            <Tab>Source</Tab>
            {props.resultHeader &&
              <li className="resultHeader">
                <pre>
                  <code>
                    {props.resultHeader}
                  </code>
                </pre>

              </li>
            }
          </div>
        </div>
      </TabList>
      <TabPanel>
        <div className="row">
          <div className="col-3">
            <div ref={iframeRef}>
              <iframe
                src="about:blank"
                scrolling="yes"
                frameBorder="0"
                ref={writeHTML}
                className={isFullPage ? "noAutoHeight" : ""}
                title={props.title}
              />
            </div>
          </div>
          <div className="col-7" style={{ position: "relative" }}>
            <div>
              <XpathConfigurator setXpath={setXpath} items={items} setItems={setItems} />
              <div className="mb-3">
                <input
                  className="form-control form-control-sm"
                  onChange={xpathChangeHandler}
                  value={xpath}
                />
              </div>

            </div>
            <MouseOverInfo getIterator={getIterator} />
            <div className="row">
              <Nodes
                nodesSelected={nodesSelected}
                setNodesSelected={setNodesSelected}
                setItems={setItems}
                nodeSuggest={nodeSuggest}
                setNodeSuggest={setNodeSuggest}
              />
            </div>
          </div>
          <div className="col-2">
            <XpathStatus
              iframeRef={iframeRef}
              xpath={xpath}
              isFullPage={isFullPage}
              insertCommand={insertCommand}
              xpathAttr={xpathAttr}
              setXpathAttr={setXpathAttr}
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <pre>
          <code>
            {pretty((props.content), { ocd: true })}
          </code>
        </pre>
      </TabPanel>
    </Tabs>

  );
};

export function getTree(node) {
  var tree = []
  var nodeToIter = node
  tree.unshift(node)
  var parentNode = nodeToIter.parentElement
  while (parentNode) {
    if (nodeToIter.parentElement.tagName === "BODY")
      break
    tree.unshift(parentNode)
    nodeToIter = parentNode
    parentNode = nodeToIter.parentElement
  }
  return tree
}