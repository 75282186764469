import { ItemsTableCommand } from '../../../Various/ItemsTable/ItemsTable'
import ClientProvider from '../../../Various/ClientProvider';
import React from 'react';

export default function If({ execAll, execAllArray, showAll, execSelected, commandKey, groupByField }) {
    const wsProvider = React.useContext(ClientProvider);

    const getItemSingle = () => [prepareExecItem(execAll[execSelected])]

    const getItemsAll = () => execAllArray.map(x => prepareExecItem(x))

    const prepareExecItem = (item) => {
        var res = item["in"]
        res["addClass"] = item["out"].length ? "rowGreen" : "rowRed"
        return res
    }

    const functionToUse = showAll === false ? getItemSingle : getItemsAll
    const [sortColumns, setSortColumns] = wsProvider.useStateVar("command", "sortColumns", [])

    return (
        <ItemsTableCommand
            tableData={functionToUse()}
            sortColumns={sortColumns}
            setSortColumns={setSortColumns}
            mergeCustomFields={true}
            showAll={showAll}
            execSelected={execSelected}
            groupByField={groupByField}

        />
    )

}