import Select from 'react-select';
import React from 'react';
import ProjectProvider from '../ProjectProvider';

export default function ProjectSelect({ selectedProjectId, setSelectedProjectId }) {
    const projectProvider = React.useContext(ProjectProvider);

    const options = projectProvider.projects.map(x => ({ "value": x['id'], "label": x['name']  }))

    const onChange = (result) => {
        setSelectedProjectId(result["value"])
    }

    return (
        <div>
            <Select
                className="projectPathSelect reactSelect"
                value={{ value: selectedProjectId, label: projectProvider.projects.filter(x => x['id'] === selectedProjectId).map(x => x['name'])[0] }}
                onChange={onChange}
                options={[{ "value": "", "label": "" }].concat(options)}
            />
        </div>
    )
}
