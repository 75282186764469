import { useContext, useState } from 'react';
import QueryContext from '../../Context';
import { ItemsTableDefault } from '../../../../../Various/ItemsTable/ItemsTable';

export default function ResultTabPanelItemsTable({ fn_dataTransform, height = "calc(100vh - 270px)", ...props }) {
    const { response } = useContext(QueryContext)
    const [sortColumns, setSortColumns] = useState([])
    var [filterColumns, setFilterColumns] = useState({})

    return <>
        <div style={{ height }} className='content'>
            <ItemsTableDefault
                tableData={fn_dataTransform(response)}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                noMemo={true}
                filterColumns={filterColumns}
                setFilterColumns={setFilterColumns}
                {...props}
            />
        </div>
    </>
}