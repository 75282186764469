import Select from 'react-select';
import React from 'react';
import ClientProvider from '../ClientProvider';
import ProjectPathProvider from '../ProjectPathProvider';

export default function ClientSelect() {
    const clientProvider = React.useContext(ClientProvider);
    const projectPathProvider = React.useContext(ProjectPathProvider);

    const options = clientProvider.clients.filter(
        x => x.projectPath === clientProvider.selectedProjectPath && x.project === projectPathProvider.selectedProjectId)
        .map((x) => ({
            "value": x.client,
            "label": x.client
    }))

    const onChange = (result) => clientProvider.changeClientHandler(result["value"])

    const clickHandler = (e) => {
        clientProvider.refreshClients()
        e.preventDefault();
    }

    return (
        <>
            {clientProvider.clients.length && (
                <div>
                    <Select
                        className="clientSelect reactSelect"
                        value={{ value: clientProvider.clientSelected, label: clientProvider.clientSelected }}
                        onChange={onChange}
                        options={[{ "value": "", "label": "" }].concat(options)}
                        isDisabled={clientProvider.checkIsLoading() ? true : false}

                    />
                </div>
            )}
            <div>
                <button
                    onClick={clickHandler}
                    className="btn btn-primary btn-sm refreshClients"
                >
                    <i className="bi bi-arrow-clockwise"></i>
                </button>
            </div>
        </>
    )

}
