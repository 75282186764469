import { useContext } from 'react';
import ReactJson from 'react-json-view';
import QueryContext from '../../Context';

export default function ResultTabPanelJson() {
    const { response } = useContext(QueryContext)

    return <>
        <div style={{ height: "calc(100vh - 320px)" }}>
            <div style={{ maxHeight: "100%", overflowY: "auto" }}>
                <ReactJson
                    src={response}
                    collapsed
                    theme="monokai"
                    indentWidth={1}
                    enableClipboard={true}
                    displayDataTypes={false}
                />
            </div>
        </div>
    </>
}



