import ClientProvider from '../../../../Various/ClientProvider';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tree from 'rc-tree';
import FilterOutputPanel from './FilterOutputPanel'
import generateTreeData from './_generateTreeDataFunctions'
import { getFlat } from './TreeSelect'

export default function FilterTypeTree({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const wsProvider = React.useContext(ClientProvider);

    const field = wsProvider.CommandsTree.getSingleSetting("field", "breadcrumbs")

    let data = generateTreeData(field, execAllArray.map(x => x["in"]), {
        leafCheckable: false,
        addNodeKeyToKey: true
    })

    const allowedNodeKeys = getFlat({ "children": data }, 1000).filter(x => x["isLeaf"] === false).map(x => x["nodeKey"])

    const checkedNodes = wsProvider.CommandsTree.getSingleSetting("checkedNodes", [])
    const setCheckedNodes = (value) => {
        //Filter Nodes that are not possible
        const result = value.filter(x => allowedNodeKeys.includes(x))
        wsProvider.CommandsTree.setSingleSetting("checkedNodes", result)
    }

    const [expandedKeys, setExpandedKeys] = wsProvider.useStateVar("command", "expandedKeysTree", [])



    return (
        <Tabs>
            <TabList>
                <Tab>Tree ({field})</Tab>
                <Tab>Output</Tab>
            </TabList>
            <TabPanel>
                <Tree
                    treeData={data}
                    selectable={false}
                    checkable={true}
                    checkedKeys={{ checked: checkedNodes }}
                    expandedKeys={expandedKeys}
                    onExpand={setExpandedKeys}
                    checkStrictly={true}
                    onCheck={(value) => {
                        setCheckedNodes(value["checked"])
                    }}
                />
            </TabPanel>
            <TabPanel>
                <FilterOutputPanel
                    execAll={execAll}
                    execAllArray={execAllArray}
                    showAll={showAll}
                    execSelected={execSelected}
                    commandKey={commandKey}
                />
            </TabPanel>
        </Tabs>
    )
}