import React, { useEffect, useRef, useState } from 'react'
import ClientProvider from '../../Various/ClientProvider';
import CompWrapper from '../CompWrapper'

export default function Batch({ }) {
    const wsProvider = React.useContext(ClientProvider);

    return (
        <CompWrapper
            className="batch"
            title={"Batch (" + Object.keys(wsProvider.batchJobsStats).filter(x => ["RUNNING", "SUBMITTED"].includes(wsProvider.batchJobsStats[x].stats.status)).length + ")"}
            buttonBar={(
                <>
                    <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={wsProvider.clearBatchLog}
                    >X</button>
                </>
            )}
        >
            {Object.keys(wsProvider.batchJobsStats).map(x => (
                <BatchJobLine data={wsProvider.batchJobsStats[x]} key={wsProvider.batchJobsStats[x].batchJobName + "_" + wsProvider.batchJobsStats[x].batchJobId} />
            ))}

        </CompWrapper>
    );
}

function BatchJobLine({ data }) {
    const wsProvider = React.useContext(ClientProvider);
    const [workspaceTabs, setWorkspaceTabs] = wsProvider.useStateVarGlobal("workspaceTabs")
    const [batchJobTabs, setBatchJobTabs] = wsProvider.useStateVarGlobal("batchJobTabs")
    const [filterFormDataBatch, setFilterFormDataBatch] = wsProvider.useStateVarGlobal("filterFormDataBatch")

    const stopBatchJob = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "stopBatchJob",
            options: {
                batchJobId: data.batchJobId,
                batchJobName: data.batchJobName
            }
        }, (response) => {
            // alert("stopBatchJob: " + filterFormData.batchJobId)
        })
    }

    const goToBatchJob = () => {
        setWorkspaceTabs(6)
        setBatchJobTabs(2)
        setFilterFormDataBatch({
            batchJobId: data.batchJobId,
            batchJobName: data.batchJobName
        })
    }

    const clearThisJob = () => {
        const batchJobId = data.batchJobId
        const batchJobName = data.batchJobName
        wsProvider.setBatchJobsStats(x => {
            const newData = { ...x }
            delete newData[batchJobName + batchJobId]
            return newData
        })
    }

    const statusShort = data.stats.status.substr(0, 13);
    return (
        <div>
            <span className="col-4 d-inline-block align-top">
                <span onClick={clearThisJob} style={{ "cursor": "pointer" }}>
                    [X]
                </span>
                &nbsp;
                <span onClick={goToBatchJob} style={{ "cursor": "pointer" }}>
                    {data.batchJobName}
                </span>
            </span>
            <span className="col-3 d-inline-block align-top">
                {statusShort}
            </span>
            <span className="col-2 d-inline-block align-top">
                {Math.round(data.stats.progress_succeeded * 100)}%
            </span>
            <span className="col-1 d-inline-block align-top">
                {Math.round(data.stats.progress_failed * 100)}%
            </span>
            {["RUNNING", "SUBMITTED"].includes(data.stats.status) &&
                <span className="col-1 d-inline-block align-top" onClick={stopBatchJob} style={{ "cursor": "pointer" }}>
                    STOP
                </span>
            }

        </div>
    )
}