import React, { useState, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';
import Form from '@rjsf/core';
import MonacoEsSearchWidget from "../../Various/rjsf/manacoEsSearchWidget";
import campaignSchema from "./campaignSchema";
import Button from "react-bootstrap/Button";
import FullQualifiedClientId from "../../Various/FullQualifiedClientId";

export default function WorkspaceCampaigns() {
    const [campaign, setCampaign] = useState(false)
    const wsProvider = React.useContext(ClientProvider);
    const schema = campaignSchema
    schema.properties.campaign_display_groups.items.properties.filter.options= { campaign }
    const  uiSchema =  {
         client_id: {
            "ui:readonly": true
        },
        campaign_start_date: {
            "ui:widget": "date-time"
        },
        campaign_end_date: {
            "ui:widget": "date-time",
        },
        campaign_display_groups: {
            items: {
                filter: {
                    "ui:widget": "monacoes",
                    "ui:options": {
                        onSearch: (value, campaign, callback) =>  { return testCampaignFilterQuery(value, campaign, callback) }
                    }
                }
            }
        }
    }

    const widgets = {
        monacoes: MonacoEsSearchWidget,
    };

    const [refresh, setRefresh] = useState()
    const [formSelectCampaign, setFormSelectCampaign] = wsProvider.useStateVarGlobal("formSelectCampaignStatiticsDiff", {
        campaign_id: null
    })

    const [formAddCampaign, setFormAddCampaign] = useState({
        "campaign_id": ""
    })

    const [selectCampaignSchema, setSelectCampaignSchema] = useState({
        schema: {
            type: 'object',
            properties: {
                campaign_id: {
                    type: 'string',
                    enum: [],
                    title: 'Campaign'
                }
            }
        },
        uiSchema: {
            'ui:order': [
                'campaign_id'
            ]
        }
    })
    const testCampaignFilterQuery = (filter, campaign,  callback) => {
        wsProvider.ws.waitForSocketAndSend({
            action: "testCampaignFilterQuery",
            options: {
                filter: filter,
                campaign: campaign
            }
        },
        (response) => { callback(response) })
    }
    const loadCampaign = (campaign_id) => {
        if (campaign_id === undefined || campaign_id === null) {
            return
        }
        console.log("CAMPAIGN ID", campaign_id)
        wsProvider.ws.waitForSocketAndSend({
            action: "getCampaign",
            options: {
                campaign_id: campaign_id,
                client_id: selectedClientFullId
            }
        }, (response) => {
            setCampaign(convertTimestampsToDates(response.campaign))
        })
    }


    const addCampaign = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "addCampaign",
            options: {
                formData: formAddCampaign
            }
        }, (response) => {
            if (response.campaign) {
                setRefresh(Math.random())
                console.log("Added Campaign", response.campaign)
                setFormSelectCampaign({
                    "campaign_id": response.campaign.campaign_id
                })
            }
        })
    }

    const convertDatesToTimestamps = (campaign) => {
        if (campaign.campaign_start_date) {
            campaign.campaign_start_date = (new Date(campaign.campaign_start_date)).getTime() / 1000
        }
        if (campaign.campaign_end_date) {
            campaign.campaign_end_date = (new Date(campaign.campaign_end_date)).getTime() / 1000
        } else {
            campaign.campaign_end_date = 0
        }

        return campaign
    }

    const convertTimestampsToDates = (campaign) => {
        if (campaign.campaign_start_date) {
            campaign.campaign_start_date = (new Date(campaign.campaign_start_date * 1000)).toISOString()
        }
        if (campaign.campaign_end_date) {
            campaign.campaign_end_date = (new Date(campaign.campaign_end_date * 1000)).toISOString()
        } else {
            campaign.campaign_end_date = ""
        }

        return campaign
    }
    const saveCampaign = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "saveCampaign",
            options: {
                campaign: convertDatesToTimestamps(campaign)
            }
        }, (response) => {
            console.log('saveCampaign response', response)

            convertTimestampsToDates(campaign)
            console.log("Saved Campaign")
        })
    }

    const publishCampaign = () => {
         wsProvider.ws.waitForSocketAndSend({
            action: "publishCampaign",
            options: {
                 campaign: convertDatesToTimestamps(campaign)
            }
        }, () => {
            convertTimestampsToDates(campaign)
            console.log("Published Campaign")
        })
    }
    const selectCampaignChangeHandler = (data) => {
        setFormSelectCampaign(data.formData)
    }

    // TODO: Flo to check
    let selectedClient = wsProvider.clients.filter(x => x.client === wsProvider.clientSelected)[0]
    let selectedClientFullId = selectedClient ? (new FullQualifiedClientId(selectedClient["project"], selectedClient["projectPath"], selectedClient["client"])).toString() : null

    useEffect(() => {
        if (wsProvider.clientSelected === null || wsProvider.clientSelected === "") {
            return
        }
        selectedClient = wsProvider.clients.filter(x => x.client === wsProvider.clientSelected)[0]
        selectedClientFullId = selectedClient ? (new FullQualifiedClientId(selectedClient["project"], selectedClient["projectPath"], selectedClient["client"])).toString() : null
        wsProvider.ws.waitForSocketAndSend({
            action: "getCampaigns",
            options: {
                client_id: selectedClientFullId,
            }
        }, (response) => {
            console.log('getCampaigns response', response)
            setSelectCampaignSchema(definition => {
                return {
                    ...definition,
                    schema: {
                        ...definition.schema,
                        properties: {
                            ...definition.schema.properties,
                            campaign_id: {
                                ...definition.schema.properties.campaign_id,
                                enum: response.campaigns.map(campaign => campaign.campaign_id)
                            }
                        }
                    }
                }
            })
        })
    }, [refresh])

    useEffect(() => {
        loadCampaign(formSelectCampaign.campaign_id)
    }, [formSelectCampaign.campaign_id])



    return (
        <div className="row">
            <div className="col-12 workspaceTop">
                <div className='flex_header'>
                    <div className='header'>
                        <div className='row'>
                            <div className='col-6'>
                                <Form
                                    className="FormSelectCampaignHeader"
                                    schema={selectCampaignSchema["schema"]}
                                    uiSchema={selectCampaignSchema["uiSchema"]}
                                    formData={formSelectCampaign}
                                    onChange={selectCampaignChangeHandler}
                                >
                                </Form>
                            </div>
                            <div className='col-2'>
                            </div>
                            <div className='col-4'>
                                <Form
                                    className="FormStatisticDiffHeader"
                                    schema={{
                                        type: 'object',
                                        properties: {
                                            client_id: {
                                                type: 'string',
                                                default: selectedClientFullId
                                            },
                                            campaign_name: {
                                                type: 'string',
                                                default: ''
                                            }
                                        },
                                        required: ["client_id", "campaign_name"]
                                    }}
                                    uiSchema={{
                                        client_id: {"ui:widget": "hidden"},
                                        'ui:order': [
                                            'client_id',
                                            'campaign_name',
                                        ]
                                    }}
                                    onChange={(data) => setFormAddCampaign(data.formData)}
                                    formData={formAddCampaign}
                                    onSubmit={addCampaign}
                                >
                                    <button className="btn btn-info">Add</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className='campaign_details'>
                        {campaign &&
                            <>
                                <div className='row'>
                                    <div className='col-12 campaign_form'>
                                        <Form
                                            schema={schema}
                                            uiSchema={uiSchema}
                                            formData={campaign}
                                            onSubmit={saveCampaign}
                                            onChange={(data) => setCampaign(data.formData)}
                                            widgets={widgets}
                                        />
                                        <Button onClick={publishCampaign}>Publish</Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}