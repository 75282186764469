//import {useState} from "react";
import ItemsTable, { ItemsTableCommand, ItemsTableOther } from '../../../Various/ItemsTable/ItemsTable'
import { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React from 'react';
import ReactJson from 'react-json-view'
import ClientProvider from '../../../Various/ClientProvider';
import Form from "@rjsf/core";
import { procConfigCommands } from '../../../SideBarRight/ProcConfigTree/procConfigCommands'
import getWordsLibConfig from '../../../SideBarRight/WordsLib/getWordsLibConfig'
import EcedFullSelectWidget from '../../../SideBarRight/WordsLib/ecedFullSelectWidget'
import MultiUnderstand from './textProcessor/MultiUnderstand'

export default function TextProcessor(props) {
    const wsProvider = React.useContext(ClientProvider);
    const CommandsTree = props["CommandsTree"]

    let field = CommandsTree.getAttr("title")

    const outputFields = [
        "#source",
        "title",
        "url",
        "lang_url",
        field,

        "s_errors",
        "s_infos",
        "s_output",
        "s_flat",
        "s_dimensions",
        "s_patterns",

        "t_output",
        "t_flat",
        "t_dimensions",


    ]

    const clickHandler = (item, column, index) => {
        var textProcessorTabs = null
        var totalActive = null
        switch (column) {
            case "s_errors":
                textProcessorTabs = 1
                totalActive = false
                break
            case "s_infos":
                textProcessorTabs = 2
                totalActive = false
                break
            case "s_output":
                totalActive = false
                break
            case "s_patterns":
                textProcessorTabs = 3
                totalActive = false
                break
            case "s_dimensions":
                textProcessorTabs = 5
                totalActive = false
                break
            case "s_flat":
                textProcessorTabs = 4
                totalActive = false
                break
            case "t_dimensions":
                textProcessorTabs = 5
                totalActive = true
                break
            case "t_flat":
                textProcessorTabs = 4
                totalActive = true
                break
            case "t_output":
                totalActive = true
                break
            case wsProvider.CommandsTree.getAttr("title"):
                textProcessorTabs = 0
                break
        }
        if (textProcessorTabs !== null) {
            wsProvider.setStateVar("crawler", "textProcessorTabs", textProcessorTabs)
        }
        if (totalActive !== null) {
            wsProvider.setStateVar("crawler", "totalActive", totalActive)
        }

    }

    props = {
        ...props,
        field: field,
        outputFields: outputFields,
        ProcessorDetails: TextProcessorDetails,
        clickHandler: clickHandler,
        command: "textProcessor"
    }

    return Processor(props)
}

export function Processor({ execAll, execAllArray, showAll, execSelected, commandKey, CommandsTree, ProcessorDetails, field, outputFields, clickHandler, command, groupByField }) {
    const wsProvider = React.useContext(ClientProvider);

    const getItemSingleCombined = (execSelected) => {
        var results = []
        var result = {}
        result["#exec"] = execSelected.toString()   //Needs to be string for sorting reasons

        if (execAll[execSelected]["out"].length === 0) {
            outputFields.forEach(field1 => {
                result[field1] = "-----"
            });
            results.push(result)
        } else {
            execAll[execSelected]["out"].forEach(execItemOut => {
                var result2 = { ...result }
                outputFields.forEach(field1 => {
                    result2[field1] = execItemOut[field1]
                });
                results.push(result2)
            })
        }

        return results
    }

    const getItemsAllCombined = () => {
        var results = []
        for (let i = 0; i < execAllArray.length; i++) {
            getItemSingleCombined(execAllArray[i]["#exec"]).forEach(item => results.push(item))
        }
        return results
    }

    const functionToUseCombined = showAll === false ? getItemSingleCombined : getItemsAllCombined

    /* Sorry */
    const errorField = { "textProcessor": "s_errors", "valueToField": "errors", "extractTable": "errors" }[command]
    const errorColor = { "textProcessor": null, "valueToField": "rowOrange", "extractTable": "rowOrange" }[command]

    var dataCombined = functionToUseCombined(execSelected)

    if (errorColor) {
        dataCombined.forEach(x => {
            x["addClass"] = x[errorField] === undefined || x[errorField].length === 0 ? "rowGreen" : errorColor
            return x
        })
    }

    if ((execAllArray.length > 0 && (execAll[execSelected] || showAll === true))) {
        const [sortColumns, setSortColumns] = wsProvider.useStateVar("crawler", "sortColumns", [])

        return (
            <div className="row">
                <div className="col-6 height100">
                    <ItemsTableCommand
                        tableData={dataCombined}
                        mergeCustomFields={false}
                        sortColumns={sortColumns}
                        setSortColumns={setSortColumns}
                        clickHandler={clickHandler}
                        showAll={showAll}
                        execSelected={execSelected}
                        groupByField={groupByField}
                    />
                </div>
                <div className="col-6 height100">
                    {ProcessorDetails({
                        row: dataCombined.find(x => x["#exec"] == execSelected),
                        dataCombined: dataCombined,
                        execSelected: execSelected,
                        execAll: execAll,
                        execAllArray: execAllArray,
                        showAll: showAll,
                        field: field,
                        outputFields: outputFields
                    })}
                </div>
            </div>
        )
    }
    return null

}

function TextProcessorDetails({ row, execSelected, field, showAll, execAll, execAllArray, outputFields }) {
    const wsProvider = React.useContext(ClientProvider);

    const [useTotalActive, setUseTotalActive] = wsProvider.useStateVar("crawler", "totalActive", false)
    const [selectedIndex, setSelectedIndex] = wsProvider.useStateVar("crawler", "textProcessorTabs", 0)

    const handleChange = () => {
        setUseTotalActive(!useTotalActive);
    };

    if (!row)
        return null

    let baseKey = useTotalActive ? "t_" : "s_"
    let tableData = row[baseKey + "output"]


    var commandsEffectedResultObj = getCommandsEffectedResultObj(wsProvider, execSelected)
    let myValuesCount = Object.keys(commandsEffectedResultObj).map(key => Object.keys(commandsEffectedResultObj[key]["myValues"]).length).reduce((a, b) => a + b, 0)
    return (
        <div className="processorDetails">
            <div className="elem1-0">
                <h4>{row["title"]}</h4>
                <p>
                    <a href={row["url"]} target="_blank">
                        {row["url"]}
                    </a>
                </p>
                {Array.isArray(row[field]) && !row[field].includes("\n") &&
                    <p>{row[field]}</p>
                }
            </div>
            <div className="elem1-1">
                <Checkbox
                    label=" Total"
                    value={useTotalActive}
                    onChange={handleChange}
                />
            </div>
            <div className="elem2-0">
                {Array.isArray(row["s_errors"]) &&
                    <Tabs
                        selectedIndex={selectedIndex}
                        onSelect={setSelectedIndex}
                        className="LoopCommand"
                    >
                        <TabList>
                            <Tab>Raw</Tab>
                            <Tab>Multi Understand</Tab>
                            <Tab>Error ({row["s_errors"].length})</Tab>
                            <Tab>Infos ({row["s_infos"].length})</Tab>
                            <Tab>Lines ({row["s_patterns"].length})</Tab>
                            <Tab>Flat ({row[baseKey + "flat"].length})</Tab>
                            <Tab>Dimensions ({Object.keys(row[baseKey + "dimensions"]).length})</Tab>
                            <Tab>Commands-Effected ({Object.keys(commandsEffectedResultObj).length}{"->"}{myValuesCount})</Tab>
                        </TabList>
                        <TabPanel>
                            {row[field]}
                        </TabPanel>
                        <TabPanel>
                            <MultiUnderstand
                                execSelected={execSelected}
                                execAll={execAll}
                                execAllArray={execAllArray}
                                showAll={showAll}
                                outputFields={outputFields}
                            />
                        </TabPanel>
                        <TabPanel>
                            <ErrorWorkspace data={row["s_errors"]} />
                        </TabPanel>
                        <TabPanel>
                            <ErrorWorkspace data={row["s_infos"]} />
                        </TabPanel>
                        <TabPanel>
                            <JSONTabTabPanel data={row["s_patterns"]} />
                        </TabPanel>
                        <TabPanel>
                            <JSONTabTabPanel data={row[baseKey + "flat"]} />
                        </TabPanel>
                        <TabPanel>
                            <JSONTabTabPanel data={row[baseKey + "dimensions"]} />
                        </TabPanel>
                        <TabPanel>
                            <CommandsEffected commandsEffectedResultObj={commandsEffectedResultObj} />
                        </TabPanel>
                    </Tabs>
                }
            </div>
            <div className="elem3-0">
                <OutputTable
                    tableData={tableData}
                    time={wsProvider.CommandsTree.getStatsTime()}
                    key={row["#exec"]}
                />
            </div>

        </div>
    )
}

const CommandsEffected = ({ commandsEffectedResultObj }) => {
    return Object.keys(commandsEffectedResultObj).map((key) => (
        <CommandsEffectedCommandGroup key={key} commandGroup={commandsEffectedResultObj[key]} />
    ))
}

const CommandsEffectedCommandGroup = ({ commandGroup }) => {
    return (
        <div>
            <h4>{commandGroup["commandGroup"]["command"]}</h4>
            {Object.keys(commandGroup["myValues"]).map(key => (
                <CommandsEffectedCommandMyValues value={commandGroup["myValues"][key]} />
            ))}

        </div>
    )
}

const CommandsEffectedCommandMyValues = ({ value }) => {
    return (
        <div>
            {value["valRegex"] ? value["valRegex"] : "-"}
        </div>
    )
}

const getCommandsEffectedResultObj = (wsProvider, execSelected) => {

    const procConfigStats = wsProvider.CommandsTree.getProcConfigStats()
    let procConfig = wsProvider.CommandsTree.getSingleSetting("procConfig")
    var resultObj = {}

    if (!procConfig)
        return resultObj

    procConfig.forEach((commandGroup, commandGroupIndex) => {
        commandGroup["values"].forEach((values, valueIndex) => {
            //console.log(valueIndex, values)
            if (commandGroupIndex in procConfigStats) {
                if (valueIndex in procConfigStats[commandGroupIndex]) {
                    if (execSelected in procConfigStats[commandGroupIndex][valueIndex] && procConfigStats[commandGroupIndex][valueIndex][execSelected]["result"] === true) {
                        //exec found
                        if (!(commandGroupIndex in resultObj))
                            resultObj[commandGroupIndex] = { commandGroup: commandGroup, myValues: {} }
                        if (!(valueIndex in resultObj[commandGroupIndex]["myValues"]))
                            resultObj[commandGroupIndex]["myValues"][valueIndex] = values
                    }

                }
            }
        })
    })
    return resultObj
}

const OutputTable = ({ tableData, time }) => {
    const [sortColumns, setSortColumns] = useState([])

    if (!Array.isArray(tableData))
        return null
    tableData = tableData.filter(x => !isEmpty(x))
    if (tableData && !isEmpty(tableData))
        return (
            <ItemsTableOther
                tableData={tableData}
                mergeCustomFields={false}
                extraClass="g-0 below99"
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                time={time}
            />
        )
    return null
}

export const JSONTabTabPanel = ({ data }) => {
    if (data && !isEmpty(data))
        return (
            <ReactJson
                src={data}
                theme="monokai"
                indentWidth={2}
                displayDataTypes={false}
            />
        )
    return null
}

const Checkbox = ({ label, value, onChange }) => {
    return (
        <label>
            <input type="checkbox" checked={value} onChange={onChange} />
            <span className="h6">{label}</span>
        </label>
    );
};

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export const ErrorWorkspace = ({ data, UseErrorDetailsComp, execSelected, showAll }) => {
    const [selected, setSelected] = useState(0)
    const selectErrorWorkspace = (index) => setSelected(index)

    if (!Array.isArray(data))
        return null

    const getListItems = () => {
        return data.map((x, index) =>
            <button
                onClick={() => { selectErrorWorkspace(index) }}
                className="btn btn-primary btn-sm"
                disabled={selected == index ? "disabled" : ""}
                key={index}
            >
                {index}
            </button>
        )
    }

    if (UseErrorDetailsComp !== undefined) {
        var ErrorDetailsComp = UseErrorDetailsComp
    } else {
        var ErrorDetailsComp = ErrorDetail
    }

    if (data.length) {
        return (
            <div className="errorWorkspace">
                <div>
                    <div className="d-grid gap-2 errorNavi">
                        {getListItems()}
                    </div>
                </div>
                {data[selected] &&
                    <ErrorDetailsComp data={data[selected]} key={data[selected]["value"] + data[selected]["name"]} selectedError={selected} />
                }
            </div>
        )
    }
    return null
}




const ErrorDetail = ({ data, selectedError }) => {
    const tabList = Object.keys(data["commands"]).map((command, index) =>
        <Tab key={selectedError + "_" + index}>{command}</Tab>
    );

    const [showFullLineValue, setShowFullLineValue] = useState(false)

    const tabPanel = Object.keys(data["commands"]).map((command, index) =>
        <TabPanel key={selectedError + "_" + index}>
            <ErrorCommandDetail command={command} commandData={data["commands"][command]} />
        </TabPanel>
    );

    const prepLineValue = (value) => {
        if (value === undefined)
            return null
        const secondPlusLines = value.split("\n")
        const firstLine = secondPlusLines.shift()
        return (
            <p title={value}>
                {secondPlusLines.length > 0 &&
                    <>
                        {firstLine}{" "}<span onClick={() => setShowFullLineValue(x => !x)}>[{showFullLineValue ? "-" : "+"}]</span>
                        {showFullLineValue === true &&
                            <>
                                {"\n" + secondPlusLines.join("\n")}
                            </>
                        }
                    </>
                }
                {secondPlusLines.length === 0 &&
                    value
                }

            </p>
        )
    }

    return (
        <div className="errorDetail">
            {prepLineValue(data["value"])}
            <h5>{data["name"]}: {data["message"]}</h5>
            <Tabs className="LoopCommand">
                <TabList>
                    {tabList}
                </TabList>
                {tabPanel}
            </Tabs>
        </div>
    )
}

export const ErrorCommandDetail = ({ command, commandData }) => {

    const [formData, setFormData] = useState(commandData["formData"])

    const onChangeHandler = (a) => {
        setFormData(a.formData)
    }

    switch (commandData["solveType"]) {
        case "addWordsLib":
            return (
                <ErrorCommandDetail_addWordsLib
                    formData={formData}
                    setFormData={setFormData}
                    command={command}
                    commandData={commandData}
                    onChangeHandler={onChangeHandler}
                />
            )
        case "addProcConfig":
            return (
                <ErrorCommandDetail_addProcConfig
                    formData={formData}
                    setFormData={setFormData}
                    command={command}
                    commandData={commandData}
                />
            )
    }
}

const ErrorCommandDetail_addProcConfig = ({ formData, setFormData, command, commandData }) => {
    const wsProvider = React.useContext(ClientProvider);

    var procConfig = wsProvider.CommandsTree.getSingleSetting("procConfig")
    if (!procConfig)
        procConfig = []

    const submitHandler = (data) => {
        const order = [
            "SplitSentences",
            "ReplaceRegex",
            "SplitRegex",
            "SplitBeforeRegex",
            "SplitAfterRegex",
            "DelContainsRegex",
            "FillTitles",
        ]

        //Find Group or add one
        let useProcConfig = [
            ...procConfig
        ]
        let useGroup = useProcConfig.find(x => x["command"] === command)
        if (!useGroup) {
            useGroup = { command: command, values: [] }
            useProcConfig.push(useGroup)

            /*
            //!!Dangerous: Should not resort completly. Solution: just find a good place to merge it in. to not destroy existing logic.

            //The order of the commands is important and gets normalized here. In case there is a new group
            useProcConfig.sort(function(a, b) {
                var val1 = order.findIndex(x => x===a["command"])
                var val2 = order.findIndex(x => x===b["command"])
                if (val1 < val2) return -1;
                if (val1 > val2) return 1;
                return 0;
            });
            */
        }

        useGroup["values"].push(data["formData"])

        wsProvider.CommandsTree.setSingleSetting("procConfig",
            useProcConfig
        )

        //Set workspace
        const [sidebarTabs1, setSidebarTabs1] = wsProvider.useStateVarGlobal("sidebarTabs1")
        const [sidebarTabs2, setSidebarTabs2] = wsProvider.useStateVarGlobal("sidebarTabs2")
        setSidebarTabs1(1)
        setSidebarTabs2(1)
    }

    var schema = procConfigCommands[command]["schema"]
    var uischema = {
        ...procConfigCommands[command]["uischema"],
        "disabled": {
            'ui:widget': 'hidden',
        },
    }

    return (
        <Form
            schema={schema}
            uiSchema={uischema}
            formData={formData}
            onSubmit={submitHandler}
            className={command}
        />
    )
}

export const ErrorCommandDetail_addWordsLib = ({ formData, setFormData, command, commandData, onChangeHandler }) => {
    const wsProvider = React.useContext(ClientProvider);
    const wordsLibOptions = {
        cat: formData.cat,
        type: commandData["wordsLibType"],
        baseFields: wsProvider.baseFields
    }
    const wordsLibConfig = getWordsLibConfig(wordsLibOptions)
    const submitHandler = (data) => {
        let formData = data["formData"]
        let wordsLibScope = formData["scope"]
        delete formData["scope"]


        wsProvider.addWordsLib([formData], wordsLibScope)



        //SET FILTERS TO THIS VALUE
        wsProvider.setStateVar("command", "wordsLibType", formData["type"]);
        wsProvider.setStateVar("command", "wordsLibScope", wordsLibScope)

        //Set workspace
        const [sidebarTabs1, setSidebarTabs1] = wsProvider.useStateVarGlobal("sidebarTabs1")
        const [sidebarTabs2, setSidebarTabs2] = wsProvider.useStateVarGlobal("sidebarTabs2")
        setSidebarTabs1(1)
        setSidebarTabs2(0)

        Object.keys(wordsLibConfig["filterSchema"]).forEach(identifier => {
            wsProvider.setStateVar("command", "wordsLib_" + formData["type"] + "_" + identifier, formData[identifier])
        })

    }

    var schema = {
        ...wordsLibConfig["schema"],
        "title": "",
        "properties": {
            scope: {
                enum: wordsLibConfig["scopes"].map(x => x["name"]),
                title: 'Scope',
                type: 'string',
                default: wordsLibConfig["scopes"][0]["name"]
            },
            ...wordsLibConfig["filterSchema"],
            ...wordsLibConfig["schema"]["properties"]
        }
    }
    Object.filter = (obj, predicate) =>
        Object.fromEntries(Object.entries(obj).filter(predicate));

    let filtered = Object.filter(wordsLibConfig["uischema"], ([key, value]) => (!["lang", "subtype", "cat"].includes(key)))

    var uischema = {
        ...filtered,
        'ui:order': [
            "scope",
            ...wordsLibConfig["uischema"]['ui:order']
        ]
    }

    return (
        <Form
            schema={schema}
            uiSchema={uischema}
            formData={formData}
            onSubmit={submitHandler}
            onChange={onChangeHandler}
            className={command}
            widgets={{
                ecedFullSelectWidget: EcedFullSelectWidget
            }}
            key={JSON.stringify(wordsLibOptions)}
        />
    )
}

export const ErrorWorkspaceNoNavi = ({ data, UseErrorDetailsComp, execSelected, showAll, errorName, resultFunc, checkValueExistsFunc, wordsLibType }) => {
    if (!Array.isArray(data))
        return null

    if (UseErrorDetailsComp !== undefined) {
        var ErrorDetailsComp = UseErrorDetailsComp
    } else {
        var ErrorDetailsComp = ErrorDetail
    }

    if (data.length) {
        return (
            <div className="errorWorkspaceNoNavi container">
                <ErrorDetailsComp
                    data={data}
                    key={execSelected + showAll ? "true" : "false"}
                    wordsLibType={wordsLibType}
                    resultFunc={resultFunc}
                    checkValueExistsFunc={checkValueExistsFunc}
                    errorName={errorName}
                />
            </div>
        )
    }
    return null
}

function filterObject(obj, callback) {
    return Object.fromEntries(Object.entries(obj).filter(([key, val]) => callback(val, key)));
}