import Node from './Node'
import NodeCompare from './NodeCompare'
import NodeXPathSuggest from './NodeXPathSuggest'
import { useState } from 'react'

export default function Nodes ({nodesSelected, setNodesSelected, setItems, nodeSuggest, setNodeSuggest}) {
    var index=-1
    return (
        <>
        {nodesSelected.map(node => { 
            index = index + 1
            return (
            <div className="col">
                <Node 
                    nodesSelected={nodesSelected}
                    setNodesSelected={setNodesSelected}
                    nodeIndex={index} 
                    nodeSuggest={nodeSuggest}
                    setNodeSuggest={setNodeSuggest}
                />
            </div>
        )})}
        <div className="col-4">
            <NodeXPathSuggest nodesSelected={nodesSelected} nodeSuggest={nodeSuggest} setItems={setItems}/>
        </div>
        </>
    )
}
/*
        {nodesSelected.length>1 &&
            <div className="col-2">
                <NodeCompare nodesSelected={nodesSelected}/>
            </div>
        }
*/