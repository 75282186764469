import { default as ReactSelect, components } from "react-select";
import ClientProvider from '../../Various/ClientProvider';
import React from 'react';
import ecedFullTreeFlat from '../../../data/flat.json';

export default function EcedFullSelectWidget(props) {

    /*
    const handleChange = (selected, event) => {
        props.onChange(selected.map(x => x.value))
    };
    */

    // eg:
    // input: 101_1021

    const enumOptions = []
    Object.keys(ecedFullTreeFlat).forEach(function (key, index) {
        enumOptions.push({
            value: ecedFullTreeFlat[key].id,
            label: ecedFullTreeFlat[key].title,
        })
    });
    enumOptions.sort((a, b) => a["value"].localeCompare(b["value"]))

    const handleChange = (value) => {
        const values = value.map(x => x.value)
        values.sort()
        props.onChange(values.join("_"))
    };

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    //console.log(props)
    /*
    return (
        <input
            value={props.value}
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
        />
    )
    */

    const values = props.value ? props.value.split("_").filter(x => x).map(cat => ({
        "value": cat,
        "label": cat
    })) : []

    return (
        <div
            className=""
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please selecet account(s)"
        >
            <ReactSelect
                /* menuIsOpen={true} */

                className="reactSelect"
                options={enumOptions}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    Option
                }}
                onChange={handleChange}
                allowSelectAll={true}
                value={values}
            />
        </div>
    )
};

/*
props.value.map(x => {
                    const labelItem = enumOptions.find(item => (item["value"] === x))
                    return {
                        "value": x,
                        "label": labelItem ? labelItem.label : x
                    }
                })
*/