import {getTree} from './Iframe'
import {getSiblingIndexSameTag} from './Node'

export default function NodeTreeElement ({node, setNodeSuggest, nodesSelected, nodeSuggest, otherTreeNode}) {
    
    const clickHandler = () => {
        setNodeSuggest(node)
    }

    let buttonClass = ''
    if(node.isEqualNode(nodeSuggest))
        buttonClass = "btn-success"
    else if(node.isEqualNode(otherTreeNode) || node.tagName==='HTML' || node.tagName==='BODY' || otherTreeNode === null)
        buttonClass = "btn-primary"
    else
        buttonClass = "btn-secondary"
    
    let idAttr = node.getAttribute("id")
    let classAttr = node.getAttribute("class")
    idAttr = idAttr ? "#"+idAttr : ''
    classAttr = classAttr ? "."+classAttr : ''
    
    let desc = [idAttr, classAttr].join(" ")
    let descShort = desc.length > 20 ? desc.substr(0,18)+"..." : desc

    return (
        <>
        <div className="d-grid gap-2">
            <button 
                title={desc}
                className={"btn btn-sm btn-node " + buttonClass}
                onClick={clickHandler}
                >{node.tagName}[{getSiblingIndexSameTag(node)}] {descShort}   
            </button>
        </div>

        </>
    )
}