import React, { useState } from 'react';
import ClientProvider from '../../../Various/ClientProvider';
import { onlyUnique } from '../../../Various/TreeFilter/helpers'
import DataGrid, { SortableHeaderCell, RowRendererProps, Row, TextEditor } from 'react-data-grid';

export default function MapString({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const wsProvider = React.useContext(ClientProvider);
    const type = wsProvider.CommandsTree.getSingleSetting("type")

    const getMappingData = () => {
        var data = wsProvider.CommandsTree.getSingleSetting("mappingData", {
            "items": {}
        })

        if (typeof data === 'string' || data instanceof String) {
            data = {
                "items": {}
            }
        }

        if (!data || !("items" in data)) {
            data["items"] = {}
        }

        return data
    }

    const getRows = () => {

        const data = getMappingData()

        const uniqueValues = Object.keys(execAll).map(x => execAll[x].in).filter(onlyUnique)

        const rows = uniqueValues.map(x => {
            return {
                "input": x,
                "replace": x in data.items && data.items[x]["replace"] ? data.items[x]["replace"] : null,
                "prefix": x in data.items && data.items[x]["prefix"] ? data.items[x]["prefix"] : null,
                "suffix": x in data.items && data.items[x]["suffix"] ? data.items[x]["suffix"] : null
            }
        })
        return rows
    }

    // const [rows, setRows] = useState(getRowsInit);

    const onRowsChangeHandler = (data_table) => {
        const data_existing = getMappingData()
        // console.log(data_table)
        Object.keys(data_table).forEach(row_index => {
            if (!(data_table[row_index]["input"] in data_existing.items)) {
                data_existing.items[data_table[row_index]["input"]] = {}
            }
            data_existing.items[data_table[row_index]["input"]]["replace"] = data_table[row_index]["replace"] ? data_table[row_index]["replace"] : null
            data_existing.items[data_table[row_index]["input"]]["prefix"] = data_table[row_index]["prefix"] ? data_table[row_index]["prefix"] : null
            data_existing.items[data_table[row_index]["input"]]["suffix"] = data_table[row_index]["suffix"] ? data_table[row_index]["suffix"] : null

            if (data_existing.items[data_table[row_index]["input"]]["replace"] === null && data_existing.items[data_table[row_index]["input"]]["prefix"] === null && data_existing.items[data_table[row_index]["input"]]["suffix"] === null) {
                delete data_existing.items[data_table[row_index]["input"]]
            }
        })

        wsProvider.CommandsTree.setSingleSetting("mappingData", data_existing)
    }

    /*
    wsProvider.CommandsTree.setSingleSetting("mappingData",
        useProcConfig
    )
    */


    const columns = [{
        key: 'input',
        name: 'input',
        width: "25%",
        frozen: true,
        // editor: textEditor
    }, {
        key: 'replace',
        name: 'replace',
        width: "25%",
        frozen: true,
        editor: TextEditor
    }, {
        key: 'prefix',
        name: 'prefix',
        width: "25%",
        frozen: true,
        editor: TextEditor
    }, {
        key: 'suffix',
        name: 'suffix',
        width: "25%",
        frozen: true,
        editor: TextEditor
    }]
    return (
        <DataGrid
            columns={columns}
            rows={getRows()}
            className="rdg-dark"
            onRowsChange={onRowsChangeHandler}

        />)
}