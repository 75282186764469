import { makeid } from '../components/SideBarRight/CommandsTree/CommandsTree'
import envs from '../envs'

const fixedResponses = {
  "runCrawlerComplete": (data, wsProvider) => {
    receiveItems(wsProvider, data["data"]["content"], data["data"]["client"], data["data"]["crawler"])
  }
}

const receiveItems = (wsProvider, response, client, crawlerKey) => {
  //console.log("GOT IT")

  if (response["type"] === 'item') {
    //Append new ones
    wsProvider.CrawlerTree.setItems(items2 => [...items2, response["content"]], crawlerKey)
  } else if (response["type"] === 'stats') {
    wsProvider.updateStats(response["content"], crawlerKey, client, { type: "crawler" })
  }
}

export default class WS extends WebSocket {
  onerror = (event) => {
    console.log('Websocket ERROR Recv')
  };

  onopen = () => {
    this.reconnectCounter = 0
    console.log('Opened Connection Recv!')
    if (this.wsProvider && this.wsProvider.setConnectedStatus) {
      this.wsProvider.setJobs([])
      this.wsProvider.setConnectedStatus(true)
    }
  };

  checkFixedActionResponse = (data) => {
    return (data["action"] in fixedResponses) ? true : false
  }

  fixedActionResponse = (data) => {
    fixedResponses[data["action"]](data, this.wsProvider)
  }

  onmessage = (event) => {
    // this.setState({ currentData: JSON.parse(event.data) });
    const data = JSON.parse(event.data)
    console.log("UH, there's mail..: " + data["responseType"])
    var jobId = data["jobId"]
    switch (data["responseType"]) {
      case "actionResponse":
        console.log("actionResponse (" + data["action"] + "): " + jobId)
        if (this.checkFixedActionResponse(data)) {
          this.fixedActionResponse(data)
        } else if (jobId in this.wsProvider.callbacks) {
          this.wsProvider.callbacks[jobId](data["data"])
        } else {
          console.log("MISSING: " + data["action"])
        }
        break
      case "stdout":
        this.wsProvider.toLog(data["data"])
        break
      case "broadcast":
        switch (data["broadcastFunction"]) {
          case "setWordsLibGlobal":
            this.wsProvider.setWordsLibGlobal(data["wordsLib"])
            break
          case "batchJobUpdate":
            if (data["data"]["level"] === "batchJobItemCommand") {
              console.log(data["data"]["stats"]["path"], data["data"]["stats"]["status"])
            }
            if ("batchJobUpdate" in this.wsProvider.callbacks) {
              Object.keys(this.wsProvider.callbacks["batchJobUpdate"]).forEach(functionKey => {
                this.wsProvider.callbacks["batchJobUpdate"][functionKey](data["data"])
              })
            } else {
              console.log("batchJobUpdate HANDLER MISSING")
            }
            break
        }
      case "jobDone":
        //alert("JOB DONE: "+jobId + " " + data["action"])
        //console.log("JOB DONE..: "  + jobId)
        this.wsProvider.setJobs(jobs => {
          if (!(jobId in jobs)) {
            return jobs
          }

          let res = {
            ...jobs,
          }
          delete res[jobId]
          /*
          res[jobId] = {
            ...res[jobId]
          }
          res[jobId]["status"] = "done"
          */
          return res
        })
        break
    }
    return false
  };
  reconnectCounter = 0

  reconnectIn = () => {
    if (this.reconnectCounter < 10) {
      return 500
    }

    if (this.reconnectCounter > 100) {
      return 100000000
    }
    return 5000
  }
  onclose = (event) => {
    console.log('Closed Connection Recv!')
    var wsProvider = this.wsProvider
    var reconnectCounter = this.reconnectCounter
    if (wsProvider) {
      if (wsProvider.setConnectedStatus) {
        wsProvider.setConnectedStatus(false)
      }

      setTimeout(function () {
        console.log('Trying to reconnect  (' + envs.recv_port + ')...', reconnectCounter)

        var newWS = new WS(envs.websocket_protocol + "://" + envs.host + ":" + envs.recv_port + "/?websocketClientId=" + wsProvider.websocketClientId + '&authToken=' + wsProvider.authToken)
        newWS.reconnectCounter = reconnectCounter + 1
        newWS.wsProvider = wsProvider
        wsProvider.setWsRecv(newWS);
      }, this.reconnectIn());
    }
  };
}