export const procConfigCommands = {
    SplitRegex: {
        schema: {
            "type": 'object',
            "properties": {
                "disabled1": {
                    "title": 'Deaktivieren',
                    "type": 'boolean'
                },
                "valRegex": {
                    "type": 'string',
                    "title": "Regex"
                }
            }
        },
        uischema: {
            'ui:order': [
                "disabled1",
                'valRegex'
            ]   
        }
    },
    SplitBeforeRegex: {
        schema: {
            "type": 'object',
            "properties": {
                "disabled1": {
                    "title": 'Deaktivieren',
                    "type": 'boolean'
                },
                "valRegex": {
                    "type": 'string',
                    "title": "Regex"
                }
            }
        },
        uischema: {
            'ui:order': [
                "disabled1",
                'valRegex'
            ]   
        }
    },
    SplitAfterRegex: {
        schema: {
            "type": 'object',
            "properties": {
                "disabled1": {
                    "title": 'Deaktivieren',
                    "type": 'boolean'
                },
                "valRegex": {
                    "type": 'string',
                    "title": "Regex"
                }
            }
        },
        uischema: {
            'ui:order': [
                "disabled1",
                'valRegex'
            ]   
        }
    },
    DelContainsRegex: {
        schema: {
            "type": 'object',
            "properties": {
                "disabled1": {
                    "title": 'Deaktivieren',
                    "type": 'boolean'
                },
                "valRegex": {
                    "type": 'string',
                    "title": "Regex"
                }
            }
        },
        uischema: {
            'ui:order': [
                "disabled1",
                'valRegex'
            ]   
        }
    },
    FillTitles: {
        schema: {
            "type": 'object',
            "properties": {
                "disabled1": {
                    "title": 'Deaktivieren',
                    "type": 'boolean'
                },
                "valRegex": {
                    "type": 'string',
                    "title": "Regex"
                }
            }
        },
        uischema: {
            'ui:order': [
                "disabled1",
                'valRegex'
            ]   
        }
    },
    SplitSentences: {
        schema: {
            "type": 'object',
            "properties": {
                "disabled1": {
                    "title": 'Deaktivieren',
                    "type": 'boolean'
                }
            }
        },
        uischema: {
            'ui:order': [
                "disabled1",
            ]   
        }
    },
    ReplaceRegex: {
        schema: {
            "type": 'object',
            "properties": {
                "disabled1": {
                    "title": 'Deaktivieren',
                    "type": 'boolean'
                },
                "proposeGeneral": {
                    "type": 'boolean'
                },
                "valRegex": {
                    "type": 'string',
                    "title": "Regex"
                },
                "replRegex": {
                    "type": 'string',
                    "title": "Replace Regex"
                }
            }
        },
        uischema: {
            'ui:order': [
                'valRegex',
                'replRegex',
                "disabled1",
                "proposeGeneral",
            ]
        }
    }
}