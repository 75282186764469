import ConfigWrapper from "../../Base/Config/ConfigWrapper";
import IndexSelector from "../../Base/Config/IndexSelector";
import ConfigForm from "../../Base/Config/ConfigForm";
import RunButtonQuery from "../../Base/Config/RunButtonQuery";
import { useContext } from "react";
import QueryContext from "../../Base/Context";

export default function QueryCoursesByTopicGroupConfig() {
    const { formData } = useContext(QueryContext)

    const generate_query = () => {
        if (formData.query)
            return JSON.parse(formData.query)
    }

    return <ConfigWrapper
        defaultIndexName="item"
        hasQuery={true}
    >
        <IndexSelector />
        <ConfigForm
            schema={{
                "type": "object",
                "properties": {
                    "query": {
                        "type": "string",
                    }
                }
            }}
            uiSchema={{
                "query": {
                    "ui:widget": "monacojson",
                    "height": "700px"
                }
            }}
            defaultFormData={() => ({
                query: JSON.stringify({
                    "aggs": {
                        "agg_filter": {
                            "filter": {
                                "bool": {
                                    "must": [{
                                        "terms": {
                                            "topic_group_set": ["04"]
                                        }
                                    }, {
                                        "terms": {
                                            "degree_group_values.keyword": ["BA"]
                                        }
                                    }]
                                }
                            },
                            "aggs": {
                                "topicDegreeCombs": {
                                    "multi_terms": {
                                        "terms": [{
                                            "field": "topic_group_set"
                                        }, {
                                            "field": "degree_group_values.keyword"
                                        }],
                                        size: 1000
                                    },
                                    "aggs": {
                                        "hits_agg": {
                                            "top_hits": {
                                                "_source": {
                                                    "includes": [
                                                        "title_value_de_CH",
                                                        "client_value",
                                                        "topic_group_set"]
                                                },
                                                "size": 100
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    "size": "0"
                }, null, 2)
            })}
            className="rjsf_QueryRawConfig"
            generate_query={generate_query}
        />
        <RunButtonQuery />
    </ConfigWrapper>
}