import Select from 'react-select';
import React from 'react';
import ClientProvider from '../../../../../Various/ClientProvider';

export default function XpathSelectAttr({ xpathAttr, setXpathAttr, insertCommand }) {
    const wsProvider = React.useContext(ClientProvider);

    const onChange = (result) => setXpathAttr(result["value"])

    const stati = [
        "innerText",
        "textContent",
        "default",
        "innerHTML",
        "outerHTML",
        "href",
        "src",
        "class"
    ]
    const options = stati.map(x => ({ "value": x, "label": x }))
    const optionsCommand = wsProvider.CrawlerTree.getFields(false)

    function Element({ command }) {
        const clickHandler = () => insertCommand(command, null)
        return (
            <button className="btn btn-default btn-sm" type="button" onClick={clickHandler} >
                Loop
            </button>)
    }
    function ElementExtractTable({ command, field }) {
        const clickHandler = () => insertCommand(command, field, "xpath_title")
        return (
            <button className="btn btn-default btn-sm" type="button" onClick={clickHandler} >
                ExtractTable
            </button>)
    }
    function Element2({ command, field }) {
        const clickHandler = () => insertCommand(command, field)
        return <li><a className="dropdown-item" onClick={clickHandler} command={command}>{field}</a></li>
    }

    return (
        <div>
            <Select
                className="reactSelect"
                value={{ value: xpathAttr, label: xpathAttr }}
                onChange={onChange}
                options={options}
            />
            <div className="dropdown">
                <Element command="loop" />
                <ElementExtractTable command="extractTable" field="table1" />
                <button className="btn btn-default dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    Extract
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    {optionsCommand.map(field => {
                        return <Element2 command="extract" field={field} />
                    })}
                </ul>
            </div>
        </div>
    )

}
