import ClientProvider from '../ClientProvider';
import React, { useState, useMemo } from 'react';
import ItemsTable from './ItemsTable_core';

export function ItemsTableCommand(props) {
  const wsProvider = React.useContext(ClientProvider);
  var [execSelected, setExecSelected] = wsProvider.useStateVar("crawler", "execSelected", null)
  var [sourceSelected, setSourceSelected] = wsProvider.useStateVar("crawler", "sourceSelected", {})
  var [activeColumn, setActiveColumn] = wsProvider.useStateVar("crawler", "activeColumn", 0)

  var [filterColumns, setFilterColumns] = wsProvider.useStateVar("command", "filterColumnsCommand", {})

  const clickHandler = (item, column, index, itemOrig) => {
    setExecSelected(item["#exec"])
    setSourceSelected(itemOrig["#source"])
    setActiveColumn(column)
    if (props.clickHandler)
      props.clickHandler(item, column, index)
  }

  const triggerNewOrder = (sortedRowsPreped, rowsSorted) => {
    if (wsProvider.loadFirstTableItem === false)
      return

    wsProvider.setLoadFirstTableItem(false)

    const index = 0
    if (sortedRowsPreped.length && rowsSorted.length) {
      wsProvider.setStateVar("crawler", "execSelected", sortedRowsPreped[index]["#exec"])
      wsProvider.setStateVar("crawler", "sourceSelected", rowsSorted[index]["#source"])
    }
  }

  var useTableData = props.tableData
  let textProcessorFilterErrorInfoNoExt = wsProvider.getStateVar("command", "textProcessorFilterErrorInfoNoExt")
  let command = wsProvider.CommandsTree.getAttr("className")
  let field = wsProvider.CommandsTree.getAttr("title")

  if (props.groupByField) {
    let useTableDataNew = []
    let useTableDataOnce = []
    useTableData.forEach(item => {
      if (useTableDataOnce.includes(item[field]))
        return
      useTableDataOnce.push(item[field])
      useTableDataNew.push(item)
    })
    useTableData = useTableDataNew
  }

  if (command == "textProcessor") {
    if (textProcessorFilterErrorInfoNoExt !== null) {
      switch (textProcessorFilterErrorInfoNoExt) {
        case "infos":
        case "errors":
          useTableData = useTableData.filter(x => x["s_" + textProcessorFilterErrorInfoNoExt].length > 0)
          break
        case "noExt":
          console.log(field)
          useTableData = useTableData.filter(x => x["s_flat"].length == 0 && x[field] != "")
      }
    }
  }

  return (
    <ItemsTable
      tableData={useTableData}
      mergeCustomFields={props.mergeCustomFields}
      extraClass={props.extraClass}
      clickHandler={clickHandler}
      hideColumns={props.hideColumns}
      columnsSettings={props.columnsSettings}
      sortColumns={props.sortColumns}
      setSortColumns={props.setSortColumns}
      wsProvider={wsProvider}
      time={wsProvider.CommandsTree.getStatsTime()}
      showAll={props.showAll}
      execSelected={props.execSelected}
      filterColumns={filterColumns}
      setFilterColumns={setFilterColumns}
      triggerNewOrder={triggerNewOrder}
    />
  )
}

export function ItemsTableOther(props) {
  const wsProvider = React.useContext(ClientProvider);

  const clickHandler = (item, column, index) => {
    wsProvider.toLog(item[column], true)
  }

  return (
    <ItemsTable
      tableData={props.tableData}
      mergeCustomFields={props.mergeCustomFields}
      extraClass={props.extraClass}
      clickHandler={clickHandler}
      hideColumns={props.hideColumns}
      columnsSettings={props.columnsSettings}
      sortColumns={props.sortColumns}
      setSortColumns={props.setSortColumns}
      wsProvider={wsProvider}
      time={props.time}
      urlsStatus={props.urlsStatus}
      filterColumns={props.filterColumns}
      setFilterColumns={props.setFilterColumns}
      filterDefinition={props.filterDefinition}
      noMemo={props.noMemo}

    />
  )
}

export function ItemsTableDefault(props) {
  const wsProvider = React.useContext(ClientProvider);
  return (
    <ItemsTable
      tableData={props.tableData}
      mergeCustomFields={props.mergeCustomFields}
      extraClass={props.extraClass}
      clickHandler={props.clickHandler}
      hideColumns={props.hideColumns}
      hideColumnsContains={props.hideColumnsContains}
      columnsSettings={props.columnsSettings}
      sortColumns={props.sortColumns}
      setSortColumns={props.setSortColumns}
      wsProvider={wsProvider}
      time={props.time}
      urlsStatus={props.urlsStatus}
      useRowKeyGetter={props.useRowKeyGetter}
      filterColumns={props.filterColumns}
      setFilterColumns={props.setFilterColumns}
      filterDefinition={props.filterDefinition}
      autoSortColums={props.autoSortColums}
      groupedFields_default={props.groupedFields_default}
      refresh_key={props.refresh_key}
      noMemo={props.noMemo}

    />
  )
}