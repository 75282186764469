import FilterOutputPanel from './FilterOutputPanel'

export default function FilterTypeCondition({execAll, execAllArray, showAll, execSelected, commandKey}) {
    return (
        <FilterOutputPanel 
            execAll={execAll}
            execAllArray={execAllArray}
            showAll={showAll}
            execSelected={execSelected}
            commandKey={commandKey}
        />
    ) 
}