import React, { useState, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';
import ItemsTable from "../../Various/ItemsTable/ItemsTable_core";
import { ItemsTableDefault } from '../../Various/ItemsTable/ItemsTable';
import Form from '@rjsf/core';




export default function WorkspaceTopicsList() {
    const wsProvider = React.useContext(ClientProvider);
    const [schemaDefinition, setSchemaDefinition] = useState({
        schema: {
            "type": "object",
            "properties": {
                "topic_group_filters": {
                    "type": "array",
                    "title": "A multiple choices list",
                    "items": {
                        "type": "string",
                        "enum": [
                            "hide_MISSING",
                            "hide_STOPWORD",
                            "hide_OTHER"
                        ]
                    },
                    "uniqueItems": true
                },
                "load_all_clients": {
                    "type": "boolean",
                    "default": false
                },
            }
        },
        uiSchema: {
            "topic_group_filters": {
                "ui:widget": "checkboxes"
            },
        }
    })
    const [headerData, setHeaderData] = useState({
        "topic_group_filters": ["hide_STOPWORD"],
        "load_all_clients": false
    })


    const updateHeaderData = ({ formData }) => {
        setHeaderData(formData)
    }


    return (
        <div className="row">
            <div className="col-12 workspaceTop">

                <div className='flex_header'>
                    <div className='header'>
                        <div className='row'>
                            <div className='col-6'>
                                <Form
                                    className="FormStatisticDiffHeader"
                                    schema={schemaDefinition["schema"]}
                                    uiSchema={schemaDefinition["uiSchema"]}
                                    formData={headerData}
                                    onChange={updateHeaderData}
                                >
                                    <button className="btn btn-info" style={{ "display": "none" }}>Load</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='row height100 '>
                            <div className='col-6'>
                                <ListSide withRules={false} headerData={headerData} side="left" />
                            </div>
                            <div className='col-6'>
                                <ListSide withRules={true} headerData={headerData} side="right" />
                            </div>
                        </div >
                    </div>
                </div >
            </div>
        </div >
    )
}

function ListSide({ withRules, headerData, side }) {
    const wsProvider = React.useContext(ClientProvider);

    const [sortColumns, setSortColumns] = useState([
        {
            "columnKey": "#",
            "direction": "ASC"
        }
    ])
    const [tableData, setTableData] = useState([])
    var [filterColumns, setFilterColumns] = useState({})

    useEffect(() => {
        loadTopicGroups()
    }, [headerData.load_all_clients])

    const loadTopicGroups = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "get_file_TopicsList",
            options: {
                client: wsProvider.clientSelected,
                project_id: wsProvider.selectedProjectId,
                load_all_clients: headerData.load_all_clients,
                withRules: withRules
            }
        }, (response) => {
            setTableData(response["data"]["items"])
        })

    }

    const clickHandler = (item, column, index, itemOrig) => {

        /*
        let topicCategories = {}
        let topics = []
        Object.keys(itemOrig['topics']).forEach((topic) => {

            Object.keys(itemOrig['topics'][topic]['ecedFullV2_levelall_counts']).forEach((category) => {

                if (category === "0") {
                    return
                }

                if (!topicCategories[category]) {
                    let title = "All"
                    if (categories[category]) {
                        title = categories[category]['title']
                    }
                    topicCategories[category] = { "title": title, "key": category, "count": 0, }
                }
                topicCategories[category]["count"] += itemOrig['topics'][topic]['ecedFullV2_levelall_counts'][category]
            })

            topics.push({
                "topic": topic,
                "count": itemOrig['topics'][topic]['count'],
            })
        })
        setSelectedTopicGroup({
            "categories": topicCategories,
            "topics": topics,
            "candidate_title": itemOrig['candidate_title'],
            "candidate_categories": itemOrig['candidate_categories'],
        })
        */
    }

    let tableDataFiltered = [...tableData]
    if (headerData.topic_group_filters.includes("hide_MISSING")) {
        tableDataFiltered = tableDataFiltered.filter(item => item["topic_group_label"] !== "MISSING")
    }
    if (headerData.topic_group_filters.includes("hide_STOPWORD")) {
        tableDataFiltered = tableDataFiltered.filter(item => item["topic_group_label"] !== "STOPWORD")
    }
    if (headerData.topic_group_filters.includes("hide_OTHER")) {
        tableDataFiltered = tableDataFiltered.filter(item => item["topic_group_label"] === "STOPWORD" || item["topic_group_label"] === "MISSING")
    }


    return (
        <>
            <ItemsTableDefault
                tableData={tableDataFiltered}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                hideColumns={[]}
                clickHandler={clickHandler}
                filterColumns={filterColumns}
                setFilterColumns={setFilterColumns}
                groupedFields_default={["title_value_de_CH", "topic", "topic_group_label"]}

            />
        </>
    )
}