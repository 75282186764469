export default function StdOutLogElement ({output}) {
    if (typeof(output)=="string") {
        output = [output]
    }
    const results = output.map((value) =>
        <div className="stdOutLogElement">
            {value}
        </div>
    );

    return (
        <div>
            {results}
        </div>
    );
} 