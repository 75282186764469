//import {useState} from "react";
//import ItemsTable from '../../../ItemsTable/ItemsTable'

import { Processor, ErrorWorkspace, JSONTabTabPanel } from "./TextProcessor"
import React from 'react';
import ReactJson from 'react-json-view'
import ClientProvider from '../../../Various/ClientProvider';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export default function ValueToField(props) {
    const wsProvider = React.useContext(ClientProvider);
    const CommandsTree = props["CommandsTree"]

    const field = CommandsTree.getAttr("title")


    const outputFields = [
        "title",
        "url",
        field,
        field + "errors",

    ]

    const clickHandler = (item, column, index) => {
    }

    props = {
        ...props,
        field: field,
        outputFields: outputFields,
        ProcessorDetails: ValueToFieldDetails,
        clickHandler: clickHandler,
        command: "valueToField"
    }

    return Processor(props)
}

const ValueToFieldDetails = ({ row, activeItem, field }) => {
    if (row)
        return (
            <div className="processorDetails">
                <div className="elem1-0">
                    <h4>{row["title"]}</h4>
                    <p>
                        <a href={row["url"]} target="_blank">
                            {row["url"]}
                        </a>
                    </p>
                </div>
                <div className="elem2-0">
                    <Tabs>
                        <TabList>
                            {row[field + "errors"].length > 0 &&
                                <Tab>Error ({row[field + "errors"].length})</Tab>
                            }
                            <Tab>Raw</Tab>
                        </TabList>
                        {row[field + "errors"].length > 0 &&
                            <TabPanel>
                                {Array.isArray(row[field + "errors"]) &&
                                    (
                                        <>
                                            <ErrorWorkspace data={row[field + "errors"]} />
                                        </>
                                    )
                                }
                            </TabPanel>
                        }
                        <TabPanel>
                            <JSONTabTabPanel data={row[field]} />
                        </TabPanel>
                    </Tabs>
                </div>

            </div>
        )
} 