import Iframe from './load/IFrame/Iframe'
import ReactJson from 'react-json-view'

export default function Extract({ execAll, showAll, execAllArray, execSelected, commandKey }) {
    const renderSingle = (execItem, index) => {
        const yourVariable = execItem["out"][0]
        if (
            typeof yourVariable === 'object' &&
            !Array.isArray(yourVariable) &&
            yourVariable !== null
        ) {
            return (
                <ReactJson
                    src={execItem["out"][0]}
                    theme="monokai"
                    indentWidth={2}
                    displayDataTypes={false}
                />
            )
            //
        } else if (Array.isArray(yourVariable)) {
            return (
                <ReactJson
                    src={execItem["out"][0]}
                    theme="monokai"
                    indentWidth={2}
                    displayDataTypes={false}
                />
            )
        } else {
            return (
                <>
                    {execItem["out"][0]}<br />
                </>
            )
        }
    }
    if (showAll !== true) {
        return renderSingle(execAll[execSelected], execSelected)
    } else {
        return execAllArray.slice(0, execAllArray.length < 20 ? execAllArray.length : 20).map((execItem, index) => renderSingle(execItem, index));
    }
}