export default function generateTreeData(title, tableData, options) {
    console.log(tableData)
    switch (title) {
        case "breadcrumbs":
        case "title":
        case "url_linkTag":
        case "url_source":
            return getTreeDataFromText(tableData, title, options)
        case "url_split":
            return getTreeDataFromText(tableData.map(x => ({ ...x, url_split: x["url"].replace("https://", "").replace("http://", "").replace(".html", "").replace(/\//g, "\n") })), "url_split", options)
    }
}

const getTreeDataFromText = (tableData, title, options) => {
    var result = {
        "title": "root",
        "children": []
    }
    tableData.forEach(row => {
        if (row[title] !== undefined) {
            let splitArray = row[title].split("\n")
            console.log(splitArray)
            var ref = result
            var nodeKeys = []
            var level = -1
            splitArray.forEach(line => {
                level = level + 1
                let thisitem = ref["children"].find(x => x["title_raw"] === line)
                nodeKeys.push(line)
                if (thisitem === undefined) {
                    thisitem = {
                        "title_raw": line,
                        "isLeaf": false,
                        "children": [],
                        "count": 1,
                        "level": level,
                        "nodeKey": nodeKeys.join("__"),
                    }
                    if (options !== undefined && "addNodeKeyToKey" in options && options["addNodeKeyToKey"] === true) {
                        thisitem["key"] = thisitem["nodeKey"]
                    }
                    ref["children"].push(thisitem)
                } else {
                    thisitem["count"] = thisitem["count"] + 1
                }
                thisitem["title"] = thisitem["title_raw"] + " (" + thisitem["count"] + ")"

                ref = thisitem
            })
            ref.children.push({
                "title": row["title"],
                "isLeaf": true,
                "key": row["#"],
                "row": row,
                "level": level,
                "checkable": options !== undefined && "leafCheckable" in options && options["leafCheckable"] === false ? false : true
            })
        }
    });
    return result["children"]
}