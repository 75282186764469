import React, { useState, useEffect } from 'react';
import ClientProvider from '../../Various/ClientProvider';


import ItemsTable from "../../Various/ItemsTable/ItemsTable_core";
import { ItemsTableDefault } from '../../Various/ItemsTable/ItemsTable';
import Form from '@rjsf/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';




export default function WorkspaceTopicsCombs() {
    const wsProvider = React.useContext(ClientProvider);
    const [waitingForBatchJobsDone, setWaitingForBatchJobsDone] = useState([])
    const [lastUpdate, setLastUpdate] = useState(null)
    const [itemActiveGlobal, setItemActiveGlobal] = useState(null)
    const [sortColumns_rules, setSortColumns_rules] = useState([])
    var [filterColumns_rules, setFilterColumns_rules] = useState({})
    const [activeTab, setActiveTab] = useState(0)
    const [tableData_ruleStats, setTableData_ruleStats] = useState({ items: [], rules_counts: {} })

    const headerSchemaDefinition = {
        schema: {
            "type": "object",
            "properties": {
                "load_all_clients": {
                    "type": "boolean",
                    "default": false
                }
            }
        },
        uiSchema: {

        }
    }

    const schemaSingleRule = {
        "type": "object",
        "properties": {
            rule_name: { "type": "string", enum: ["remove_1", "remove_2", "allow", "alias_1_to_2", "alias_2_to_1", "decide_1", "decide_1_or_2", "replace_1", "replace_2", "remove_1_if_has_other_else_remove_2", "remove_2_if_has_other_else_remove_1", "final"] },
            topic_group_str_1: { "type": "string" },
            topic_group_str_2: { "type": "string" },
        },
        dependencies: {
            rule_name: {
                oneOf: [
                    {
                        properties: {
                            rule_name: {
                                enum: [
                                    'replace_1', 'replace_2'
                                ]
                            },
                            topic_group_str_replace_with: {
                                type: 'string',
                            }
                        },
                        required: ["topic_group_str_replace_with"]

                    },
                    {
                        properties: {
                            rule_name: {
                                enum: [
                                    "remove_1_if_has_other_else_remove_2", "remove_2_if_has_other_else_remove_1"
                                ]
                            },
                            topic_group_str_other_regex: {
                                type: 'string',
                            }
                        },
                        required: ["topic_group_str_other_regex"]

                    },
                    {
                        properties: {
                            rule_name: {
                                enum: [
                                    "remove_1", "remove_2", "allow", "alias_1_to_2", "alias_2_to_1", "decide_1", "decide_1_or_2", "final"
                                ]
                            }
                        }
                    }
                ]
            }
        },
        required: ["rule_name", "topic_group_str_1", "topic_group_str_2"]
    }

    const insertRuleSchemaDefinition = {
        schema: schemaSingleRule,
        uiSchema: {

        }
    }

    const rulesSchemaDefinition = {
        schema: {
            "type": "object",
            "properties": {
                "rules": {
                    "type": "array",
                    "items": {
                        "$ref": "#/definitions/rule"
                    }

                },
            },
            definitions: {
                rule: schemaSingleRule
            }
        },
        uiSchema: {

        }
    }

    const [headerData, setHeaderData] = useState({
        "load_all_clients": false
    })

    const updateHeaderData = ({ formData }) => {
        setHeaderData(formData)
    }

    const [rulesData, setRulesData] = useState({
        rules: []
    })
    const [newRuleData, setNewRuleData] = useState({

    })
    const [filterRuleData, setFilterRuleData] = useState(null)



    const updateRulesData = ({ formData }) => {
        setRulesData(formData)
    }

    const updateNewRuleData = ({ formData }) => {
        setNewRuleData(formData)
    }

    const insertNewRuleData = () => {
        setRulesData(x => ({
            ...x,
            rules: [...x.rules,
                newRuleData
            ]
        }))
        setNewRuleData({})
    }

    const topic_system_combine_stats = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "topic_system_combine_stats",
            options: {
                project_id: wsProvider.selectedProjectId
            }
        }, (response) => {

        })
    }
    const runTopicGroupsAddCommandSet = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "update_file_TopicsCombsRules",
            options: {
                "project_id": wsProvider.selectedProjectId,
                "data": rulesData
            }
        }, (response) => {
            let projectPathSelector
            if (headerData.load_all_clients) {
                projectPathSelector = {
                    "project": wsProvider.selectedProjectId,
                    "selectionType": "all_by_project_path",
                    "selectionValue": "live",
                    "maxClients": 0
                }
            } else {
                projectPathSelector = {
                    "project": wsProvider.selectedProjectId,
                    "selectionType": "single",
                    "selectionValue": wsProvider.clientSelected,
                    "maxClients": 0
                }
            }
            wsProvider.ws.waitForSocketAndSend({
                action: "batchJobInit",
                options: {
                    "batchJobName": "edufind.ClientsRunEdufind",
                    "batchJobOptions": {
                        "engine": "awslambda",
                        "projectPathSelector": projectPathSelector,
                        "runTopicGroupsAddCommandSet": true,
                        "topicGroupsAdd_stats": true,
                    }
                }
            }, (data) => {
                setWaitingForBatchJobsDone(x => [...x, data["batchJobId"]])
            })
        })

    }

    const handleBatchJobUpdate = (data) => {
        if (!waitingForBatchJobsDone.includes(data["batchJobId"]))
            return

        switch (data["level"]) {
            case "batchJob":
                if (data.stats.status === "SUCCEEDED") {
                    setLastUpdate(data.time)
                }
                break
            case "batchJobItem":
                break
            case "batchJobItemCommand":
                break
        }
    }

    const clickHandler_rules = (item, column, index, itemOrig, columnIndex) => {
        const filterRuleItem = { ...item }
        delete filterRuleItem["added_count"]
        delete filterRuleItem["removed_count"]
        setFilterRuleData(JSON.stringify(filterRuleItem))
        setNewRuleData(item)
        if (column === "delete") {
            setRulesData(rulesData => ({ ...rulesData, rules: rulesData.rules.filter(rule => JSON.stringify(rule) !== JSON.stringify(itemOrig)) }))
        }
    }

    useEffect(() => {
        // Register Callback
        // Return is the cleanup function
        return wsProvider.registerBatchJobUpdateHandler("TopcisComboRefresh", handleBatchJobUpdate)
    }, [JSON.stringify(waitingForBatchJobsDone)])

    useEffect(() => {
        // Load Rules
        wsProvider.ws.waitForSocketAndSend({
            action: "get_file_TopicsCombsRules",
            options: {
                "project_id": wsProvider.selectedProjectId
            }
        }, (response) => {
            setRulesData(response.data)
        })
    }, [])

    useEffect(() => {
        // Load Rules
        if (!itemActiveGlobal)
            return
        setNewRuleData(x => ({
            ...x,
            topic_group_str_1: itemActiveGlobal.item.comb.split("___")[0],
            topic_group_str_2: itemActiveGlobal.item.comb.split("___")[1],
            rule_name: schemaSingleRule.properties.rule_name.enum[itemActiveGlobal.columnIndex]

        }))
    }, [JSON.stringify(itemActiveGlobal)])

    const rules_counts = tableData_ruleStats.rules_counts


    const rulesData_withCount = rulesData.rules.map(item => {
        const hash = JSON.stringify(item)
        if (rules_counts && rules_counts[hash]) {
            return {
                ...item,
                added_count: rules_counts[hash].added,
                removed_count: rules_counts[hash].removed
            }
        }
        return item
    })


    return (
        <div className="row">
            <div className="col-12 workspaceTop workspaceTopicsCombs">

                <div className='flex_header' style={{ height: "calc(100% - 50px)" }}>
                    <div className='header'>
                        <div className='row' style={{ marginBottom: "10px" }}>

                            <div className='col-6'>
                                <Form
                                    className=""
                                    schema={headerSchemaDefinition["schema"]}
                                    uiSchema={headerSchemaDefinition["uiSchema"]}
                                    formData={headerData}
                                    onChange={updateHeaderData}
                                >
                                    <button className="btn btn-info" style={{ "display": "none" }}>Load</button>
                                </Form>
                            </div>
                            <div className='col-6'>
                                <div style={{ "float": "right", "marginRight": "3px" }}>
                                    <button
                                        onClick={runTopicGroupsAddCommandSet}
                                        className="btn btn-primary btn-sm"
                                        title='runTopicGroupsAddCommandSet'
                                    >
                                        <i className="bi bi-play-circle"></i>
                                    </button>
                                </div>
                                <div style={{ "float": "right", "marginRight": "3px" }}>
                                    <button
                                        onClick={topic_system_combine_stats}
                                        className="btn btn-primary btn-sm"
                                        title='topic_system/combine_stats'
                                    >
                                        <i className="bi bi-gear"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='row height100 '>
                            {/* 
                            <div className='col-4'>
                                <CombsSide withRules={false} headerData={headerData} lastUpdate={lastUpdate} rulesData={rulesData} setItemActiveGlobal={setItemActiveGlobal} side={"left"} />

                            </div>
                            */}
                            <div className='col-6'>
                                <div className="insertItem">
                                    {
                                        <Form
                                            className="FormTopicsNewRule"
                                            schema={insertRuleSchemaDefinition["schema"]}
                                            uiSchema={insertRuleSchemaDefinition["uiSchema"]}
                                            formData={newRuleData}
                                            onChange={updateNewRuleData}
                                            onSubmit={insertNewRuleData}
                                            omitExtraData={true}
                                        >
                                            <button className="btn btn-info">Insert</button>
                                        </Form>
                                    }
                                </div>
                                {/*
                                <Form
                                    className="FormTopicsRules"
                                    schema={rulesSchemaDefinition["schema"]}
                                    uiSchema={rulesSchemaDefinition["uiSchema"]}
                                    formData={rulesData}
                                    onChange={updateRulesData}
                                >
                                    <button className="btn btn-info" style={{ "display": "none" }}>Load</button>
                                </Form>
                                 */}

                                <div style={{ height: "calc(100% - 60px)" }}>
                                    <ItemsTableDefault
                                        tableData={rulesData_withCount} //rulesData.rules.map(item => ({ ...item, delete: "[x]" }))
                                        sortColumns={sortColumns_rules}
                                        setSortColumns={setSortColumns_rules}
                                        hideColumns={[]}
                                        clickHandler={clickHandler_rules}
                                        filterColumns={filterColumns_rules}
                                        setFilterColumns={setFilterColumns_rules}
                                        groupedFields_default={[]}
                                        columnsSettings={{
                                            "rule_name": {
                                                width: 110,
                                            },
                                            "topic_group_str_1": {},
                                            "topic_group_str_2": {},
                                            "added_count": {},
                                            "removed_count": {},
                                            "topic_group_str_replace_with": {
                                            },
                                            "topic_group_str_other_regex": {
                                            },
                                            "delete": {
                                                width: 30,
                                            },

                                        }}

                                    />


                                </div>
                            </div>
                            <div className='col-6'>
                                <Tabs
                                    selectedIndex={activeTab}
                                    onSelect={setActiveTab}
                                    className="tabsTopicCombs"
                                >

                                    <TabList>
                                        <Tab>Combs</Tab>
                                        <Tab>Rule Stats</Tab>
                                    </TabList>

                                    <TabPanel className="workspaceTabPanel">
                                        <CombsSide withRules={true} headerData={headerData} lastUpdate={lastUpdate} rulesData={rulesData} setItemActiveGlobal={setItemActiveGlobal} side={"right"} />
                                    </TabPanel>
                                    <TabPanel className="workspaceTabPanel">
                                        <RuleStats
                                            headerData={headerData}
                                            rulesData={rulesData}
                                            lastUpdate={lastUpdate}
                                            setItemActiveGlobal={setItemActiveGlobal}
                                            filterRuleData={filterRuleData}
                                            tableData_ruleStats={tableData_ruleStats}
                                            setTableData_ruleStats={setTableData_ruleStats}
                                        />
                                    </TabPanel>
                                </Tabs>

                            </div>
                        </div >
                    </div>
                </div >
            </div>
        </div >
    )
}

function RuleStats({ headerData, rulesData, setItemActiveGlobal, lastUpdate, filterRuleData, tableData_ruleStats, setTableData_ruleStats }) {
    const wsProvider = React.useContext(ClientProvider);
    const tableData = tableData_ruleStats.items

    const [sortColumns, setSortColumns] = useState([
        {
            "columnKey": "count",
            "direction": "DESC"
        }
    ])
    const [itemActive, setItemActive] = useState(null)

    var [filterColumns, setFilterColumns] = useState({})

    useEffect(() => {
        load()
    }, [headerData.load_all_clients, lastUpdate])

    useEffect(() => {
        setFilterColumns({
            "rule": filterRuleData
        })
    }, [filterRuleData])

    const clickHandler = (item, column, index, itemOrig, columnIndex) => {
        return
        const itemActiveNew = {
            index: tableData.findIndex(x => x["comb"] === item.comb),
            item: tableData.find(x => x["comb"] === item.comb),
            columnIndex
        }
        setItemActive(itemActiveNew)
        setItemActiveGlobal(itemActiveNew)

    }

    const load = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "get_file_TopicsRuleStats",
            options: {
                project_id: wsProvider.selectedProjectId,
                client: wsProvider.clientSelected,
                load_all_clients: headerData.load_all_clients
            }
        }, (response) => {
            if (response["data"]) {
                setTableData_ruleStats(response["data"])
            }
        })

    }

    let tableDataFiltered = [...tableData].map(item => ({
        "#": item["#"],
        "title": item.title_value_de_CH,
        "rule_name": item.rule_definition.rule_name,
        "rule": JSON.stringify(item.rule_definition),
        "added": item.added.join(" | "),
        // "added_count": item.added.length,
        "removed": item.removed.join(" | "),
        "removed_count": item.removed.length,
        "remains": item.remains.join(" | "),
    }))

    return (
        <div style={{ height: "calc(100%)", marginTop: "34px" }}>
            <ItemsTableDefault
                tableData={tableDataFiltered}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                hideColumns={["rule_definition"]}
                clickHandler={clickHandler}
                filterColumns={filterColumns}
                setFilterColumns={setFilterColumns}
                groupedFields_default={["title", "rule"]}
                columnsSettings={{
                    "#": {
                        width: 30,
                    },
                    "title": {},
                    "rule_name": {
                        width: 100,
                    },
                    "rule": {
                        width: 30,
                    },
                    "added": {},
                    "removed": {},
                    "removed_count": {
                        width: 30,
                    },
                    "remains": {},
                }}

            />
        </div>
    )
}

function CombsSide({ withRules, headerData, lastUpdate, rulesData, setItemActiveGlobal, side }) {

    const settingsDefinition = {
        schema: {
            "type": "object",
            "properties": {
                "hide_allow": {
                    "type": "boolean",
                    "default": true
                },
                "show_all_topics": {
                    "type": "boolean",
                    "default": false
                }
            }
        },
        uiSchema: {

        }
    }

    const [settingsData, setSettingsData] = useState({
        hide_allow: true,
        show_all_topics: false,
    })

    const updateSettingsData = ({ formData }) => {
        setSettingsData(formData)
    }

    const wsProvider = React.useContext(ClientProvider);

    const [sortColumns, setSortColumns] = useState([
        {
            "columnKey": "count",
            "direction": "DESC"
        }
    ])
    const [sortColumns2, setSortColumns2] = useState([])
    const [itemActive, setItemActive] = useState(null)

    const [tableData, setTableData] = useState([])
    var [filterColumns, setFilterColumns] = useState({})
    var [filterColumns2, setFilterColumns2] = useState({})

    useEffect(() => {
        loadTopicCombs()
    }, [headerData.load_all_clients, lastUpdate])

    const clickHandler = (item, column, index, itemOrig, columnIndex) => {
        const itemActiveNew = {
            index: tableData.findIndex(x => x["comb"] === item.comb),
            item: tableData.find(x => x["comb"] === item.comb),
            columnIndex
        }
        setItemActive(itemActiveNew)
        setItemActiveGlobal(itemActiveNew)

    }

    const loadTopicCombs = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "get_file_TopicsCombs",
            options: {
                project_id: wsProvider.selectedProjectId,
                client: wsProvider.clientSelected,
                load_all_clients: headerData.load_all_clients,
                withRules: withRules
            }
        }, (response) => {
            setTableData(response["data"]["items"])
        })

    }

    let tableDataFiltered = [...tableData]

    if (settingsData.hide_allow && rulesData) {
        rulesData.rules.filter(rule => rule.rule_name).forEach((rule) => {
            tableDataFiltered = tableDataFiltered.filter((item) => {
                const splitted = item.comb.split("___")
                if (splitted[0].startsWith(rule.topic_group_str_1) && splitted[1].startsWith(rule.topic_group_str_2)) {
                    return false
                }
                return true
            })
        })

    }
    /*
        const all_courses = []
        tableDataFiltered.forEach(item=>{
    
            return 
        })
    */
    return (
        <>
            <Form
                className="FormTopicsSettings"
                schema={settingsDefinition["schema"]}
                uiSchema={settingsDefinition["uiSchema"]}
                formData={settingsData}
                onChange={updateSettingsData}
            >
                <button className="btn btn-info" style={{ "display": "none" }}>Load</button>
            </Form>
            <div style={{ height: "70%" }}>
                <ItemsTableDefault
                    tableData={tableDataFiltered}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    hideColumns={["comb", "items"]}
                    clickHandler={clickHandler}
                    filterColumns={filterColumns}
                    setFilterColumns={setFilterColumns}
                    groupedFields_default={[]}
                    columnsSettings={{
                        "topic_group_str_1": {},
                        "topic_group_str_2": {},
                        "count": { width: 30 },

                    }}

                />
            </div>
            <div style={{ height: "calc(30% - 10px)", marginTop: "10px" }}>

                {itemActive &&
                    <ItemsTableDefault
                        key={itemActive["index"]}
                        tableData={settingsData.show_all_topics ? tableDataFiltered.map(item => item["items"]).flat() : itemActive["item"]["items"]}
                        sortColumns={sortColumns2}
                        setSortColumns={setSortColumns2}
                        hideColumns={["url_value"]}
                        filterColumns={filterColumns2}
                        setFilterColumns={setFilterColumns2}
                        groupedFields_default={["title_value_de_CH"]}
                        columnsSettings={{
                            "title_value_de_CH": {},
                            "client_value": {
                                width: 100,
                            },
                            "topics": {}
                        }}

                    />
                }
            </div>
        </>
    )
}