import React, {useState, useEffect} from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';


const SortableItem = SortableElement(({obj, index2, items, setItems}) => {

    const changeHandler = (e) => {
        let newItems = [...items]
        newItems[index2]={...obj, value: e.target.value}
        setItems(newItems)
    }

    const deleteHandler = (e) => {
        let newItems = [...items]
        newItems.splice(index2, 1);
        setItems(newItems)
    }

    return (
        <div className="xpathItem">
            <div><span className="btn btn-primary btn-sm" tabIndex="-1"><i className="bi bi-arrows-move"></i></span></div>
            <div><button onClick={deleteHandler} href="#" className="btn btn-primary btn-sm" tabIndex="-1"><i className="bi bi-trash"></i></button></div>
            <div><input value={obj.value} style={{ width: (obj.value.length+2)*0.85 +'ch'}} onChange={changeHandler}/></div>
        </div>
    )
}) 

const SortableList = SortableContainer(({items, setItems}) => {
    const addHandler = (e) => {
        setItems([...items, {
            value: "/"
        }])
    }

  return (
    <div>
      {items.map((obj, index) => (
        <SortableItem 
            key={`item-${index}`} 
            index={index} 
            index2={index} 
            obj={obj} 
            items={items}
            setItems={setItems}
        />
      ))}
        <div className="xpathItem xpathItemAdd">
            <div><button className="btn btn-primary btn-sm" onClick={addHandler}><i className="bi bi-plus"></i></button></div>
        </div>
    </div>
  );
});

export default function XpathConfigurator ({setXpath, items, setItems}) {

    useEffect(() => {
        //Load Clients-List on Startup
        let value = ''
        items.forEach(x => {value = value.concat(x.value)})
        setXpath(value)
      }, [items]);
    

    const onSortEnd = ({oldIndex, newIndex}) => {
        setItems(value => arrayMoveImmutable(value, oldIndex, newIndex));
      };
    
    return (
        <>
        <SortableList 
            items={items} 
            axis="x"
            onSortEnd={onSortEnd}
            setItems={setItems}
        />
        </>
    )
}