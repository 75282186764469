import React, { useState } from 'react';
import { ItemsTableDefault } from '../../Various/ItemsTable/ItemsTable'
import WorkspaceCommandBottom from '../Command/WorkspaceCommandBottom'
import ClientProvider from '../../Various/ClientProvider';
import { CommandsData } from '../Command/CommandExec/_commands.js';
import ItemsTableConsoleObjs from '../../Various/ItemsTable/ItemsTableConsoleObjs'
import ItemsTableConsolePlain from '../../Various/ItemsTable/ItemsTableConsolePlain.js';

export default function WorkspaceItems() {
  console.log("WorkspaceItems Rerendered")
  const wsProvider = React.useContext(ClientProvider);
  var [execSelected, setExecSelected] = wsProvider.useStateVar("crawler", "execSelected", null)

  var [sourceSelected, setSourceSelected] = wsProvider.useStateVar("crawler", "sourceSelected", {})
  const [workspaceTabs, setWorkspaceTabs] = wsProvider.useStateVarGlobal("workspaceTabs")
  const [filterColumns, setFilterColumns] = wsProvider.useStateVar("crawler", "filterColumnsItems", {})


  const [value, setValue] = useState(0); // integer state
  const updateWorkspaceItems = () => setValue(value => value + 1)
  wsProvider.updateWorkspaceItems = updateWorkspaceItems

  var [showDetailsSide, setShowDetailsSide] = wsProvider.useStateVar("crawler", "showDetailsSide", null)

  const clickHandler = (item, column, index, itemOrig) => {
    setSourceSelected(itemOrig["#source"])
    setExecSelected(null)

    if (column !== "url") {
      wsProvider.setStateVar("crawler", "showAll", false)
      wsProvider.setStateVar("command", "showAll", false)
      const matchingCommand = wsProvider.CommandsTree.findCommandRec(x => (x["title"] === column && x["active"] !== false && CommandsData[x["className"]]["hasItemField"] === true))

      if (matchingCommand) {
        wsProvider.CommandsTree.goToCommand(matchingCommand["key"])
        setWorkspaceTabs(1) //Maked no sense at the moment
        setShowDetailsSide(null)
      } else {
        setShowDetailsSide(itemOrig[column])
      }
    }
    //Select command automatically
    //column
  }

  const [sortColumns, setSortColumns] = wsProvider.useStateVar("crawler", "sortColumns", [])
  const items = wsProvider.CrawlerTree.getItems()
  return (
    <div className="row">
      <div className={"col-12 workspaceTop"}>
        <div className="row">
          <div className={"col-" + (showDetailsSide === null ? "12" : "10") + " workspaceTop"}>
            {(items !== null && items.length > 0) &&
              <ItemsTableDefault
                tableData={items}
                clickHandler={clickHandler}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                mergeCustomFields={true}
                time={"notNeededSoFar"}
                hideColumns={["#source"]}
                hideColumnsContains={["_class"]}
                filterColumns={filterColumns}
                setFilterColumns={setFilterColumns}
                autoSortColums={true}
              />
            }
            {(items !== null && items.length === 0) &&
              <>
                No Items...
              </>
            }
            {items === null &&
              <>
                Loading...
              </>
            }
          </div>
          {showDetailsSide !== null && (
            <div className={"col-2 workspaceDetailsSide"}>
              <span onClick={() => setShowDetailsSide(null)} style={{ "cursor": "pointer", "float": "right", "zIndex": 1, "position": "relative" }}>
                [X]
              </span>
              <ShowDetailsSide content={showDetailsSide} />
            </div>
          )}
        </div>
      </div>
      <div className="col-12 workspaceBottom">
        {wsProvider.CommandsTree.getAttr("key") && wsProvider.CommandsTree.getAttr("className") &&
          <WorkspaceCommandBottom key={wsProvider.CommandsTree.getAttr("key") + wsProvider.CommandsTree.getAttr("className") + wsProvider.CommandsTree.getAttr("title")} />
        }
      </div>
    </div>
  )
}

const ShowDetailsSide = ({ content }) => {
  if (content !== null && typeof content === 'object') {
    return (
      <ItemsTableConsoleObjs data={content} />
    )
  } else {
    return (
      <ItemsTableConsolePlain data={content} />
    )
  }
}