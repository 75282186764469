import Select from 'react-select';

export function BaseDropdown ({status, setStatus, stati, className}) {
    const onChange = (result) => setStatus(result["value"])

    const options = stati.map(x => ({"value": x["value"], "label": x["label"]}))

    const value = {
        value: status, 
        label: stati.find(x=>x["value"]===status)["label"]
    }

    return (
        <Select
            className={"reactSelect " + (className!==undefined ? className : "") + " status_" + value["label"]}
            value={value}
            onChange={onChange}
            options={options}
        />
    )
}