const scopes = {
  command: {
    name: "command"
  },
  crawler: {
    name: "crawler"
  },
  client: {
    name: "client"
  },
  global: {
    name: "global"
  }
}



export default function getWordsLibConfig(options) {
  const baseFields = options.baseFields
  const fieldNamesList = Object.keys(baseFields)

  const widgetMapping = {
    "Cluster": {
      widget: "ecedFullSelectWidget"
    },
    "Priceperunit": {
      widget: "SelectWidget",
      enum: [
        "per_lesson",
        "per_halfday",
        "per_day",
        "per_month",
        "per_triester",
        "per_semester",
        "per_year",
        "per_course",
        "per_module",
        "per_block",
      ]
    }
  }

  if (options.type === "understand") {
    return {
      name: "understand",
      scopes: [
        scopes["client"],
        scopes["global"],
        scopes["crawler"],
      ],
      filterSchema: {
        cat: {
          enum: [
            'Applyuntil',
            'Careeroption',
            'Cluster',
            'Cat0',
            'Degree',
            'Discount',
            'Extrasoption',
            'Eventtype',
            'Fees',
            'Fullvaluedate',
            'Fullvalueprice',
            'Fullybooked',
            'Groupsizeoption',
            'Ignoredate',
            'Ignoreprice',
            'Intensityoption',
            'LangLangcourse',
            'Lengthoption',
            //'Lessons',
            'Level',
            'Location',
            'Module',
            'Persongroupoption',
            'Priceperunit',
            'Startorderoption',
            'Studymode',
            //'Timetable'
          ],
          title: 'Cat',
          type: 'string'
        },
        subtype: {
          enum: [
            'terms',
            'termsRegex'
          ],
          default: 'terms',
          title: 'Subtype',
          type: 'string'
        },
        lang: {
          enum: [
            'de_CH',
            'fr_CH',
            'en_CH',
            'it_CH'
          ],
          title: 'Language',
          type: 'string'
        }
      },
      schema: {
        type: 'object',
        title: 'understand',
        properties: {
          value: {
            title: 'Value',
            type: 'string'
          }
        },
        dependencies: {
          subtype: {
            oneOf: [
              {
                properties: {
                  subtype: {
                    enum: [
                      'terms'
                    ]
                  },
                  valueMatch: {
                    title: 'ValueMatch',
                    type: 'string',
                    enum: widgetMapping[options.cat]?.enum
                  }
                }
              },
              {
                properties: {
                  subtype: {
                    enum: [
                      'termsRegex'
                    ]
                  }
                }
              }
            ]
          }
        },
        required: []
      },
      uischema: {
        'ui:order': [
          'cat',
          'type',
          'subtype',
          'lang',
          'value',
          'valueMatch'
        ],
        "type": {
          'ui:widget': 'hidden',
        },
        "subtype": {
          'ui:widget': 'hidden',
        },
        "lang": {
          'ui:widget': 'hidden',
        },
        "cat": {
          'ui:widget': 'hidden',
        },
        "value": {
          classNames: "understandValue"
        },
        "valueMatch": {
          'ui:widget': widgetMapping[options.cat]?.widget,
          //'ui:widget': "TextWidget",
          classNames: "understandValueMatch"
        }
      }
    }
  } else if (options.type === "acceptLineAfter") {
    return {
      name: "acceptLineAfter",
      scopes: [
        scopes["client"],
        scopes["global"],
        scopes["crawler"],
      ],
      filterSchema: {
        field: {
          enum: fieldNamesList,
          title: 'Field',
          type: 'string'
        },
        subtype: {
          enum: [
            'allow',
            'disallow',
            //'allowFirst',
            //'allowLast'
          ],
          title: 'Subtype',
          type: 'string'
        },
        lang: {
          enum: [
            'de_CH',
            'fr_CH',
            'en_CH',
            'it_CH'
          ],
          title: 'Language',
          type: 'string'
        }
      },
      schema: {
        type: 'object',
        properties: {
          value: {
            title: 'Value',
            type: 'string'
          }
        },
        dependencies: {},
        required: []
      },
      uischema: {
        'ui:order': [
          'type',
          'subtype',
          'field',
          'lang',
          'value'
        ],
        "field": {
          'ui:widget': 'hidden',
        },
        "type": {
          'ui:widget': 'hidden',
        },
        "subtype": {
          'ui:widget': 'hidden',
        },
        "lang": {
          'ui:widget': 'hidden',
        }
      }
    }
  } else if (options.type === "valueToField") {
    return {
      name: "valueToField",
      scopes: [
        scopes["global"],
        scopes["client"],
        scopes["crawler"],
      ],
      filterSchema: {
        subtype: {
          enum: [
            'terms',
            //'termsRegex'
          ],
          title: 'Subtype',
          type: 'string'
        },
        lang: {
          enum: [
            'de_CH',
            'fr_CH',
            'en_CH',
            'it_CH',
            'es_CH'
          ],
          title: 'Language',
          type: 'string'
        }
      },
      schema: {
        type: 'object',
        title: 'valueToField',
        properties: {
          value: {
            title: 'Value',
            type: 'string'
          },
          suffix: {
            title: 'Value',
            type: 'string'
          },
          fields: {
            "type": "array",
            "items": {
              "type": "string",
              "enum": [...fieldNamesList]
            },
            "uniqueItems": true
          },
        },
        required: []
      },
      uischema: {
        'ui:order': [
          'type',
          'subtype',
          'lang',
          'value',
          'suffix',
          'fields'
        ],
        "fields": {
          'ui:widget': 'checkboxes',
          classNames: "fields"
        },
        "type": {
          'ui:widget': 'hidden',
        },
        "subtype": {
          'ui:widget': 'hidden',
        },
        "lang": {
          'ui:widget': 'hidden',
        },
        "value": {
          classNames: "value"
        },
        "suffix": {
          classNames: "suffix"
        }
      }
    }
  }
}