import {getTree} from './Iframe'
import NodeTreeElement from './NodeTreeElement'


export default function Node ({nodesSelected, setNodesSelected, nodeIndex, nodeSuggest, setNodeSuggest}) {
    const removeNode = (e) => {
        e.preventDefault()

        var array = [...nodesSelected];
        array.splice(nodeIndex, 1);
        setNodesSelected(array)
    }

    var nodeTree = getTree(nodesSelected[nodeIndex])
    if(nodesSelected.length == 2) {
        var otherTree = getTree(nodesSelected[nodeIndex ? 0 : 1])
    }
    
    if(nodeTree) {
        var index = -1
        return (
            <>
            {nodeTree.map(node => {
                index=index+1
                let otherTreeNode = otherTree ? otherTree[index] : null
                return (
                <NodeTreeElement 
                    node={node}
                    nodesSelected={nodesSelected}
                    nodeSuggest={nodeSuggest}
                    setNodeSuggest={setNodeSuggest}
                    nodesSelected={nodesSelected}
                    otherTreeNode={otherTreeNode}
                />
            )})}
            <div className="d-grid gap-2">
                <button onClick={removeNode} className="btn btn-sm">{nodeIndex} [X]</button>
            </div>
            </>
        )
    }
    return null
}

export function getSiblingIndex(node) {
    var i = 0;
    node = node.previousSibling
    while( node != null ) {
      i++;
      node = node.previousSibling
    }
    return i
}
export function getSiblingIndexSameTag(node) {
    var i = 1;
    var tagName = node.tagName
    node = node.previousSibling
    while( node != null ) {
      if (node.tagName == tagName) {
        i++;
      }
      node = node.previousSibling
    }
    return i
}