import React from "react";
import ClientProvider from "./ClientProvider";

export default function ExportButton({label, type, parameters= {}}) {

    const wsProvider = React.useContext(ClientProvider);
    const triggerExport = () => {
        wsProvider.ws.waitForSocketAndSend({
            action: "trigger_export",
            options: {
                "type": type,
                "parameters": parameters
            }
        }, (response) => {
                console.log(response)
        })
    }

  return (
    <button
        onClick={triggerExport}
        className="btn btn-primary btn-sm">

        <i className="bi bi-upload"></i> {label}
    </button>
  )
}