
import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import QueryContext from "../Context";
import ClientProvider from "../../../../Various/ClientProvider";

export default function IndexSelector({ isDisabled = false }) {
    const { index, setIndex } = useContext(QueryContext)
    const [indices, setIndices] = useState([])
    const wsProvider = useContext(ClientProvider);

    useEffect(() => {
        wsProvider.ws.waitForSocketAndSend({
            action: "getESIndices",
            options: {}
        }, (response) => {
            const indices_names = Object.keys(response).filter(x => !x.startsWith("."))
            setIndices(indices_names)
        })
    }, [])

    const onChange = (x) => {
        setIndex(x.value)
    }

    return <Select
        isDisabled={isDisabled}
        onChange={onChange}
        className="reactSelect"
        isClearable={false}
        isSearchable={true}
        options={indices.map(x => ({ value: x, label: x }))}
        value={{
            value: index,
            label: index
        }}
    />
}