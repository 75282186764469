import React, { useEffect, useRef } from 'react'
import ClientProvider from '../../Various/ClientProvider';
import CompWrapper from '../CompWrapper'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import Form from "@rjsf/core";
import getWordsLibConfig from './getWordsLibConfig'
import { useState } from 'react';
import { ToggleWrapper } from '../ToggleWrapper';
import EcedFullSelectWidget from './ecedFullSelectWidget'

export default function WordsLib() {

    const wsProvider = React.useContext(ClientProvider);
    // || (wsProvider.CommandsTree.getAttr("className") !== "textProcessor" && wsProvider.CommandsTree.getAttr("className") !== "valueToField" && wsProvider.CommandsTree.getAttr("className") !== "valueToFieldStats" && wsProvider.CommandsTree.getAttr("className") !== "extractTable")
    if (!wsProvider.commandsTreeReady())
        return null

    const [filterValue, setFilterValue] = wsProvider.useStateVar("crawler", "filterValue", "");

    const [wordsLibType, setWordsLibType] = wsProvider.useStateVar("command", "wordsLibType", "understand");

    const wordsLibOptions = {
        baseFields: wsProvider.baseFields,
        cat: wsProvider.getStateVar("command", "wordsLib_understand_cat"),
        type: wordsLibType
    }
    const wordsLibConfig = getWordsLibConfig(wordsLibOptions)

    const [wordsLibScope, setWordsLibScope] = wsProvider.useStateVar("command", "wordsLibScope", wordsLibConfig["scopes"][0]["name"]);
    const onChangeScope = (val) => setWordsLibScope(val["value"])

    const getDefaultPropFilterSchema = (identifier) => {
        if (identifier == "field") {
            return wsProvider.CommandsTree.getAttr("title")
        }
        return wordsLibConfig["filterSchema"][identifier]["enum"][0]
    }

    const onChangeType = (result) => {
        setWordsLibType(result["value"])
    }

    const options = [
        {
            value: "understand",
            label: "understand"
        },
        {
            value: "valueToField",
            label: "valueToField",
        },
        {
            value: "acceptLineAfter",
            label: "acceptLineAfter",
        }
    ]

    const onAdd = () => {
        let entry = {
            "type": wordsLibType,
        }

        Object.keys(wordsLibConfig["filterSchema"]).forEach(identifier => {
            entry[identifier] = wsProvider.getStateVar("command", "wordsLib_" + wordsLibType + "_" + identifier, getDefaultPropFilterSchema(identifier))
        })

        entry["value"] = filterValue //USE FILTER VALUE AS DEFAULT
        wsProvider.addWordsLib([entry], wordsLibScope)

    }
    const onSortAuto = () => {
        const data = [...wsProvider.getWordsLib(wordsLibScope)]
        data.sort(function (a, b) {
            var res = b["value"].length - a["value"].length;
            if (res === 0) {
                return a["value"].localeCompare(b["value"])
            }
            return res
        });
        wsProvider.setWordsLib(data, wordsLibScope)
    }

    const showWordsLib = () => {
        if (!wsProvider.crawlerTreeReady())
            return null

        const data = wsProvider.getWordsLib(wordsLibScope)

        let useSchema = {
            ...wordsLibConfig["schema"],
            "properties": {
                type: {
                    enum: options.map(x => x.value),
                    title: 'Type',
                    type: 'string'
                },
                ...wordsLibConfig["filterSchema"],
                ...wordsLibConfig["schema"]["properties"]
            }
        }
        const useUiSchema = wordsLibConfig["uischema"]

        switch (wordsLibType) {
            case "valueToField":
                var Wrapper = ToggleWrapper
                break
            case "acceptLineAfter":
                var Wrapper = AcceptLineAfterWrapper
                break
            case "understand":
                var Wrapper = UnderstandWrapper
                break
        }

        return (
            <>
                <div>
                    <input
                        value={filterValue}
                        placeholder="Filter..."
                        onChange={(e) => setFilterValue(e.target.value)}
                        className="form-control form-control-sm wordslibFilter"
                    />
                </div>
                <div className="wordsLibContainer">
                    {data.map((x1, index) => {
                        //FILTERS
                        //type-filter
                        if (filterValue !== "" && x1["value"] !== undefined) {
                            if (!x1["value"].toUpperCase().includes(filterValue.toUpperCase()))
                                return null
                        }

                        if (x1["type"] !== wordsLibType)
                            return null

                        //custom-filters
                        let filtersOK = true
                        Object.keys(wordsLibConfig["filterSchema"]).forEach(identifier => {
                            if (x1[identifier] !== wsProvider.getStateVar("command", "wordsLib_" + wordsLibType + "_" + identifier, getDefaultPropFilterSchema(identifier)))
                                filtersOK = false
                        })
                        if (filtersOK === false)
                            return null

                        let onChange = (res) => {
                            let newWordsLib = [
                                ...data
                            ]
                            newWordsLib[index] = res["formData"]
                            wsProvider.setWordsLib(newWordsLib, wordsLibScope)
                        }

                        let onDelete = () => {
                            let newWordsLib = [
                                ...data
                            ]
                            newWordsLib.splice(index, 1);
                            wsProvider.setWordsLib(newWordsLib, wordsLibScope)
                        }

                        const title = x1["value"]

                        return (
                            <>
                                <div className="row">
                                    <div className="col-11">
                                        <Wrapper title={title}>
                                            <Form
                                                schema={useSchema}
                                                className={"wordsLibForm wordsLibForm_" + wordsLibType + " " + wordsLibOptions.cat}
                                                uiSchema={useUiSchema}
                                                formData={x1}
                                                onChange={onChange}
                                                widgets={{
                                                    ecedFullSelectWidget: EcedFullSelectWidget
                                                }}
                                            />
                                        </Wrapper>
                                    </div>
                                    <div className="col-1">
                                        <button
                                            onClick={onDelete}
                                            className="btn btn-primary btn-sm"
                                        >
                                            <i className="bi bi-dash"></i>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </>
        )
    }



    return (
        <CompWrapper
            className="wordsLibrary"
            title="WordsLib"
            buttonBar={(
                <>
                    <button
                        onClick={onAdd}
                        className="btn btn-primary btn-sm"
                    >
                        <i className="bi bi-plus"></i>
                    </button>
                    <button
                        onClick={onSortAuto}
                        className="btn btn-primary btn-sm"
                    >
                        <i className="bi bi-filter-left"></i>
                    </button>
                </>
            )}
        >
            <div className="row mb-1">
                <Select
                    className="typeSelect reactSelect col-5"
                    value={{
                        value: wordsLibType,
                        label: wordsLibType
                    }}
                    onChange={onChangeType}
                    options={options}
                />
                <Select
                    className="scopeSelect reactSelect col-5"
                    value={{
                        value: wordsLibScope,
                        label: wordsLibScope
                    }}
                    onChange={onChangeScope}
                    options={wordsLibConfig["scopes"].map(x => {
                        return {
                            value: x["name"],
                            label: x["name"]
                        }
                    })}
                />
            </div>
            <div className="row">
                {Object.keys(wordsLibConfig["filterSchema"]).map((propName1, index2) => {
                    let prop = wordsLibConfig["filterSchema"][propName1]
                    let key = "wordsLib_" + wordsLibType + "_" + propName1
                    let value = wsProvider.getStateVar("command", key, getDefaultPropFilterSchema(propName1))

                    return (
                        <>
                            <Select
                                key={index2}
                                className="reactSelect col-4 mb-1"
                                value={{
                                    value: value,
                                    label: value
                                }}
                                onChange={(val) => wsProvider.setStateVar("command", key, val["value"])}
                                options={[
                                    {
                                        value: "",
                                        label: ""
                                    },
                                    ...prop["enum"].map(x => {
                                        return {
                                            value: x,
                                            label: x
                                        }
                                    })]}
                            />
                        </>
                    )
                })}
            </div>
            <div className="row">
                {showWordsLib()}
            </div>
        </CompWrapper>
    );
}

const AcceptLineAfterWrapper = (props) => {
    return props["children"]
}

const UnderstandWrapper = (props) => {
    return props["children"]
}