import { useContext, useState } from "react"
import QueryContext from "../../Base/Context"
import ResultTabPanelItemsTable from "../../Base/Result/TabPanels/ItemsTable"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ResultTabPanelJson from "../../Base/Result/TabPanels/Json"
import ProfileDetails from "./ProfileDetails"

export default function QueryLinkedinProfilesSelectorResult() {
    const { response } = useContext(QueryContext)
    const [itemActive, setItemActive] = useState()

    const clickHandler = (item, column, index, itemOrig) => {
        setItemActive(itemOrig)
    }

    return <Tabs>
        <TabList>
            {response &&
                <Tab>Hits ({response.hits.total.value})</Tab>
            }
            <Tab>JSON</Tab>
        </TabList>
        {response &&
            <TabPanel>
                <ResultTabPanelItemsTable
                    fn_dataTransform={(response) => response.hits.hits.map(hit => hit._source)}
                    height={"calc(100vh - 570px)"}
                    clickHandler={clickHandler}
                    columnsSettings={{
                        "province": {
                            minColumnWidth: 70,
                            minWidth: 70,
                            width: 70,
                        }
                        ,
                        "name": {
                            minColumnWidth: 130,
                            minWidth: 130,
                            width: 130,
                        },
                        "education": {
                            minColumnWidth: 100,
                            minWidth: 100,
                            width: 100,
                        },
                        "position": {},
                        "url": {
                            minColumnWidth: 100,
                            minWidth: 100,
                            width: 100,
                        }
                    }}
                />
                <div style={{ height: "280px", marginTop: "20px" }}>
                    {itemActive &&
                        <ProfileDetails education_data={itemActive.education} />
                    }
                </div>
            </TabPanel>
        }
        <TabPanel>
            <ResultTabPanelJson />
        </TabPanel>
    </Tabs>
}