import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import ClientProvider from '../ClientProvider';

export default function ClientSelectSimple({ client, setClient, projectPath }) {
    const wsProvider = React.useContext(ClientProvider);

    const onChange = (result) => {
        setClient(result["value"])
    }

    const options = wsProvider.clients.filter(x => x.projectPath === projectPath).map((x) => ({ "value": x.client, "label": x.client }))

    return (
        <div>
            <Select
                className="clientSelect reactSelect"
                value={{ value: client, label: client }}
                onChange={onChange}
                options={[{ "value": "", "label": "" }].concat(options)}
            />
        </div>
    )

}
