import React from 'react';
import ClientProvider from '../../../Various/ClientProvider';
import FilterTypeIsCourse from './filter/FilterTypeIsCourse'
import FilterTypeCondition from './filter/FilterTypeCondition'
import FilterTypeTree from './filter/FilterTypeTree'
import FilterTypePickValues from './filter/FilterTypePickValues'

export default function Filter({ execAll, execAllArray, showAll, execSelected, commandKey }) {
    const wsProvider = React.useContext(ClientProvider);
    const type = wsProvider.CommandsTree.getSingleSetting("type")

    switch (type) {
        case "condition":
            return (
                <FilterTypeCondition
                    execAll={execAll}
                    execAllArray={execAllArray}
                    showAll={showAll}
                    execSelected={execSelected}
                    commandKey={commandKey}
                />
            )

        case "pickValues":
            return (
                <FilterTypePickValues
                    execAll={execAll}
                    execAllArray={execAllArray}
                    showAll={showAll}
                    execSelected={execSelected}
                    commandKey={commandKey}
                />
            )

        case "tree":
            return (
                <FilterTypeTree
                    execAll={execAll}
                    execAllArray={execAllArray}
                    showAll={showAll}
                    execSelected={execSelected}
                    commandKey={commandKey}
                />
            )
        case "isCourse":
            return (
                <FilterTypeIsCourse
                    execAll={execAll}
                    execAllArray={execAllArray}
                    showAll={showAll}
                    execSelected={execSelected}
                    commandKey={commandKey}
                />
            )
    }
    return null
}