
import App from "./App.js"
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";

export default function LoginWrapper() {

  const { isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();

  const [authToken, setAuthToken] = useState()

  useEffect(() => {
    const getAuthToken = async () => {
      if (isAuthenticated) {
        const authToken = await getAccessTokenSilently();
        setAuthToken(authToken)
      }
    }
    if (isAuthenticated) {
      getAuthToken().catch(console.error)
    }
  }, [isAuthenticated])

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div className="login">
      <img src="/login.png" alt="Login" />

      <button className="flex" onClick={() => loginWithRedirect()}>Log In</button>
    </div>
  }

  if (!authToken) {
    return <div>Loading...</div>;
  }

  return (<App authToken={authToken} />)
}