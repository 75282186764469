import Select from "react-select";


export default function WorkspaceESQueryHeader({ queriesToSelect, selectedQuery, setSelectedQuery }) {

    const onChangeSelectedQuery = (newValue) => {
        setSelectedQuery(newValue)
    }

    return <>
        <div className='col-8'>
            <Select
                onChange={onChangeSelectedQuery}
                className="reactSelect"
                isClearable={false}
                isSearchable={true}
                name="color"
                options={queriesToSelect}
                value={selectedQuery}
            />

        </div>

    </>
}