import { getTree } from './Iframe'
import { getSiblingIndexSameTag } from './Node'

export default function NodeXPathSuggest({ nodesSelected, nodeSuggest, setItems }) {
    const handler_value = (value) => {
        setItems(items => [...items, { "value": value }])
    }

    const handler_brakets = () => {
        setItems(items => [{ "value": "(" }, ...items, { "value": ")" }])
    }

    const handler_path = (value, options) => {
        var nodeTree = getTree(nodeSuggest)
        var result = "."
        nodeTree.map((node, i, nodeTree) => {
            result = result + "/" + node.tagName
            if (i + 1 === nodeTree.length) {
                if (!options["noLastIndex"])
                    result = result + "[" + getSiblingIndexSameTag(node) + "]"
            } else {
                result = result + "[" + getSiblingIndexSameTag(node) + "]"
            }
        })
        setItems(items => [{ "value": result }])
    }
    const nPos = (child) => {
        let parent = child.parentNode;
        let index = Array.prototype.indexOf.call(parent.children, child);
        return index
    }
    function Element({ value, handler, options }) {
        handler = handler ? handler : handler_value
        return (
            <>
                <div className="d-grid gap-2">
                    <button
                        className="btn btn-primary btn-sm btn-node"
                        onClick={() => handler(value, options)}
                        title={value}
                    >{value.length > 40 ? value.substr(0, 38) + "... [" + value.length + "]" : value}
                    </button>
                </div>

            </>
        )
    }

    if (nodeSuggest) {
        return (
            <div>
                <Element value={'Absolute Path'} handler={handler_path} options={{}} />
                <Element value={'Absolute Path (no index)'} handler={handler_path} options={{ noLastIndex: true }} />
                <br />
                <Element value={"//"} />
                <Element value={"/"} />
                <Element value={"."} />
                <Element value={".."} />
                <Element value={"[" + getSiblingIndexSameTag(nodeSuggest) + "]"} />
                <Element value={"[position() mod 2 = 1]"} />
                <Element value={'( ... )'} handler={handler_brakets} />
                <br />
                {nodeSuggest.nextElementSibling &&
                    <Element value={"/following-sibling::" + nodeSuggest.nextElementSibling.tagName} />
                }
                <Element value={"/ancestor::"} />
                <Element value={"//*[text()]"} />
                <br />
                <Element value={nodeSuggest.tagName} />
                <Element value={'[contains(text(),"' + nodeSuggest.textContent.trim() + '")]'} />
                <br />

                {nodeSuggest.getAttribute("id") &&
                    <Element value={'[@id="' + nodeSuggest.getAttribute("id") + '"]'} />
                }
                <Element value={'[@class="' + nodeSuggest.getAttribute("class") + '"]'} />
                <br />
                {
                    ("" + nodeSuggest.getAttribute("class")).split(" ").map(x => (
                        <Element value={'[contains(@class,"' + x + '")]'} key={x} />
                    ))
                }
            </div>
        )
    }
    return 'B'
}

