import { useContext, useEffect, useState } from "react";
import ConfigWrapper from "../../Base/Config/ConfigWrapper";
import ConfigForm from "../../Base/Config/ConfigForm";
import IndexSelector from "../../Base/Config/IndexSelector";
import { propertiesProvince, propertiesSize, propertiesSource } from "../../Base/Config/formDataConstants";
import RunButton from "./RunButton";

export default function QueryLinkedinProfilesForCoursesConfig() {
    return <ConfigWrapper
        defaultIndexName="linkedin_profiles"
    >
        <IndexSelector isDisabled={true} />
        <ConfigForm
            schema={{
                "type": "object",
                "properties": {
                    "selection_type": {
                        type: "string",
                        enum: ["by_school_course_list", "by_topic_group_set_degree"]
                    },
                    "province": propertiesProvince,
                    "size_courses": {
                        type: "integer",
                        title: "Size: Courses"
                    },
                    "size_profiles_per_edustep": {
                        type: "integer",
                        title: "Size: Profile per edustep"
                    },
                    "doc_count_threshold": {
                        type: "number",
                        title: "doc_count >= (0-N)"
                    },
                    "doc_count_ratio_threshold": {
                        type: "number",
                        title: "doc_count_ratio >= (0-1)"
                    },
                    "education.start_year": {
                        type: "integer",
                        title: "education.start_year >="
                    },
                    "education.end_year": {
                        type: "integer",
                        title: "education.end_year >="
                    },
                    "anonymize": {
                        type: "boolean",
                        title: "anonymize"
                    },
                    "only_stats": {
                        type: "boolean",
                        title: "only_stats"
                    },
                    "small_file": {
                        type: "boolean",
                        title: "small_file"
                    },
                },
                dependencies: {
                    selection_type: {
                        oneOf: [
                            {
                                properties: {
                                    selection_type: {
                                        enum: [
                                            'by_school_course_list'
                                        ]
                                    },
                                    "school": {
                                        type: "string"
                                    },
                                    "title_value_de_CH": {
                                        type: "string",
                                        title: "Title"
                                    },

                                }
                            },
                            {
                                properties: {
                                    selection_type: {
                                        enum: [
                                            'by_topic_group_set_degree'
                                        ]
                                    },
                                    "selection_type_degree": {
                                        type: "string"
                                    },
                                    "selection_type_topic_group_str": {
                                        type: "string",
                                    },
                                }
                            }
                        ]
                    }
                },
            }}
            uiSchema={{
                "province": {
                    "ui:widget": "checkboxes"
                },
                'ui:order': [
                    "selection_type",
                    "school",
                    "title_value_de_CH",
                    "selection_type_degree",
                    "selection_type_topic_group_str",
                    "province",
                    "size_courses",
                    "size_profiles_per_edustep",
                    "doc_count_threshold",
                    "doc_count_ratio_threshold",
                    "education.start_year",
                    "education.end_year",
                    "anonymize",
                    "only_stats",
                    "small_file",
                ]
            }}
            defaultFormData={() => ({
                selection_type: "by_school_course_list",
                province: [],
                size_courses: 10,
                size_profiles_per_edustep: 10000,
                school: "coachingzentrum-ch",
                title_value_de_CH: "CAS Resilienztraining M3",
                selection_type_degree: "CAS",
                selection_type_topic_group_str: "12###1270###Beratung und Coaching",
                doc_count_threshold: 2,
                doc_count_ratio_threshold: 0.05,
                "education.start_year": 2020,
                "education.end_year": 2021,
                "anonymize": true,
                "only_stats": false,
                "small_file": false,
            })}
            className="rjsf_QueryLinkedinProfilesSelectorConfig"
        />
        <RunButton />
    </ConfigWrapper>
}