import {useContext, useEffect, useState} from "react";
import ConfigWrapper from "../../Base/Config/ConfigWrapper";
import ConfigForm from "../../Base/Config/ConfigForm";
import IndexSelector from "../../Base/Config/IndexSelector";
import RunButtonQuery from "../../Base/Config/RunButtonQuery";
import QueryContext from "../../Base/Context";

export default function QueryRInderDishesSelectorConfig() {
    const { formData } = useContext(QueryContext)

    const generate_query = () => {
        const query = {
            "query": {
                "bool": {
                    "must": []
                }
            },
            "size": formData.size
        }
        if (formData.restaurant_hash) {
            query.query.bool.must.push({
                "term": {
                    "restaurant_hash": formData.restaurant_hash
                }
            })
        }
        // if (formData.nested_query) {
        //     query.query.bool.must.push({
        //         "nested": {
        //             "path": "education",
        //             "query": JSON.parse(formData.nested_query)
        //         }
        //     })
        // }

        return query
    }

    return  <ConfigWrapper
        defaultProject={"rinder"}
        defaultIndexName="dishes"
        hasQuery={true}
    >
        <IndexSelector isDisabled={true} />
        <ConfigForm
            schema={{
                "type": "object",
                "properties": {
                    "restaurant_hash": {
                        "type": "string",
                        "title": "Restaurant Hash",
                        "default": null
                    },
                    "size": {
                        "type": "integer",
                        "title": "Size",
                        "default": 100
                    },
                }
            }}
            uiSchema={{

            }}
            defaultFormData={() => ({
                // province: [],
                size: 100,
                // source: [
                //     "name",
                //     "position",
                //     "url",
                //     "education",
                //     "province"
                // ],
                nested_query: JSON.stringify({
                    "bool": {
                        "must": [
                            // {
                            //     "bool": {
                            //         "should": [
                            //
                            //             {
                            //                 "prefix": {
                            //                     "education.url.keyword": {
                            //                         "value": "https://ch.linkedin.com/school/coachingzentrum-olten"
                            //                     }
                            //                 }
                            //             },
                            //             {
                            //                 "prefix": {
                            //                     "education.school_id": {
                            //                         "value": "CHE-czo"
                            //                     }
                            //                 }
                            //             }
                            //         ]
                            //     }
                            // }
                        ]
                    }
                }, null, 2)
            })}
            className="rjsf_QueryLinkedinProfilesSelectorConfig"
            generate_query={generate_query}
        />
        <RunButtonQuery />
    </ConfigWrapper>
}

/*
ZHAW

{
    "bool": {
        "should": [
            {
                "prefix": {
                    "education.url.keyword": {
                        "value": "https://ch.linkedin.com/school/zhaw"
                    }
                }
            },
            {
                "prefix": {
                    "education.school_id": {
                        "value": "CHE-zhaw"
                    }
                }
            }
        ]
    }
},

/*  
{
    "term": {
        "education.degrees": "BA"
    }
},

{
"range": {
    "education.end_year": {
        "gte": "2024"
    }
}
}

{
"prefix": {
    "education.topic_group_set": {
        "value": "07###072###0722###Systementwicklung"
    }
}
},

*/