import ReactJson from 'react-json-view'

export default function ItemsTableConsoleObjs({data}) {

    return (
        <div className="itemsTableConsoleObjs">
            <ReactJson 
                src={data}  
                theme="monokai" 
                indentWidth={2}
                enableClipboard={false}
                displayDataTypes={false}
            />
        </div>
    )
}