import ConfigWrapper from "../../Base/Config/ConfigWrapper";
import IndexSelector from "../../Base/Config/IndexSelector";
import ConfigForm from "../../Base/Config/ConfigForm";
import RunButtonQuery from "../../Base/Config/RunButtonQuery";
import { useContext } from "react";
import QueryContext from "../../Base/Context";

export default function QueryRawConfig() {
    const { formData } = useContext(QueryContext)

    const generate_query = () => {
        if (formData.query)
            return JSON.parse(formData.query)
    }

    return <ConfigWrapper
        defaultIndexName="item"
        hasQuery={true}
    >
        <IndexSelector />
        <ConfigForm
            schema={{
                "type": "object",
                "properties": {
                    "query": {
                        "type": "string",
                    }
                }
            }}
            uiSchema={{
                "query": {
                    "ui:widget": "monacojson",
                    "height": "700px"
                }
            }}
            defaultFormData={() => ({
                query: JSON.stringify({
                    "_source": [
                        "title_value_de_CH",
                        "client_value",
                        "topic_group_set"
                    ],

                    "query": {
                        "bool": {
                            "must": [
                                {
                                    "dis_max": {
                                        "queries": [
                                            {
                                                "match_phrase": {
                                                    "title_value_de_CH": {
                                                        "boost": 2,
                                                        "query": "sport"
                                                    }
                                                }
                                            },
                                            {
                                                "match": {
                                                    "title_value_de_CH": {
                                                        "boost": 1.5,
                                                        "operator": "AND",
                                                        "fuzziness": 0,
                                                        "query": "sport"
                                                    }
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    "aggs": {
                        "agg1": {
                            "terms": {
                                "field": "topic_group_set",
                                "size": "5"
                            }
                        }
                    },
                    "size": "20"
                }, null, 2)
            })}
            className="rjsf_QueryRawConfig"
            generate_query={generate_query}
        />
        <RunButtonQuery />
    </ConfigWrapper>
}