import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap-icons/font/bootstrap-icons.css'
import LoginWrapper from './LoginWrapper.js';

import * as serviceWorker from './websocket/serviceWorker';
import {Auth0Provider} from "@auth0/auth0-react";

console.log(process.env.REACT_APP_AUTH0_DOMAIN, process.env.REACT_APP_AUTH0_CLIENT_ID, process.env.REACT_APP_AUTH0_AUDIENCE)

ReactDOM.render(
    <Auth0Provider
        domain={ process.env.REACT_APP_AUTH0_DOMAIN }
        clientId={ process.env.REACT_APP_AUTH0_CLIENT_ID }
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE ,
        }}
    >
        <LoginWrapper />
    </Auth0Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
