import React, {useEffect} from 'react';
import ClientProvider from '../../Various/ClientProvider';
import { useState } from 'react';
import ExportButton from "../../Various/ExportButton";
import {DialogContext} from "../../Various/Dialog/DialogYesNo";


export default function WorkspaceSystem() {

    const wsProvider = React.useContext(ClientProvider);

    const dialogContext = React.useContext(DialogContext);
    const [fileList, setFileList] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const [isLoading, setIsLoading] = useState([false])

    const updateSelectedFiles = (file) => {
        const result = [...selectedFiles]
        if (result.includes(file)) {
            result.splice(result.indexOf(file), 1)
        } else {
            result.push(file)
        }
        setSelectedFiles(result)
    }

    const listFolder = () => {
        setIsLoading(true)
        wsProvider.ws.waitForSocketAndSend(
            {
                action: "list_folder",
                options: {
                    "fs": "fs_cross_environment",
                    "path": "export/"
                }
            },
            (response) => {
                console.log("list_folder_response", response)
                setFileList(response.data)
                setSelectedFiles([])
                setIsLoading(false)
            }
        )
    }

    useEffect(
        () => {
            listFolder()
        }, []
    )

    const importHandler = () => {

    }

    return (
      <>
          <div>
              <div className='row'>
                  <div className='col-6'>
                      <h2>Export</h2>
                      <div>
                          <p>
                              <ExportButton type="1_critical" label="Export 1_critical"/>
                          </p>
                      </div>
                      <div>
                          <p>
                              <ExportButton type="3_reproducable_edufind_topics"
                                            label="Export 3_reproducable/projects/edufind/topic_system"/>
                          </p>
                      </div>
                      <div>
                          <p>
                              <ExportButton type="3_reproducable_geocode_cache"
                                            label="Export 3_reproducable/geocodeCache"/>
                          </p>
                      </div>
                      <div>
                          <p>
                              <ExportButton type="export_3_reproducable_open_ai_prompt_cache"
                                            label="Export 3_reproducable/openAIPromptCache"/>

                          </p>
                      </div>
                      <div>
                          <p>
                              <ExportButton type="3_reproducable_translation_cache"
                                            label="Export 3_reproducable/translationCache"/>
                          </p>
                      </div>
                      <div>
                          <p>
                              <ExportButton type="wordlib_global" label="Export WordsLibGlobal"/>
                          </p>
                      </div>
                  </div>
                  <div className='col-6'>
                      <div className={"row"}>
                          <div className={"col-10"}>
                              <h2>Import</h2>
                          </div>
                          <div className={"col-2"}>
                              <button className={"btn btn-primary"} onClick={listFolder}><i className={"bi bi-arrow-clockwise"}></i></button>
                          </div>
                      </div>
                      {!isLoading ? (
                              <div>
                                  <ul>
                                      {
                                          fileList.map((x, i) => {
                                              return (
                                                  <li key={i} style={{listStyleType: "none"}}>
                                                      <input
                                                          type="checkbox"
                                                          checked={selectedFiles.includes(x.name)}
                                                          onChange={() => {
                                                              updateSelectedFiles(x.name)
                                                          }}
                                                      /> {x.name},
                                                      ({(x.size / 1024).toLocaleString([], {maximumFractionDigits: 2})}KB)
                                                  </li>
                                              )
                                          })
                                      }
                                  </ul>
                              </div>
                          ) :
                          <span className={isLoading ? "spinner-border spinner-border-sm text-light" : "notLoading"}
                              role="status">
                            <span className="visually-hidden">Loading...</span>
                          </span>
                      }
                      <div className={"row"}>
                          <div className={"col-6"}>
                              <button
                                  type={"button"}
                                  className={"btn btn-primary"}
                                  disabled={selectedFiles.length === 0}
                                  onClick={() => {
                                          dialogContext.openDialog({
                                              title: "Import Selected Files ",
                                              prompt: "Are you sure?",
                                              handleYes: () => {
                                                wsProvider.ws.waitForSocketAndSend(
                                                    {
                                                        action: "import_files",
                                                        options: {
                                                            "fs": "fs_cross_environment",
                                                            "files": selectedFiles
                                                        }
                                                    },
                                                    (response) => {
                                                        console.log("import_response", response)
                                                    }
                                                )
                                              },
                                              handleNo: () => { }
                                            })
                                        }
                                  }
                              >Import</button>
                          </div>
                          <div className={"col-6"}>
                              <button
                                  type={"button"}
                                  className={"btn btn-danger"}
                                  disabled={selectedFiles.length === 0}
                                    onClick={() => {
                                        console.log(selectedFiles)

                                        wsProvider.ws.waitForSocketAndSend(
                                            {
                                                action: "delete_files",
                                                options: {
                                                    "fs": "fs_cross_environment",
                                                    "files": selectedFiles
                                                }
                                            },
                                            (response) => {
                                                listFolder()
                                            }
                                        )
                                    }}
                              >Delete</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}