import Editor from "@monaco-editor/react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import ClientProvider from "../ClientProvider";
import React, { useState, useMemo } from 'react';
import ItemsTable from "../ItemsTable/ItemsTable_core";
import {ItemsTableDefault} from "../ItemsTable/ItemsTable";

export default function MonacoEsSearchWidget(props) {

    // const wsProvider = React.useContext(WsProvider);
    // const [data, setTableData] = useState( [ {} ] )
    // const [sortColumns, setSortColumns] = useState([ "result_id" ])
    const handleEditorChange = (value, event) => {
        props.onChange(value)
    }

    return (
        <>
        <Editor
            height="30vh"
            defaultLanguage="json"
            value={props.value}
            onChange={handleEditorChange}
            theme="vs-dark"
            options={
                {
                    tabSize: 2,
                    minimap: { enabled: false }
                }
            }
        />
        <Button
            variant="secondary" onClick={ () => {
                props.options.onSearch(
                    props.value,
                    props.schema.options.campaign,
                    (response) => {
                        console.log(response)
                        //
                        // let tableData = []
                        // let id = 0
                        // response.filter_result.hits.forEach((hit) => {
                        //     tableData.push({ result_id: id++, ...hit.fields })
                        // })
                        // console.log(tableData)
                        // setTableData(tableData)
                    }
                )
            }}
        >Test</Button>
        <Button onClick={() => {
            const query = {
                query: {
                    match_all: {}
                }
            }
            props.onChange(JSON.stringify(query, null, 4))
        }
        }>Match All</Button>
        <Button onClick={() => {
            const query = {
                query: {
                    query_string: {
                        query: "title_value: *",
                        default_field: "title_value",
                        allow_leading_wildcard: true,
                        analyze_wildcard: true,
                        default_operator: "OR",
                        fuzziness: 0
                    }
                }
            }
            props.onChange(JSON.stringify(query, null, 4))
        }
        }>Query String</Button>
            <Link to={{ pathname:"https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#query-string-syntax"}}
                  target="_blank">Query String Syntax</Link>


        </>
    )
  };

        /*
            <div className='content'>
                 <ItemsTableDefault
                    tableData={data}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    useRowKeyGetter={(row) => 'result_id'}
                    key={"result_id"}
                    // filterColumns={filterColumns}
                    // setFilterColumns={setFilterColumns}
                />
            </div>
        */